import React, { useState, useEffect } from "react";
import { Message, Tab } from "semantic-ui-react";
import { ReportFrame } from "./ReportFrame";

export const ReportTabs = ({ reportList }) => {
  const [isIframeLoading, setIsIframeLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setActiveTab(0);
  }, [reportList]);

  if (reportList.length === 0) {
    return (
      <Message
        icon="warning circle"
        header={`Reports`}
        content={`You do not have any reports configured.`}
      />
    );
  }

  return (
    <div>
      <Tab
        menu={{ attached: true }}
        onTabChange={(e, { activeIndex }) => {
          e.persist();
          if (activeIndex !== activeTab) {
            setIsIframeLoading(true);
            setActiveTab(activeIndex);
          }
        }}
        defaultActiveIndex={0}
        panes={reportList.map((report) => ({
          menuItem: report.name,
          render: () => (
            <Tab.Pane loading={isIframeLoading}>
              <ReportFrame
                embeddedReportId={report.powerBiEmbeddedReportId}
                onLoad={() => setIsIframeLoading(false)}
              />
            </Tab.Pane>
          ),
        }))}
      />
    </div>
  );
};
