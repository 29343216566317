import React from "react";
import Helmet from "react-helmet";

export const OneTrust = () => {
  return (
    <Helmet>
      <script
        type="text/javascript"
        src={`https://cdn.cookielaw.org/consent/${process.env.REACT_APP_ONETRUST_COOKIE_KEY}/OtAutoBlock.js`}
      ></script>
      <script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charset="UTF-8"
        data-domain-script={process.env.REACT_APP_ONETRUST_COOKIE_KEY}
      ></script>
    </Helmet>
  );
};
