import {
  deleteFinancialSubmission,
  downloadSubmissionFile,
  downloadSubmissionTemplateFile,
  getFinancialSubmission,
  uploadSubmissionFile,
} from "../../lib/apiOrderManagement";
import { isExcelFile } from "../../lib/excelHelpers";
import { notifyError } from "../../lib/notifications";

export const getFinancialComplianceInformation = async (
  customerGroupId,
  setTableData,
  setLoading
) => {
  setLoading(true);
  if (customerGroupId) {
    const financialSubmissions = await getFinancialSubmission(customerGroupId);

    if (financialSubmissions) {
      setTableData(financialSubmissions);
    } else {
      setTableData([]);
    }
  }
  setLoading(false);
};

export const deleteSubmissionFile = async (
  financialSubmissionId,
  setDeleteLoading
) => {
  setDeleteLoading(true);
  await deleteFinancialSubmission(financialSubmissionId);
  setDeleteLoading(false);
};

export const downloadSubmission = async (submissionId, fileName) => {
  try {
    await downloadSubmissionFile(submissionId, fileName);
  } catch (error) {
    notifyError("Failed to get submission file.", error);
  }
};

export const downloadSubmissionTemplate = async (
  setTemplateLoading,
  selectedCustomerGroup
) => {
  setTemplateLoading(true);
  await downloadSubmissionTemplateFile(selectedCustomerGroup);
  setTemplateLoading(false);
};

/*File upload scripts*/

export const validateFileType = async ({ file, setError, filetype }) => {
  if (filetype === "excel") {
    if (!isExcelFile(file)) {
      setError(
        "Upload status: Upload failed due to wrong file format, upload a new file in order to correct."
      );
    } else {
      setError(null);
    }
  } else if (filetype === "pdf") {
  }
};

export const uploadFile = async ({
  file,
  financialSubmissionWindowId,
  customerGroupId,
  financialDocumentType,
  uploadComplete,
  setLoading,
}) => {
  if (file) {
    uploadSubmissionFile(
      file,
      financialSubmissionWindowId,
      customerGroupId,
      financialDocumentType,
      uploadComplete,
      setLoading
    );
  }
};
