import "./BoxInsights.scss";
import {
  fetchSalesPeriods,
  downloadBoxInsightUploadFile,
  fetchCustomerGroup,
} from "./BoxInsights.functions";
import { Divider, Message } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { UilDownloadAlt } from "@iconscout/react-unicons";
import { StandardSubheading } from "../../../../common/StandardSubheading/StandardSubheading";
import { Grid, Select } from "semantic-ui-react";
import { IconButton } from "../../../../common/IconButton/IconButton";

export const BoxInsights = ({ selectedCustomerGroup }) => {
  const [salesPeriodsDropdownData, setSalesPeriodsDropdownData] = useState([]);
  const [customerGroup, setCustomerGroup] = useState({});
  const [selectedSalesPeriod, setSelectedSalesPeriod] = useState({
    key: 0,
    value: 0,
    text: "",
  });
  const [hasBoxInsightsPermission, setHasBoxInsightsPermission] =
    useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchSalesPeriods({ setSelectedSalesPeriod, setSalesPeriodsDropdownData });
  }, [setSalesPeriodsDropdownData]);

  useEffect(() => {
    fetchCustomerGroup({
      setCustomerGroup,
      customerGroupId: selectedCustomerGroup,
      setHasBoxInsightsPermission,
    });
  }, [setCustomerGroup, selectedCustomerGroup]);

  const download = () => {
    downloadBoxInsightUploadFile({
      customerGroupId: selectedCustomerGroup,
      salesPeriodId: selectedSalesPeriod.value,
      customerGroupName: customerGroup.name,
      salesPeriodName: selectedSalesPeriod.text,
      setLoading,
    });
  };

  const handleSalesPeriodDropdownChange = (selectedSalesPeriod) => {
    let selectedSalesPeriodIndex = 0;
    salesPeriodsDropdownData.forEach((value, index) => {
      if (value.value === selectedSalesPeriod) {
        selectedSalesPeriodIndex = index;
      }
    });
    setSelectedSalesPeriod(salesPeriodsDropdownData[selectedSalesPeriodIndex]);
  };

  const pageContent = (
    <React.Fragment>
      <StandardSubheading>
        <div className="flex justify-space-between">
          <span className="sub-heading-text">
            Box Insights Data: {customerGroup.name}
          </span>
        </div>
      </StandardSubheading>
      <Divider />

      <>
        {!hasBoxInsightsPermission ? (
          <Message
            icon="warning circle"
            header={`Box Insights Data Access`}
            content={`You do not have access to view or submit box insights data for this customer group.`}
          />
        ) : (
          <Grid>
            <Grid.Row className="boxInsightsGrid">
              <Grid.Column width={6}>
                <p>{`Download Box Insights Data for Sight: ${selectedSalesPeriod.text}`}</p>
              </Grid.Column>
              <Grid.Column width={10}>
                <div className="action-container">
                  <IconButton
                    onClick={download}
                    floated="right"
                    primary
                    Icon={UilDownloadAlt}
                    width={200}
                    loading={loading}
                    disabled={loading}
                  >
                    Download
                  </IconButton>
                  <Select
                    className="ml2"
                    options={salesPeriodsDropdownData}
                    onChange={(_, { value }) =>
                      handleSalesPeriodDropdownChange(value)
                    }
                    closeOnChange={true}
                    value={selectedSalesPeriod.value}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </>
    </React.Fragment>
  );

  return pageContent;
};
