import "./login.scss";

import { Grid, Icon, Segment } from "semantic-ui-react";

import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import React from "react";

export const Cookies = () => {
  return (
    <>
      <Helmet>
        <title>De Beers Sightholder Portal - Cookies</title>
      </Helmet>
      <Grid
        textAlign="center"
        verticalAlign="middle"
        className="terms-conditions"
      >
        <Grid.Column className="terms-holder">
          <Segment className="terms-container cookie-container">
            <Grid textAlign="left">
              <Grid.Row>
                <Grid.Column>
                  <Icon
                    name="arrow left"
                    className="help-back-navigation-icon"
                  />
                  <span className="back-navigation-button-text">
                    <Link to="/login">Back to Sign In</Link>
                  </span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <div id="ot-sdk-cookie-policy"></div>
                  <button id="ot-sdk-btn" class="ot-sdk-show-settings">
                    Cookie Settings
                  </button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
};
