import { createAxios } from "./axiosWrapper";
import { notifyError } from "./notifications";

const orderManagementUrl = `${process.env.REACT_APP_CUSTOMER_INTEGRATION_URL}/api`;

const manufacturingSubmissionController = () =>
  createAxios(`${orderManagementUrl}/ManufacturingSubmission`);

export const getCustomerGroupPermissions = async ({ customerGroupId }) => {
  try {
    const request = `check-user-customer-group-manufacturing-access?customerGroupId=${customerGroupId}`;

    const customerGroupPermissions = await manufacturingSubmissionController().get(
      request
    );
    return customerGroupPermissions.data;
  } catch (err) {
    notifyError("Could not fetch customer group permissions.", err);
  }
};

export const getManufacturingSubmissions = async ({
  limit,
  skip,
  salesPeriodId,
  customerGroupId,
}) => {
  try {
    const request = `customer-portal?limit=${limit}&skip=${
      skip - 1
    }&salesPeriodId=${encodeURIComponent(
      salesPeriodId
    )}&customerGroupId=${encodeURIComponent(customerGroupId)}`;

    const manufacturingSubmissions = await manufacturingSubmissionController().get(
      request
    );
    return manufacturingSubmissions.data;
  } catch (err) {
    notifyError("Could not fetch manufacturing submissions.", err);
  }
};

export const uploadManufacturingSubmissionsOverwrite = async (
  newSubmission,
  file
) => {
  try {
    const dataJson = {
      file: [file],
      data: newSubmission,
    };
    const data = formData(dataJson, ["file"]);
    await manufacturingSubmissionController().post("upload-overwrite", data);
  } catch (err) {
    notifyError("Could not upload manufacturing submissions.", err);
  }
};

export const uploadManufacturingSubmissions = async (newSubmission, file) => {
  try {
    const dataJson = {
      file: [file],
      data: newSubmission,
    };
    const data = formData(dataJson, ["file"]);
    const result = await manufacturingSubmissionController().post(
      "upload-if-not-exists",
      data
    );
    return result.data;
  } catch (err) {
    notifyError("Could not upload manufacturing submissions.", err);
  }
};

const formData = (formData, filesIgnore = []) => {
  const data = new FormData();

  const files = filesIgnore;

  Object.entries(formData).forEach(([key, value]) => {
    if (typeof value === "object" && !files.includes(key)) {
      data.append(key, JSON.stringify(value) || null);
    } else if (files.includes(key)) {
      data.append(key, value[0] || null);
    } else {
      data.append(key, value || null);
    }
  });

  return data;
};

export const downloadSingleSubmission = async ({ submissionId, fileName }) => {
  try {
    const result = await manufacturingSubmissionController().get(
      `download?submissionId=${submissionId}`,
      { responseType: "blob" }
    );
    const url = window.URL.createObjectURL(result.data);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    return result.data;
  } catch (err) {
    notifyError(
      "Could not download manufacturing insights data submissions.",
      err
    );
  }
};

export const downloadManufacturingSubmissionTemplate = async () => {
  try {
    const result = await manufacturingSubmissionController().get(
      `download-template`,
      { responseType: "blob" }
    );
    const url = window.URL.createObjectURL(result.data);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Manufacturing Insights Data Template.xlsx";
    a.click();
    return result.data;
  } catch (err) {
    notifyError(
      "Could not download manufacturing insights data submissions template.",
      err
    );
  }
};

export const deleteManufacturingSubmission = async ({ submissionId }) => {
  try {
    const request = `?submissionId=${submissionId}`;

    const manufacturingSubmissions = await manufacturingSubmissionController().delete(
      request
    );
    return manufacturingSubmissions.data;
  } catch (err) {
    notifyError("Could not remove manufacturing submission.", err);
  }
};

export const searchManufacturingSubmissions = async ({
  limit,
  skip,
  salesPeriodId,
  customerGroupId,
  searchString,
}) => {
  try {
    let request = `for-previous-sales-periods?limit=${limit}&skip=${
      skip - 1
    }&currentSalesPeriodId=${encodeURIComponent(
      salesPeriodId
    )}&customerGroupId=${encodeURIComponent(customerGroupId)}`;

    if (searchString)
      request += `&searchString=${encodeURIComponent(searchString)}`;

    const manufacturingSubmissions = await manufacturingSubmissionController().get(
      request
    );
    return manufacturingSubmissions.data;
  } catch (err) {
    notifyError(
      "Could not fetch past manufacturing submissions from your search.",
      err
    );
  }
};
