import React from "react";

export const NotificationsContext = React.createContext({
  notifications: [],
  loadingNotifications: false,
  typeFilter: null,
  notificationTypes: [],
  locations: [],
  salesPeriods: [],
  filterLoading: false,
  unreadMessagesCount: 0,
  viewAllClicked: () => {},
  updateFilter: (filterType, filterValue) => {},
  markAllAsReadClicked: () => {},
  markNotificationAsReadClicked: (notification) => () => {}
});
