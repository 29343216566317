import "../../common/BackButtonNavigation/BackButtonNavigation.scss";

import { Button, Icon } from "semantic-ui-react";

import { Link } from "react-router-dom";
import React from "react";
import { useHistory } from "react-router-dom";

export const BackLink = ({ backUrl }) => {
  const history = useHistory();

  const onBackClick = () => {
    history.push(backUrl);
  };

  return (
    <Button
      className="back-navigation-button-container mobile"
      as={Link}
      onClick={onBackClick}
    >
      <Icon name="arrow left" />
      <span className="back-navigation-button-text">Back</span>
    </Button>
  );
};
