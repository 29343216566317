import { createAxios } from "./axiosWrapper";
import { notifyError, notifyInfo} from "./notifications";
import { isEmpty, isNil } from "lodash";

const customerIntegrationUrl = `${process.env.REACT_APP_CUSTOMER_INTEGRATION_URL}/api`;

const boxInsightsDownloadController = () =>
  createAxios(`${customerIntegrationUrl}/BoxInsightsDownload`);

const customerPortalOrderController = () =>
  createAxios(`${customerIntegrationUrl}/CustomerPortalOrder`);

export const getBoxInsightUploadData = async ({
  customerGroupId,
  salesPeriodId,
}) => {
  const insightsUrl = `get-box-insights-upload-data?customerGroupId=${customerGroupId}&salesPeriodId=${salesPeriodId}`;

  try {
    const result = await boxInsightsDownloadController().get(insightsUrl);
    if (!isNil(result) && !isEmpty(result.data)) {
      return result.data;
    } else {
      notifyInfo("No data currently available for this Sight.");
    }
  } catch (error) {
    notifyError("Failed to download box insights data.", error);
  }
};

export const fetchUserCustomerGroupPermissions = async (customerGroupId) => {
  const permissionsUrl = `check-user-customer-group-box-insights-permission?customerGroupId=${customerGroupId}`;

  try {
    const result = await customerPortalOrderController().get(permissionsUrl);
    return result.data;
  } catch (error) {
    notifyError(
      "Failed to fetch customer group box insights permission.",
      error
    );
  }
};
