import React from "react";
import { Segment } from "semantic-ui-react";
import { StandardHeading } from "../../common/StandardHeading/StandardHeading";

export const MobileNotAvailable = () => {
  return (
    <Segment compact padded="very" className="mobile-message">
      <StandardHeading>
        Tablet, mobile and smaller screen view coming soon.
      </StandardHeading>
      <p>
        Please increase the size of your browser window, increase your screen
        resolution or use a larger device.
      </p>
    </Segment>
  );
};

export default MobileNotAvailable;
