import * as fileSaver from "file-saver";

import {
  assoc,
  contains,
  defaultTo,
  find,
  head,
  keys,
  lensProp,
  not,
  over,
  pipe,
  prop,
  propEq,
  propOr,
  sum,
  values,
  zip,
} from "ramda";
import {
  editExplanApplication,
  importExplanApplication,
} from "../../lib/apiExplan.js";
import { notifyError, notifySuccess } from "../../lib/notifications";

import Excel from "exceljs/dist/exceljs";
import autoSizeAllColumns from "../ExcelUtility/AutosizeColumns";
import { getOrderTypesForCustomerAndSalesPeriod } from "../../lib/apiOrder";
import { isExcelFile } from "../../lib/excelHelpers";
import { isNil } from "lodash";

export const calculateInPlanSum = (input) => {
  return input.reduce((a, b) => (a.inPlanTarget || a) + b.inPlanTarget, 0);
};
const processFile = async (
  file,
  setLoading,
  customerId,
  salesPeriodId,
  orderTypeId,
  setErrors,
  setRefreshAddApp,
  uploadComplete
) => {
  const fileReader = new FileReader();

  fileReader.onload = async function (event) {
    const data = event.target.result;
    const workbook = new Excel.Workbook();

    await workbook.xlsx.load(data);
    let errors = [];

    if (validateStructure(workbook)) {
      const data = readWorkbookContent(workbook);

      if (data?.errors?.length > 0) {
        setErrors(data.errors);
      } else {
        setLoading(true);
        try {
          errors = await importExplanApplication(
            customerId,
            salesPeriodId,
            orderTypeId,
            data.data
          );

          if (errors.length === 0) {
            setRefreshAddApp();
            notifySuccess("Upload of Explan Application successful.");
            uploadComplete();
          }
        } finally {
          setLoading(false);
        }
      }
    }

    setErrors(errors);
  };

  fileReader.onerror = (error) => {
    notifyError("Could not read the file.", error);
  };

  fileReader.readAsArrayBuffer(file);
};

const validateStructure = (workbook) => {
  const headers = {
    category: "Category",
    boxDescription: "Box Description",
    inPlanTarget: "In Plan Target",
    minimumBox: "Minimum Box",
    overallCapValue: "Category $,000",
    boxCap: "Box $,000",
    specifically: "Specifically",
    preferred: "Preferred",
    acceptable: "Acceptable",
    notRequired: "Not Required",
  };
  if (workbook) {
    const worksheet = workbook.worksheets[0];
    const row1Values = worksheet.getRow(1).values;
    const headerValues = Object.values(headers);

    if (row1Values.length === headerValues.length + 1) {
      let truthy = true;
      headerValues.forEach((val, index) => {
        truthy = truthy && val === row1Values[index + 1];
      });
      return truthy;
    }
  }
  return false;
};

const readWorkbookContent = (workbook) => {
  const worksheet = workbook.worksheets[0];
  const data = [];
  const errors = [];

  worksheet.eachRow((row, rowNumber) => {
    const rowValues = worksheet.getRow(rowNumber).values;

    if (rowNumber !== 1 && rowValues[1] !== "Totals") {
      data.push({
        category: rowValues[1],
        boxDescription: rowValues[2],
        inPlanTarget:
          rowValues[3] === "-" || rowValues[3] === "" || !rowValues[3]
            ? 0
            : rowValues[3],
        minimumBox: rowValues[4],
        categoryCapValue:
          rowValues[5] === "-" || rowValues[5] === "" || !rowValues[5]
            ? 0
            : rowValues[5],
        boxCapValue:
          rowValues[6] === "-" || rowValues[6] === "" || !rowValues[6]
            ? 0
            : rowValues[6],
        specifically:
          (rowValues[7]?.toLowerCase() ?? "") === "yes" ? true : false,
        preferred: (rowValues[8]?.toLowerCase() ?? "") === "yes" ? true : false,
        acceptable:
          (rowValues[9]?.toLowerCase() ?? "") === "yes" ? true : false,
        notRequired:
          (rowValues[10]?.toLowerCase() ?? "") === "yes" ? true : false,
      });
    }
  });

  return { data, errors };
};

export const processBulkUploadFile = async ({
  file,
  setLoading,
  customerId,
  salesPeriodId,
  orderTypeId,
  setErrors,
  setRefreshAddApp,
  uploadComplete,
}) => {
  try {
    if (file) {
      if (isExcelFile(file)) {
        var response = await processFile(
          file,
          setLoading,
          customerId,
          salesPeriodId,
          orderTypeId,
          setErrors,
          setRefreshAddApp,
          uploadComplete
        );

        return response;
      }
    }
  } catch (err) {
    notifyError("Upload unsuccessful.", err);
  }
};

export const validateFileType = async ({ file, setError }) => {
  if (!isExcelFile(file)) {
    setError(
      "Upload status: Upload failed due to wrong file format, upload a new file in order to correct."
    );
  } else {
    setError(null);
  }
};

export const saveMobileExplanRequestChanges = async (
  dirtyDataDictionary,
  customerId,
  salesPeriodId,
  selectedOrderType,
  setEditing
) => {
  const saveData = [];

  try {
    for (let i = 0; i < dirtyDataDictionary.length; i++) {
      const categoryData = dirtyDataDictionary[i];

      const boxCategories = categoryData.data.map((box) => ({
        box: box.box,
        boxDescription: box.boxDescription,
        boxId: box.boxId,
        boxToExplanApplicationId: box.boxToExplanApplicationId,
        category: box.category,
        categoryId: box.categoryId,
        explanRequestPreference: box.explanRequestPreference,
        inPlanTarget: box.inPlanTarget,
        minBoxSize: box.minBoxSize,
        type: box.type,
        value: categoryData.value,
        capValue: box.capValue,
      }));

      const explanRequest = {
        explanApplicationId: 0,
        customerId: customerId,
        salesPeriod: salesPeriodId,
        value: categoryData.value,
        categories: [],
        boxCategories: boxCategories,
        orderTypeId: selectedOrderType.id,
      };

      saveData.push(explanRequest);
    }

    const data = await editExplanApplication(saveData);
    
    if(!isNil(data)) {
      notifySuccess(
        "Explan request successfully saved.",
        "Changes applied to Ex-Plan requests."
      );
    }

    setEditing(false);

    return true;

  } catch (err) {
    notifyError(
      "Unable to save Ex-Plan Application.",
      err
    );
    return false;
  }
};

export const saveExplanRequestChanges = async (
  tableData,
  dirtyDataDictionary,
  customerId,
  salesPeriodId,
  selectedOrderType,
  setEditing
) => {
  const saveData = [];

  try {
    for (const key in dirtyDataDictionary) {
      const category = dirtyDataDictionary[key];
      const categoryData = tableData.find((x) => x.category === key);

      const boxCategories = categoryData.data.map((box) => ({
        box: box.box,
        boxDescription: box.boxDescription,
        boxId: box.boxId,
        boxToExplanApplicationId: box.boxToExplanApplicationId,
        category: box.category,
        categoryId: box.categoryId,
        explanRequestPreference: box.explanRequestPreference,
        inPlanTarget: box.inPlanTarget,
        minBoxSize: box.minBoxSize,
        type: category.boxes[box.boxId],
        value: category.value,
        capValue: category.capValues[box.boxId],
      }));

      const explanRequest = {
        explanApplicationId: 0,
        customerId: customerId,
        salesPeriod: salesPeriodId,
        value: category.value,
        categories: [],
        boxCategories: boxCategories,
        orderTypeId: selectedOrderType,
      };

      saveData.push(explanRequest);
    }

    const data = await editExplanApplication(saveData);
    if(!isNil(data)) {
      notifySuccess(
        "Explan request successfully saved.",
        "Changes applied to Ex-Plan requests."
      );
    }

    setEditing(false);
  } catch (err) {
    notifyError(
      "Unable to save Explan Application.",
      "Changes applied to Ex-Plan requests."
    );
  }
};

export const getCustomerOrderTypes = async (
  setOrderTypes,
  setSelectedOrderType,
  customerId,
  salesPeriodId,
  setLoadingOrderTypes
) => {
  setLoadingOrderTypes(true);
  const orderTypesRaw = await getOrderTypesForCustomerAndSalesPeriod(
    customerId,
    salesPeriodId
  );

  if (orderTypesRaw) {
    const orderTypes = orderTypesRaw
      .filter(pipe(prop("name"), contains("FM / DBJ"), not))
      .map(({ orderTypeId, name }) => ({
        key: orderTypeId,
        text: name,
        value: orderTypeId,
      }));

    setOrderTypes(orderTypes);
    setSelectedOrderType(orderTypes[0]?.key);
    setLoadingOrderTypes(false);
  }
};

export const anySpecific = pipe(
  (boxes) => zip(keys(boxes), values(boxes)),
  find(propEq(1, "Specifically")),
  defaultTo([]),
  head
);

export const downloadExplanExcelReport = async (data) => {
  try {
    const workbook = new Excel.Workbook("ExplanExport");
    const worksheet = workbook.addWorksheet("ExplanExport");

    populateWorksheet(worksheet, data);
    autoSizeAllColumns(worksheet, 20);
    downloadWorkbook(workbook);
  } catch (err) {
    notifyError("Could not download explan report for all customers.");
  } finally {
  }
};

const downloadWorkbook = (workbook) => {
  workbook.xlsx.writeBuffer().then((data) => {
    fileSaver.saveAs(
      new Blob([data]),
      "ExplanExport.xlsx",
      { autoBom: false },
      true
    );
  });
};

const populateWorksheet = (worksheet, data) => {
  const headers = [
    "Category",
    "Box Description",
    "In Plan Target",
    "Minimum Box",
    "Category $,000",
    "Box $,000",
    "Specifically",
    "Preferred",
    "Acceptable",
    "Not Required",
  ];

  const defaultHeaderStyles = {
    fill: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF172F4A" },
      bgColor: { argb: "FF172F4A" },
    },
    font: {
      bold: true,
      color: { argb: "FFFFFF" },
      name: "Franklin Gothic Book",
      size: 13,
    },
    alignment: { horizontal: "center", vertical: "middle" },
    height: 35,
  };

  const entryRowStyles = {
    height: 25,
  };

  const summaryRowStyles = {
    fill: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "eaf2fb" },
      bgColor: { argb: "eaf2fb" },
    },
    font: { bold: true, name: "Franklin Gothic Book", size: 13 },
    alignment: { horizontal: "center", vertical: "middle" },
    height: 35,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
    },
  };

  const grandTotalRowStyles = {
    fill: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "d7e6f7" },
      bgColor: { argb: "d7e6f7" },
    },
    font: { bold: true, name: "Franklin Gothic Book", size: 13 },
    alignment: { horizontal: "center", vertical: "middle" },
    height: 35,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
    },
  };

  const centerMiddleAlignment = { horizontal: "center", vertical: "middle" };
  const rightMiddleAlignment = { horizontal: "right", vertical: "middle" };
  const leftMiddleAlignment = { horizontal: "left", vertical: "middle" };
  const rightBorder = { right: { style: "thin" } };
  const totalsEndCellBorder = {
    top: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  };

  let currentRowKey = 1;

  const currentRow = worksheet.getRow(currentRowKey);

  currentRow.values = headers;

  for (let columnKey = 1; columnKey <= headers.length; columnKey++) {
    currentRow.getCell(columnKey).fill = defaultHeaderStyles.fill;
  }

  worksheet.getColumn(1).width = 35;
  worksheet.getColumn(2).width = 20;
  worksheet.getColumn(3).width = 35;
  worksheet.getColumn(4).width = 20;
  worksheet.getColumn(5).width = 20;
  worksheet.getColumn(6).width = 20;
  worksheet.getColumn(7).width = 20;
  worksheet.getColumn(8).width = 20;
  worksheet.getColumn(9).width = 20;
  worksheet.getColumn(10).width = 20;

  currentRow.font = defaultHeaderStyles.font;
  currentRow.height = defaultHeaderStyles.height;
  currentRow.alignment = defaultHeaderStyles.alignment;
  worksheet.getCell("A1").alignment = leftMiddleAlignment;
  worksheet.getCell("B1").alignment = leftMiddleAlignment;
  worksheet.getCell("D1").alignment = leftMiddleAlignment;

  const distinctCategories = [...new Set(data.map((a) => a.category))];

  let grandTotalFormula = "SUM(";
  distinctCategories.forEach((category) => {
    const startRow = currentRowKey + 1;

    const boxesIncategory = data.filter((a) => a.category === category);

    boxesIncategory.forEach((entry) => {
      currentRowKey++;

      const entryRow = worksheet.getRow(currentRowKey);
      entryRow.height = entryRowStyles.height;
      entryRow.values = [
        entry.category,
        entry.boxDescription,
        entry.inPlanTarget === 0 ? "-" : entry.inPlanTarget,
        entry.minimumBox,
        entry.categoryCapValue === 0 ? "-" : entry.categoryCapValue,
        entry.boxCapValue === 0 ? "-" : entry.boxCapValue,
        entry.specifically ? "Yes" : "",
        entry.preferred ? "Yes" : "",
        entry.acceptable ? "Yes" : "",
        entry.notRequired ? "Yes" : "",
      ];

      worksheet.getCell("C" + currentRowKey).alignment = rightMiddleAlignment;
      worksheet.getCell("D" + currentRowKey).alignment = rightMiddleAlignment;
      worksheet.getCell("E" + currentRowKey).alignment = centerMiddleAlignment;
      worksheet.getCell("F" + currentRowKey).alignment = rightMiddleAlignment;
      worksheet.getCell("G" + currentRowKey).alignment = centerMiddleAlignment;
      worksheet.getCell("H" + currentRowKey).alignment = centerMiddleAlignment;
      worksheet.getCell("I" + currentRowKey).alignment = centerMiddleAlignment;
      worksheet.getCell("J" + currentRowKey).alignment = centerMiddleAlignment;
      worksheet.getCell("J" + currentRowKey).border = rightBorder;
      worksheet.getCell("A" + currentRowKey).protection = {
        locked: true,
        lockText: true,
      };
    });
    const endRow = currentRowKey;

    const cellsToMerge = "E" + startRow + ":E" + endRow;

    worksheet.getCell(cellsToMerge).alignment = centerMiddleAlignment;

    worksheet.mergeCells(cellsToMerge);

    currentRowKey++;

    const totalsRow = worksheet.getRow(currentRowKey);
    grandTotalFormula = grandTotalFormula + "E" + currentRowKey + ",";

    totalsRow.values = [
      "Totals",
      "",
      { formula: "SUM(C" + startRow + ":C" + endRow + ")" },
      "",
      { formula: "E" + startRow },
      { formula: "SUM(F" + startRow + ":F" + endRow + ")" },
      "",
      "",
      "",
      "",
    ];

    totalsRow.font = summaryRowStyles.font;
    worksheet.getCell("E" + currentRowKey).alignment = centerMiddleAlignment;
    worksheet.getCell("F" + currentRowKey).alignment = rightMiddleAlignment;

    for (let columnKey = 1; columnKey <= headers.length; columnKey++) {
      totalsRow.getCell(columnKey).fill = summaryRowStyles.fill;
      totalsRow.getCell(columnKey).border = summaryRowStyles.border;
    }
    worksheet.getCell("J" + currentRowKey).border = totalsEndCellBorder;
  });
  currentRowKey++;
  const grandTotalsRow = worksheet.getRow(currentRowKey);

  grandTotalFormula = grandTotalFormula.slice(0, -1);
  grandTotalFormula = grandTotalFormula + ")";

  grandTotalsRow.values = [
    "Totals",
    "",
    "",
    "",
    { formula: grandTotalFormula },
    "",
    "",
    "",
    "",
    "",
  ];
  grandTotalsRow.font = grandTotalRowStyles.font;
  worksheet.getCell("E" + currentRowKey).alignment = centerMiddleAlignment;

  for (let columnKey = 1; columnKey <= headers.length; columnKey++) {
    grandTotalsRow.getCell(columnKey).fill = grandTotalRowStyles.fill;
    grandTotalsRow.getCell(columnKey).border = grandTotalRowStyles.border;
  }
  worksheet.getCell("J" + currentRowKey).border = totalsEndCellBorder;
};

export const onCheckedChange = (
  value,
  record,
  setDirtyDataDictionary,
  val,
  setHasErrors,
  displayErrorBanner,
  setDisplayErrorBanner,
  indexKey,
  categoryKey
) => {
  if (value === "Specifically") {
    setDirtyDataDictionary((prev) => {
      const newValue = { ...prev };

      for (const [key, entryValue] of Object.entries(
        newValue.boxes[categoryKey].data
      )) {
        if (+key === +indexKey) {
        } else {
          entryValue.type = "Not required";
          entryValue.capValue = 0;
        }
      }
      newValue.boxes[categoryKey].data[indexKey].type = value;
      newValue.boxes[categoryKey].value = val;

      newValue.capValues = propOr([], "capValues", newValue).map(
        (capValue, index) => {
          if (index === record.boxId) {
            return val;
          }
          return 0;
        }
      );

      validate(
        setHasErrors,
        displayErrorBanner,
        setDisplayErrorBanner
      )(
        pipe((updatedValue) =>
          assoc(
            "capValueTotal",
            sum(updatedValue.capValues.filter((num) => !isNaN(num))),
            updatedValue
          )
        )(newValue)
      );

      return newValue;
    });
  } else if (value === "Not required") {
    setDirtyDataDictionary((prev) => {
      const newValue = { ...prev };
      newValue.boxes = { ...newValue.boxes };
      newValue.boxes[categoryKey].data[indexKey].type = value;
      newValue.boxes[categoryKey].data[indexKey].capValue = 0;
      newValue.value = val;

      newValue.capValues = propOr([], "capValues", newValue).map(
        (capValue, index) => {
          if (index === record.boxId) {
            return 0;
          }
          return capValue;
        }
      );

      validate(
        setHasErrors,
        displayErrorBanner,
        setDisplayErrorBanner
      )(newValue);

      newValue.boxes = Object.values(newValue.boxes);

      return newValue;
    });
  } else {
    setDirtyDataDictionary((prev) => {
      const newValue = { ...prev };

      newValue.boxes = { ...newValue.boxes };

      newValue.boxes[categoryKey].data[indexKey].type = value;

      for (const [key, entryValue] of Object.entries(
        newValue.boxes[categoryKey].data
      )) {
        if (entryValue.type === "Specifically" && key) {
          entryValue.type = "Preferred";
        }
      }

      validate(
        setHasErrors,
        displayErrorBanner,
        setDisplayErrorBanner
      )(newValue);

      newValue.boxes = Object.values(newValue.boxes);

      return newValue;
    });
  }
};

export const onValueChange = (
  record,
  setDirtyDataDictionary,
  updateTotal,
  setHasErrors,
  displayErrorBanner,
  setDisplayErrorBanner
) => ({ value, indexKey }) => {
  setDirtyDataDictionary((prev) => {
    const newValue = { ...prev };

    if (
      newValue.boxes[indexKey].value === undefined &&
      parseFloat(value) === record.value
    ) {
      return prev;
    }

    const previousValue = newValue.boxes[indexKey].value || 0;

    newValue.boxes[indexKey].value = value || 0;

    updateTotal(previousValue, newValue.boxes[indexKey].value);

    validate(setHasErrors, displayErrorBanner, setDisplayErrorBanner)(newValue);

    return newValue;
  });
};

export const onCapValueChange = (
  record,
  setDirtyDataDictionary,
  setHasErrors,
  displayErrorBanner,
  setDisplayErrorBanner,
  indexKey,
  categoryKey
) => ({ capValue }) => {
  setDirtyDataDictionary((prev) => {
    const newValue = { ...prev };
    newValue.boxes[categoryKey].data[indexKey].capValue = capValue;

    return newValue;
  });
};

export const validateCapValues = (context) => {
  if (context) {
    if (context.errors.capValues === undefined) {
      context.errors.capValues = [];
    }

    if (context.capValues === undefined) {
      return context;
    }

    let total = 0;
    context.capValues.forEach((capValue, index) => {
      if (parseFloat(capValue) > parseFloat(context.value)) {
        context.errors.capValues[index] =
          "The box value cannot exceed the category value";
      } else {
        delete context.errors.capValues[index];
      }

      total += parseFloat(capValue);
    });

    if (total < parseFloat(context.value)) {
      context.errors.capValueTotal =
        "The total box value should be greater than or equal to the category value";
    } else {
      delete context.errors.capValueTotal;
    }

    return context;
  } else {
    return null;
  }
};

export const validateValue = (context) => {
  let notAllBoxesNotRequired = false;
  let specificallyValidation = false;
  if (context && context.boxes) {
    const boxes = Object.keys(context.boxes);

    if (boxes && boxes.length > 0) {
      notAllBoxesNotRequired =
        boxes.filter((boxId) => {
          return context.boxes[boxId] !== "Not required";
        }).length > 0;
    }

    const specificallyBoxes = boxes.filter((boxId) => {
      return context.boxes[boxId] === "Specifically";
    });
    const otherBoxes = boxes.filter((boxId) => {
      return (
        context.boxes[boxId] !== "Specifically" &&
        context.boxes[boxId] !== "Not required"
      );
    });

    if (
      specificallyBoxes.length > 1 ||
      (specificallyBoxes.length > 0 && otherBoxes.length > 0)
    ) {
      specificallyValidation = true;
    }
  }

  if (notAllBoxesNotRequired && (!context.value || context.value <= 0)) {
    context.errors.value =
      "Value is required if any box preference is set to a value other than not required";
  } else if (context && context.value > 0 && !notAllBoxesNotRequired) {
    context.errors.value =
      "When a value is entered, at least one box preference needs to be set";
  } else if (specificallyValidation) {
    context.errors.value =
      "Only one box can be marked Specifically, all others need to be marked not required.";
  } else if (context) {
    delete context.errors.value;
  }

  return context;
};

export const initialiseErrors = (context) => {
  if (context && context?.errors === undefined) {
    context.errors = {};
  }

  return context;
};

export const errorsLens = lensProp("errors");

export const hasErrors = (errors) =>
  !!errors?.value ||
  errors?.capValues.some((error) => !!error) ||
  !!errors?.capValueTotal;

export const validateForm = (
  setHasErrors,
  displayErrorBanner,
  setDisplayErrorBanner
) =>
  over(errorsLens, (errors) => {
    const errorExists = hasErrors(errors);
    if (!errorExists) {
      setDisplayErrorBanner(false);
    } else if (displayErrorBanner) {
      setHasErrors(errorExists);
    }

    return assoc("hasErrors", errorExists, errors);
  });

export const validate = (
  setHasErrors,
  displayErrorBanner,
  setDisplayErrorBanner
) =>
  pipe(
    initialiseErrors,
    validateValue,
    validateCapValues,
    validateForm(setHasErrors, displayErrorBanner, setDisplayErrorBanner)
  );
