import React from "react";
import { Image, Dimmer, Grid } from "semantic-ui-react";
import loaderImage from "../media/loader.gif";
import "./LoaderComponent.scss";

export const LoaderComponent = ({ loading, children, message }) => {
  const loader = (
    <Grid className="loader-component">
      <Grid.Row className="mt-5 mb-5">
        <Dimmer active inverted>
          <Image src={loaderImage} size="tiny" />
          <h3 className="loading-text">{message || "Loading..."}</h3>
        </Dimmer>
      </Grid.Row>
    </Grid>
  );

  return (
    <div>
      {!loading && children}
      {loading && loader}
    </div>
  );
};
