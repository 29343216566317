/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import "./IconAction.scss";
import { isFunction } from "lodash";

export const IconAction = ({ title, data, action }) => {
  const disabled = isFunction(action.disabled)
    ? action.disabled(data)
    : action.disabled;

 const className = disabled
    ? "index-table-icon-action disabled"
    : "index-table-icon-action";

  const onClick = () => {
    if (!disabled) {
      action.onClick(data);
    }
  };

  return (
    <div className={className}>
      <div className="icon-container" onClick={onClick}>
        {action.icon}
      </div>
      <div className="text-container" onClick={onClick}>
        {title}
      </div>
    </div>
  );
};
