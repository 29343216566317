import { createAxios } from "./axiosWrapper";
import { notifyError } from "./notifications";

const emailUrl = `${process.env.REACT_APP_CUSTOMER_INTEGRATION_URL}/api/email`;

const axios = () => createAxios(emailUrl);

export const resubscribeEmail = async ({ setLoading }) => {
  setLoading(true);
  try {
    const requestUrl = `/resubscribe`;

    await axios().post(requestUrl);
  } catch (err) {
    notifyError("Failed to resubscribe user to email notifications", err);
  } finally {
    setLoading(false);
  }
};

export const unsubscribeEmail = async ({ setLoading }) => {
  setLoading(true);
  try {
    const requestUrl = `/unsubscribe`;

    await axios().post(requestUrl);
  } catch (err) {
    notifyError("Failed to unsubscribe user from email notifications", err);
  } finally {
    setLoading(false);
  }
};

export const getCurrentEmailPreference = async ({
  setLoading,
  setPreference,
}) => {
  setLoading(true);
  try {
    const requestUrl = `/preference`;

    const preference = await axios().get(requestUrl);
    setPreference(preference.data);
  } catch (err) {
    notifyError("Failed to fetch the email preference for current user", err);
  } finally {
    setLoading(false);
  }
};
