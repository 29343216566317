import React from "react";
import { Card } from "semantic-ui-react";
import moment from "moment";
import {
  UilEnvelope,
  UilEnvelopeCheck,
  UilAngleRight,
} from "@iconscout/react-unicons";
import { Link } from "react-router-dom";
import { find, pipe, propEq, propOr } from "ramda";
import { notificationMetadataEnum, notificationTypes } from "../../lib/enums";

const getCustomerIdMetaData = pipe(
  propOr([], "notificationMetadata"),
  find(propEq("key", notificationMetadataEnum.customerId))
);

export const NotificationCard = ({
  notification,
  markAsRead,
  viewNotification,
  viewingNotification,
  closePopup,
}) => (
  <Card
    className={`notification-card ${
      notification.dateRead === null && "unread"
    }`}
  >
    <div>
      <div className="notification-card-heading">
        <div
          className={["heading", viewingNotification ? "" : "max-height"].join(
            " "
          )}
        >
          {notification.headerText}
        </div>
        <div className="date">
          {moment(notification.dateSent).format("DD/MM/YYYY")}
          {viewNotification && (
            <UilAngleRight className="right-arrow" onClick={viewNotification} />
          )}
        </div>
      </div>
      <div className="notification-content-container">
        <p
          className={[
            "notification-content",
            viewingNotification ? "" : "max-height",
          ].join(" ")}
        >
          {notification.messageText}
        </p>
      </div>
      <div className="notification-action">
        {notification.notificationTypeId ===
          notificationTypes.ApplicationsDeliveryScheduleSubmissionAdjustment && (
          <Link
            to={{
              pathname: "/orders/capture-pds",
            }}
            onClick={closePopup}
          >
            Enter PDS
          </Link>
        )}
        {notification.notificationTypeId ===
          notificationTypes.ApplicationsITOSwapRequests && (
          <Link
            to={{
              pathname: "/orders",
            }}
            onClick={closePopup}
          >
            Apply
          </Link>
        )}
        {notification.notificationTypeId ===
          notificationTypes.ApplicationsApplications && (
          <Link
            to={{
              pathname: "/current-sight/applications",
            }}
            onClick={closePopup}
          >
            Make Application Changes
          </Link>
        )}
        {notification.notificationTypeId ===
          notificationTypes.ApplicationsAdditionalGoodsApplication && (
          <Link
            to={{
              pathname: "/current-sight/additionalapplications",
            }}
            onClick={closePopup}
          >
            Apply
          </Link>
        )}
        {/* Awaiting confirmation on what "Sight by Sight" was meant for */}
        {/*         {notification.notificationTypeId === notificationTypes.AllocationsSightbysight && (
          <Link
            to={{
              pathname: "/orders/capture-pds"
            }}
          >
            Enter PDS
          </Link>
        )} */}
        {notification.notificationTypeId ===
          notificationTypes.AllocationsITOAnnouncements && (
          <Link
            to={{
              pathname: "/current-sight/allocations",
              state: { customerMetadata: getCustomerIdMetaData(notification) },
            }}
            onClick={closePopup}
          >
            View Allocations
          </Link>
        )}
        {notification.notificationTypeId ===
          notificationTypes.ITOAnnouncement && (
          <Link
            to={{
              pathname: "/orders",
            }}
            onClick={closePopup}
          >
            View ITO
          </Link>
        )}
        {notification.notificationTypeId ===
          notificationTypes.ManufacturingDataSubmission && (
          <Link
            to={{
              pathname: "/insights",
            }}
            onClick={closePopup}
          >
            Submit Documents
          </Link>
        )}
        {!notification.dateRead && (
          <UilEnvelope onClick={markAsRead} className="unread-icon" />
        )}
        {notification.dateRead && <UilEnvelopeCheck className="read-icon" />}
      </div>
    </div>
  </Card>
);
