import React from "react";
import { withRouter } from "react-router-dom";
import { Routes } from "./modules/Routes";
import "./styles/shared.scss";
import GA4React from "ga-4-react";

const googleAnalyticsKey = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;

if (googleAnalyticsKey) {
  const ga4react = new GA4React(googleAnalyticsKey);
  ga4react.initialize().then(() => {
  },(err) => {
      console.error(err);
    })
}

const App = () => {
  return <Routes />;
};

export default withRouter(App);
