import * as fileSaver from "file-saver";

export const saveFileForUser = async (workbookAwaiter, fileName) => {
  const workbook = await workbookAwaiter;
  workbook.xlsx.writeBuffer().then((data) => {
    fileSaver.saveAs(new Blob([data]), `${fileName}`, true);
  });
};

export const isExcelFile = (file) => {
  if (file) {
    return (
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel"
    );
  } else {
    return true;
  }
};
