import { notifyError } from "./notifications";
import { createAxios } from "./axiosWrapper";

const embeddedReportUrl = `${process.env.REACT_APP_CUSTOMER_INTEGRATION_URL}/api/PowerBiEmbeddedReports`;
// const powerBiCustomerRoleUrl = `${process.env.REACT_APP_CUSTOMER_INTEGRATION_URL}/api/PowerBiRepresentativeRoles`;

const embeddedReportController = () => createAxios(embeddedReportUrl);
// const representativeRoleController = () => createAxios(powerBiCustomerRoleUrl);

export const getPowerBiReportList = async () => {
  try {
    const response = await embeddedReportController().get(`get-reports-for-user`);

    return response.data;
  } catch (err) {
    notifyError("Failed to fetch Customer Report list", err);
  }
};

export const getEmbedToken = async (embeddedReportId) => {
  try {
    const response = await embeddedReportController().get(
      `generate-embed-token`,
      {
        params: { embeddedReportId },
      }
    );

    return response.data;
  } catch (err) {
    notifyError("Failed to fetch Embedded Report", err);
    throw err;
  }
};

export const fetchPowerBiReportPermissions = async () => {
  const permissionsUrl = `check-user-power-bi-reports-permission`;

  try {
    const result = await embeddedReportController().get(permissionsUrl);
    return result.data;
  } catch (error) {
    notifyError(
      "Failed to fetch Power BI report permission.",
      error
    );
    
    return false;
  }
};