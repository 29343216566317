import { clone, mergeRight, prop, sortBy } from "ramda";
import {
  getAdditionalApplicationData,
  getNonItoAdditionalApplicationData,
} from "../../../lib/apiOrder";

import { getAdditionalApplicationLastUpdate } from "../../../lib/apiAdditionalApplications";
import { getOrderTypesForCustomerAndSalesPeriod } from "../../../lib/apiOrder";
import { notifyError } from "../../../lib/notifications";
import { searchBoxes } from "../../../lib/apiProductManagement";

const sortByBoxDescription = sortBy(prop("boxDescription"));

export const getAdditionalApplications = async ({
  customerId,
  salesPeriodId,
  setter,
  setLoading,
  selectedOrderTypeId,
}) => {
  setLoading(true);
  try {
    if (customerId && salesPeriodId) {
      const additionalApplications = await getAdditionalApplicationData({
        customerId,
        salesPeriodId,
        selectedOrderTypeId,
      });
      setter(sortByBoxDescription(additionalApplications) || []);
    }
  } catch (err) {
    notifyError("Failed to fetch additional applications", err);
  } finally {
    setLoading(false);
  }
};

export const getNonItoAdditionalApplications = async ({
  customerId,
  salesPeriodId,
  setter,
  setLoading,
}) => {
  setLoading(true);
  try {
    if (customerId && salesPeriodId) {
      const additionalApplications = await getNonItoAdditionalApplicationData({
        customerId,
        salesPeriodId,
      });
      setter(additionalApplications || []);
    }
  } catch (err) {
    notifyError("Failed to fetch non-ito additional applications", err);
  } finally {
    setLoading(false);
  }
};

export const handleSubmit = (values, { props, setStatus }) => {
  props.setIsAdding(true);
  const categoryPicked = props.categoryData.find(
    (element) => element.key === values.category
  );

  const boxId = values.boxId;

  const editedProductTableData = props.productTableData;
  const position = editedProductTableData.findIndex(
    (x) => x.box.boxId === boxId
  );

  if (position < 0) {
    editedProductTableData.push({
      category: categoryPicked,
      box: {
        boxId: boxId,
        value: props.productsSearch,
      },
      ito: 0,
    });

    props.setproductTableData(editedProductTableData);
  }

  props.changeProductsSearch("");
  props.setShowTable(true);

  setStatus({ success: true });
  props.setIsAdding(false);
};

export const removeItem = (index, productTableData, setproductTableData) => {
  if (productTableData.length > index) {
    productTableData.splice(index, 1);
    setproductTableData([...productTableData]);
  }
};

export const searchForProducts = async (
  search,
  categoryID,
  setProducts,
  setLoading
) => {
  setLoading(true);
  try {
    await fetchProducts(setProducts, search, categoryID);
  } catch (err) {
    notifyError("Could not search for products", err);
  } finally {
    setLoading(false);
  }
};

export const fetchProducts = async (setProducts, search = "", categoryID) => {
  const productsRaw = await searchBoxes(
    { name: search, categoryId: categoryID },
    5
  );
  const products = productsRaw.data.map(({ boxId, name }) => ({
    title: name,
    value: boxId,
  }));
  setProducts(products);
};

export const fetchadditionalApplicationLastUpdate = async (
  customerId,
  setLoading,
  setLastUpdateDate
) => {
  if (customerId) {
    setLoading(true);
    try {
      const _lastUpdateDate = await getAdditionalApplicationLastUpdate(
        customerId
      );
      setLastUpdateDate(_lastUpdateDate);
    } finally {
      setLoading(false);
    }
  }
};

export const getCustomerOrderTypes = async (
  setOrderTypes,
  setSelectedOrderType,
  customerId,
  salesPeriodId,
  setLoadingOrderTypes
) => {
  setLoadingOrderTypes(true);
  const orderTypesRaw = await getOrderTypesForCustomerAndSalesPeriod(
    customerId,
    salesPeriodId
  );

  if (orderTypesRaw) {
    const orderTypes = orderTypesRaw
      .map(({ orderTypeId, name }) => ({
        key: orderTypeId,
        text: name,
        value: orderTypeId,
      }));
    setOrderTypes(orderTypes);
    setSelectedOrderType((prev) =>
      mergeRight(prev, {
        id: orderTypes[0]?.value,
        name: orderTypes[0]?.text,
      })
    );
    setLoadingOrderTypes(false);
  }
};

export const handleMobileAdditionalApplicationAmountChange = async (
  value,
  box,
  orderType,
  setEditApplications,
  validateAdditionalApplication
) => {
  setEditApplications((prev) => {
    const newEditApplications = clone(prev);

    const foundIndex = newEditApplications.findIndex(
      (item) => item.boxId === box.boxId && item.orderType === orderType
    );

    if (foundIndex >= 0) {
      newEditApplications[foundIndex].amount = +value >= 0 ? +value : 0;
      newEditApplications[foundIndex].error = validateAdditionalApplication(
        value,
        box
      );
    } else {
      const newItem = {
        boxId: box.boxId,
        orderType: orderType,
        amount: +value >= 0 ? +value : 0,
        error: validateAdditionalApplication(value, box),
      };

      newEditApplications.push(newItem);
    }

    return newEditApplications;
  });
};
