import { createAxios } from "./axiosWrapper";
import { notifyError, notifyProblem, notifySuccess } from "./notifications";

const additionalApplicationServiceUrl = `${process.env.REACT_APP_CUSTOMER_INTEGRATION_URL}/api/AdditionalRequestApplication`;

const axios = () => createAxios(additionalApplicationServiceUrl);

export const upsertNonItoAdditionalApplication = async (data) => {
  try {
    const additionalApplicationResult = await axios().put("non-ito", data);

    return additionalApplicationResult.data;
  } catch (err) {
    notifyError("Failed to create additional application", err);
  }
};

export const bulkUploadAdditionalApplications = async (
  additionalApplications,
  customerId,
  salesPeriodId,
  uploadComplete
) => {
  try {
    const result = await axios().put("bulk-upload", {
      customerId,
      salesPeriodId,
      additionalApplications,
    });
    await uploadComplete(true);
    notifySuccess("Upload of Additional Applications successful.");

    return result.data;
  } catch (err) {
    await uploadComplete(false);
    notifyProblem("Failed to bulk upload additional applications.");
  }
};

export const deleteAdditionalApplication = async (applicationId) => {
  try {
    await axios().delete(
      `${additionalApplicationServiceUrl}/delete-by-application?applicationId=${applicationId}`
    );
  } catch (error) {
    notifyError("Failed to delete additional application", error);
  }
};

export const getAdditionalApplicationLastUpdate = async (customerId) => {
  try {
    const requestUrl = `get-additional-application-last-update`;

    const lastUpdateResponse = await axios().get(requestUrl, {
      params: {
        customerId,
      },
    });

    return lastUpdateResponse.data;
  } catch (err) {
    notifyError(
      "Failed to retrieve last additional application update date",
      err
    );
  }
};

export const updateServerFavouriteAsync = async (customerId, boxId, value) => {
  try {
    const requestUrl = "update-user-to-box-favourite";

    await axios().post(
      requestUrl +
        "?customerId=" +
        customerId +
        "&boxId=" +
        boxId +
        "&create=" +
        value
    );
  } catch (err) {
    notifyError("Could not set favourite.  Please try again.", err);
  }
};

export const getUserBoxFavourites = async (customerId) => {
  try {
    const requestUrl = `get-user-box-favourites?customerId=${customerId}`;

    const favouritesList = await axios().get(requestUrl);

    return favouritesList.data;
  } catch (err) {
    notifyError("Failed to retrieve favourites", err);
  }
};
