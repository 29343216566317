/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useContext, useEffect } from "react";
import { Divider, Button, Form, Table } from "semantic-ui-react";
import { SelectedCustomerGroupContext } from "../../common/CustomerGroupSelector/CustomerGroupSelectorContext";
import { mergeDeepRight, update, remove } from "ramda";
import * as Unicons from "@iconscout/react-unicons";
import {
  validateBankName,
  validateBankNames,
  validateBankCountries,
  submitBanksForCustomer,
  fetchBankListForCustomerGroup,
  fetchCountries,
  fetchCustomerGroup,
  getCustomerGroupPermission,
} from "./BankList.functions";
import { IconButton } from "../../common/IconButton/IconButton";
import { Popup } from "../../common/Popup/Popup";
import { StandardSubheading } from "../../common/StandardSubheading/StandardSubheading";

const createRemoveAction = ({ setter, setErrors, errors, list, index }) => {
  const removeFromList = () => {
    setter(remove(index, 1, list));
    setErrors(remove(index, 1, errors));
  };
  return (
    <div className="index-table-icon-action">
      <div className="icon-container" onClick={removeFromList}>
        <Unicons.UilMinusCircle size={18} />
      </div>
      <div className="text-container" onClick={removeFromList}>
        Remove
      </div>
    </div>
  );
};

export const BankList = ({ setEditingBankList, editing }) => {
  const { selectedCustomerGroup } = useContext(SelectedCustomerGroupContext);
  const [submitting, setSubmitting] = useState(false);
  const [canSubmit, setCanSubmit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [loadingBankList, setLoadingBankList] = useState(false);
  const [newBankList, setNewBankList] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [loadingCountries, setLoadingCountries] = useState(false);
  const [errors, setErrors] = useState([]);
  const [customerGroup, setCustomerGroup] = useState();
  const [
    hasOrderManagementPermissionForCustomerGroup,
    setHasOrderManagementPermissionForCustomerGroup,
  ] = useState(false);

  useEffect(() => {
    fetchCustomerGroup({
      setCustomerGroup,
      customerGroupId: selectedCustomerGroup,
    });
    
    getCustomerGroupPermission(
      selectedCustomerGroup,
      setHasOrderManagementPermissionForCustomerGroup
    );
  }, [selectedCustomerGroup]);

  useEffect(() => {
    if (hasOrderManagementPermissionForCustomerGroup) {
      fetchBankListForCustomerGroup({
        setLoading: setLoadingBankList,
        customerGroupId: selectedCustomerGroup,
        setBankList,
      });
      fetchCountries({ setLoading: setLoadingCountries, setCountries });
    }
  }, [selectedCustomerGroup, hasOrderManagementPermissionForCustomerGroup]);

  useEffect(() => {
    setLoading(loadingCountries || loadingBankList);
  }, [loadingBankList, loadingCountries]);

  useEffect(() => {
    setCountriesList(countries);
  }, [countries]);

  useEffect(() => {
    if (
      newBankList &&
      newBankList.length === 0 &&
      bankList &&
      bankList.length === 0
    ) {
      setNewBankList([
        ...newBankList,
        { name: "", country: { countryId: null } },
      ]);
      setErrors([...errors, { country: {} }]);
    }
  }, [newBankList, bankList, errors]);

  useEffect(() => {
    const futureCanSubmit =
      errors.some((error) => !error.name || !error.countryId) ||
      errors.length === 0;
    if (futureCanSubmit !== canSubmit) {
      setCanSubmit(futureCanSubmit);
    }
  }, [errors, canSubmit]);

  const addNewBank = () => {
    setNewBankList([
      ...newBankList,
      { name: "", country: { countryId: null } },
    ]);
    setErrors([...errors, { country: {} }]);
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      let tempErrors = errors;
      const setTempErrors = (errs) => {
        tempErrors = errs;
      };
      validateBankCountries({
        setErrors: setTempErrors,
        banks: newBankList,
        errors: tempErrors,
      });
      validateBankNames({
        setErrors: setTempErrors,
        errors: tempErrors,
        banks: newBankList,
      });

      setErrors(tempErrors);
      if (tempErrors.some((error) => error.name || error.countryId)) {
        setCanSubmit(false);
        return;
      }

      await submitBanksForCustomer(
        [...bankList, ...newBankList],
        selectedCustomerGroup
      );
      setEditingBankList(false);
      fetchBankListForCustomerGroup({
        setLoading: setLoadingBankList,
        customerGroupId: selectedCustomerGroup,
        setBankList,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleBankNameUpdate = (index, value) => {
    const bank = newBankList[index];
    if (bank) {
      setNewBankList(
        update(index, mergeDeepRight(bank, { name: value }), newBankList)
      );
    }
  };

  const handleBankCountryUpdate = (index, value) => {
    const bank = newBankList[index];
    if (bank) {
      const updatedBankList = update(
        index,
        mergeDeepRight(bank, { countryId: value }),
        newBankList
      );
      setNewBankList(updatedBankList);

      validateBankCountries({
        setErrors,
        errors,
        banks: updatedBankList,
      });
    }
  };

  const handleBankNameBlur = (index) => {
    validateBankName({ setErrors, errors, bank: newBankList[index], index });
  };

  return !hasOrderManagementPermissionForCustomerGroup ? null : (
    <React.Fragment>
      <StandardSubheading>
        <div className="flex justify-space-between">
          <span className="sub-heading-text">
            Bank List for Customer Group: {customerGroup && customerGroup.name}
          </span>
          {!editing && (
            <div className="flex align-bottom">
              <Button
                primary
                size={"small"}
                onClick={() => setEditingBankList(true)}
                className="bank-list-edit-button"
              >
                Edit
              </Button>
            </div>
          )}
          {editing && (
            <div>
              <Button onClick={() => setEditingBankList(false)} basic primary>
                Cancel
              </Button>
              <Button
                className="margin-left-1"
                onClick={handleSubmit}
                loading={submitting}
                primary
                disabled={!canSubmit || submitting}
              >
                Submit
              </Button>
            </div>
          )}
        </div>
      </StandardSubheading>
      <Divider />

      <Form loading={loading}>
        <div className="flex justify-space-between">
          <p className="margin-bottom-1">
            Please confirm that you have declared the full legal name and host
            country of any bank that makes payments, <br /> for or on behalf of
            any entity within your Compliance Group, to De Beers Global
            Sightholder Sales
          </p>
          {editing && (
            <IconButton
              className="margin-bottom-1"
              onClick={addNewBank}
              Icon={Unicons.UilPlusCircle}
              primary
              basic
            >
              Add More
            </IconButton>
          )}
        </div>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Full Legal Name Of Bank</Table.HeaderCell>
              <Table.HeaderCell>Country</Table.HeaderCell>
              {editing && <Table.HeaderCell>Actions</Table.HeaderCell>}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!editing && bankList.length === 0 && (
              <Table.Row>
                <Table.Cell colSpan={2}>
                  No bank lists created for current customer group.
                </Table.Cell>
              </Table.Row>
            )}
            {bankList.map((bank, index) => (
              <Table.Row key={index}>
                <Table.Cell>{bank.name}</Table.Cell>
                <Table.Cell>{bank.country.name}</Table.Cell>
                {editing && (
                  <Table.Cell>
                    {createRemoveAction({
                      list: bankList,
                      setter: setBankList,
                      index,
                      errors,
                      setErrors,
                    })}
                  </Table.Cell>
                )}
              </Table.Row>
            ))}
            {editing &&
              newBankList.map((bank, index) => (
                <Table.Row key={index + bankList.length}>
                  <Table.Cell>
                    <Form.Group>
                      <Form.Input
                        readOnly={!editing}
                        className="width-100"
                        placeholder="Full Legal Name Of Bank"
                        value={bank.name}
                        onChange={(_, data) =>
                          handleBankNameUpdate(index, data.value)
                        }
                        onBlur={() => handleBankNameBlur(index)}
                        error={!!errors[index]?.name}
                      />
                      {errors[index]?.name && (
                        <Popup
                          content={errors[index]?.name}
                          Icon={Unicons.UilExclamationCircle}
                          iconClassNames="error-info-icon"
                        />
                      )}
                    </Form.Group>
                  </Table.Cell>
                  <Table.Cell>
                    <Form.Group>
                      <Form.Dropdown
                        className="width-100"
                        placeholder="Select country"
                        onChange={(_, { value }) =>
                          handleBankCountryUpdate(index, value)
                        }
                        search
                        selection
                        value={bank?.countryId}
                        options={countriesList}
                        loading={loadingCountries}
                        error={!!errors[index]?.countryId}
                      />
                      {errors[index]?.countryId && (
                        <Popup
                          content={errors[index]?.countryId}
                          Icon={Unicons.UilExclamationCircle}
                          iconClassNames="error-info-icon"
                        />
                      )}
                    </Form.Group>
                  </Table.Cell>
                  {editing && (
                    <Table.Cell>
                      {createRemoveAction({
                        list: newBankList,
                        setter: setNewBankList,
                        index,
                        errors,
                        setErrors,
                      })}
                    </Table.Cell>
                  )}
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Form>
    </React.Fragment>
  );
};
