import { Checkbox, Divider, Form, Segment } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import {
  getCurrentEmailPreference,
  resubscribeEmail,
  unsubscribeEmail
} from "../../lib/apiEmail";

import Helmet from "react-helmet";
import { LoaderComponent } from "../../common/LoaderComponent";
import { StandardHeading } from "../../common/StandardHeading/StandardHeading";
import { StandardSubheading } from "../../common/StandardSubheading/StandardSubheading";
import { getUserProfile } from "../../lib/authentication";

export const Profile = () => {
  const [userProfile, setUserProfile] = useState();
  const [emailPreference, setEmailPreference] = useState(false);
  const [loadingEmailPreference, setLoadingEmailPreference] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUserProfile({ setUserInformation: setUserProfile, setLoading });
  }, []);

  useEffect(() => {
    if (userProfile) {
      getCurrentEmailPreference({
        setLoading: setLoadingEmailPreference,
        setPreference: setEmailPreference,
      });
    }
  }, [userProfile]);

  const onEmailPreferenceChange = (_, { checked }) => {
    setEmailPreference(checked);
    if (!emailPreference) {
      resubscribeEmail({
        setLoading: setLoadingEmailPreference,
      });
    } else {
      unsubscribeEmail({
        setLoading: setLoadingEmailPreference,
      });
    }
  };

  return (
    <Segment>
      <Helmet>
        <title>De Beers Sightholder Portal - User Profile</title>
      </Helmet>
      <LoaderComponent loading={loading}>
        {userProfile && (
          <React.Fragment>
            <Form>
              <StandardHeading>
                {userProfile.firstName} {userProfile.lastName}&apos;s profile
              </StandardHeading>
              <Divider />
              <Form.Group widths="equal">
                <Form.Field>
                  <label htmlFor="first-name">First Name</label>
                  <input
                    readOnly
                    value={userProfile.firstName}
                    name="first-name"
                  />
                </Form.Field>
                <Form.Field>
                  <label htmlFor="last-name">Surname</label>
                  <input
                    readOnly
                    value={userProfile.lastName}
                    name="last-name"
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label htmlFor="email-address">Email Address</label>
                  <input
                    readOnly
                    value={userProfile.emailAddress}
                    name="email-address"
                  />
                </Form.Field>
              </Form.Group>
              <StandardSubheading>Email Preferences</StandardSubheading>
              <Divider />
              <p>
                Receive email communication from De Beers Sightholder Portal?
              </p>
              <Form.Group>
                <Form.Field className="toggle-field">
                  <Checkbox
                    disabled={loadingEmailPreference}
                    loading={loadingEmailPreference}
                    checked={emailPreference}
                    name="email-preference"
                    toggle
                    onChange={onEmailPreferenceChange}
                  />
                  <label htmlFor="email-preference">Yes</label>
                </Form.Field>
              </Form.Group>
            </Form>
          </React.Fragment>
        )}
      </LoaderComponent>
    </Segment>
  );
};
