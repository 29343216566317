import { createAxios } from "./axiosWrapper";
import { notifyError } from "./notifications";

const customerIntegrationUrl = `${process.env.REACT_APP_CUSTOMER_INTEGRATION_URL}/api`;

const customerPortalDashboardController = () =>
  createAxios(`${customerIntegrationUrl}/CustomerPortalDashboard`);

const communicationUserController = () =>
  createAxios(`${customerIntegrationUrl}/CommunicationUser`);

export const getCommunications = async ({
  pageSize,
  pageNumber,
  titleFilter,
  typeIdFilter,
  salesPeriodIdFilter,
  releasedDateFromFilter,
  releasedDateToFilter,
  sortColumn,
  sortDirection,
}) => {
  try {
    let requestUrl = `get-communications?limit=${pageSize}&skip=${
      pageNumber - 1
    }&descending=${sortDirection === "descending"}&orderBy=${sortColumn}`;

    if (titleFilter) {
      requestUrl += `&title=${titleFilter}`;
    }

    if (releasedDateFromFilter) {
      requestUrl += `&releasedDateFrom=${releasedDateFromFilter}`;
    }

    if (releasedDateToFilter) {
      requestUrl += `&releasedDateTo=${releasedDateToFilter}`;
    }

    if (salesPeriodIdFilter) {
      requestUrl += `&salesPeriodId=${salesPeriodIdFilter}`;
    }

    if (typeIdFilter) {
      requestUrl += `&typeId=${typeIdFilter}`;
    }

    const communicationResults = await customerPortalDashboardController().get(
      requestUrl
    );

    const communications = communicationResults.data;

    return communications;
  } catch (error) {
    notifyError("Failed to load communications.", error);
  }
};

export const getCommunicationUsers = async () => {
  try {
    const requestUrl = `get-communication-users`;
    const communicationUserResults = await communicationUserController().get(
      requestUrl
    );

    const communicationUsers = communicationUserResults.data;

    return communicationUsers;
  } catch (error) {
    notifyError("Failed to get communication user.", error);
  }
};

export const patchCommunicationMarkAsRead = async ({ communicationId }) => {
  try {
    const requestUrl = `${communicationId}`;

    const communication = await communicationUserController().patch(requestUrl);

    return communication.data;
  } catch (err) {
    notifyError("Failed to mark communication as read", err);
  }
};

export const patchCommunicationsMarkAllAsRead = async () => {
  try {
    const requestUrl = `mark-all-as-read`;

    const communications = await communicationUserController().patch(
      requestUrl
    );

    return communications.data;
  } catch (err) {
    notifyError("Failed to mark all communication as read", err);
  }
};

export const getAllCommunicationTypes = async () => {
  try {
    const requestUrl = `get-communication-types`;
    const communicationTypeResults =
      await customerPortalDashboardController().get(requestUrl);

    const communicationTypes = communicationTypeResults.data;

    return communicationTypes;
  } catch (error) {
    notifyError("Failed to get communication types.", error);
  }
};
