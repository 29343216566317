import React from "react";
import { Button } from "semantic-ui-react";
import "./IconButton.scss";

export const IconButton = ({ children, Icon, loading, disabled, ...props }) => (
  <Button {...props} loading={loading} disabled={disabled||loading}>
    <div className="flex">
      <div>{children}</div>
      <Icon className="iconMargin" />
    </div>
  </Button>
);
