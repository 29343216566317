import { createAxios } from "./axiosWrapper";
import { notifyError } from "./notifications";

const customerApiUrl = `${process.env.REACT_APP_CUSTOMER_INTEGRATION_URL}/api`;
const axios = () => createAxios(customerApiUrl);

export const getCustomer = async (customerId) => {
  try {
    const requestUrl = `/customers/${customerId}`;

    const customer = await axios().get(requestUrl);
    return customer.data;
  } catch (err) {
    notifyError("Failed to fetch the customer", err);
  }
};
