import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "semantic-ui-css/semantic.min.css";
import {OneTrust} from "./modules/login/OneTrust"

const instrumentationKey = process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY;

if (instrumentationKey) {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey,
    },
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

ReactDOM.render(
  <div>
    <OneTrust/>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </div>,
  document.getElementById("root")
);

serviceWorker.unregister();
