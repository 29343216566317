import { update, mergeDeepRight, applySpec, prop, always } from "ramda";
import {
  getCountries,
  getCustomerGroup,
  getCustomerGroupOrderManagementPermission,
} from "../../lib/apiOrderManagement";
import { getBanksForCustomer, postBanks } from "../../lib/apiBankLists";

export const validateBankName = ({ setErrors, errors, bank, index }) => {
  if (bank && bank.name.length === 0) {
    setErrors(
      update(
        index,
        mergeDeepRight(errors[index], { name: "The bank name is required" }),
        errors
      )
    );
  } else {
    if (errors[index].name) {
      setErrors(
        update(index, mergeDeepRight(errors[index], { name: null }), errors)
      );
    }
  }
};

export const validateBankNames = ({ setErrors, errors, banks }) =>
  setErrors(
    banks.map((bank, index) => {
      const error = errors[index];
      if (bank && bank.name.length === 0) {
        return mergeDeepRight(error, { name: "The bank name is required" });
      } else {
        if (error.name) {
          return mergeDeepRight(error, { name: null });
        }
      }

      return error;
    })
  );

export const validateBankCountries = ({ setErrors, errors, banks }) =>
  setErrors(
    banks.map((bank, index) => {
      const error = errors[index];

      if (bank.countryId === null || bank.countryId === undefined) {
        return mergeDeepRight(error, {
          countryId: "A country is required",
        });
      } else {
        if (error.countryId) {
          return mergeDeepRight(error, { countryId: null });
        }
      }

      return error;
    })
  );

export const fetchCustomerGroup = async ({
  setCustomerGroup,
  customerGroupId,
}) => {
  if (customerGroupId !== null) {
    const customerGroup = await getCustomerGroup(customerGroupId);

    setCustomerGroup(customerGroup);
  }
};

export const fetchBankListForCustomerGroup = async ({
  setLoading,
  setBankList,
  customerGroupId,
}) => {
  if (customerGroupId !== null) {
    setLoading(true);
    try {
      const bankList = await getBanksForCustomer(customerGroupId);

      setBankList(bankList);
    } finally {
      setLoading(false);
    }
  }
};

export const fetchCountries = async ({ setLoading, setCountries }) => {
  setLoading(true);
  try {
    const countries = await getCountries();

    setCountries(
      countries.data.map(
        applySpec({
          key: prop("code"),
          value: prop("countryId"),
          text: prop("name"),
        })
      )
    );
  } finally {
    setLoading(false);
  }
};

export const submitBanksForCustomer = async (banks, customerGroupId) => {
  await postBanks(
    banks.map(
      applySpec({
        customerGroupId: always(customerGroupId),
        bankId: prop("bankId"),
        countryId: prop("countryId"),
        name: prop("name"),
      })
    )
  );
};

export const getCustomerGroupPermission = async (
  customerGroupId,
  setHasOrderManagementPermissionForCustomerGroup
) => {
  setHasOrderManagementPermissionForCustomerGroup(false);
  const hasPermission = await getCustomerGroupOrderManagementPermission(customerGroupId);
  setHasOrderManagementPermissionForCustomerGroup(hasPermission);
};
