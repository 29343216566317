const autoSizeAllColumns = (worksheet, minWidth = 10, padding = 2) => {
  worksheet.columns.forEach((column, i) => {
    var maxLength = 0;
    column["eachCell"]({ includeEmpty: true }, (cell) => {
      var columnLength = cell.value ? cell.value.toString().length : minWidth;
      if (columnLength > maxLength) {
        maxLength = columnLength;
      }
    });
    column.width = (maxLength < minWidth ? minWidth : maxLength) + padding;
  });
};
export default autoSizeAllColumns;
