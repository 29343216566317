import { Grid, Input } from "semantic-ui-react";

import IndexPageTable from "../../../../common/IndexPageTable/IndexPageTable";
import React from "react";
import { UilDownloadAlt } from "@iconscout/react-unicons";
import { downloadManufacturingDataSubmission } from "./ManufacturingData.functions";

const ManufacturingDataPreviousSightTable = ({
  hasManufacturingPermission,
  tableData,
  loading,
  pageSize,
  setPageSize,
  pageNumber,
  setPageNumber,
  totalResults,
  setSearchString,
  searchString,
}) => {
  const onPageNumberChange = (event, { activePage }) => {
    setPageNumber(activePage);
  };

  const onPageSizeChange = (event, { value }) => {
    setPageNumber(1);
    setPageSize(value);
  };

  const actionIdField = "manufacturingSubmissionId";

  const actionPaths = {
    Download: {
      onClick: (data) => {
        downloadManufacturingDataSubmission({
          submissionId: data.manufacturingSubmissionId,
          fileName: data.documentName,
        });
      },
      icon: <UilDownloadAlt size={25} />,
    },
  };

  const fieldNameMappings = {
    SALESPERIODNAME: { name: "Sales Period", order: 1 },
    CUSTOMERGROUP: { name: "Customer Group", order: 2 },
    CUSTOMERNAME: { name: "Customer Name", order: 3 },
    DOCUMENTNAME: { name: "Document Name", order: 4 },
    DATECREATED: { name: "Date Uploaded", order: 5 },
    UPLOADEDBY: { name: "Uploaded By", order: 6 },
  };

  const fieldsToIgnore = ["MANUFACTURINGSUBMISSIONID"];

  const dateFields = ["DATECREATED"];

  const onSearchChange = (_, { value }) => {
    setPageNumber(1);
    setSearchString(value);
  };

  const headerContent = (
    <React.Fragment>
      <Grid.Row className="table-header ms-margin-top-5">
        <Grid.Column width={6}>
          <p>Previous Sight Submissions</p>
        </Grid.Column>
        <Grid.Column width={10}>
          <Input
            className="ms-float-search-right"
            icon="search"
            iconPosition="left"
            placeholder="Search"
            disabled={!hasManufacturingPermission}
            value={searchString}
            onChange={onSearchChange}
          />
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  );

  return (
    <IndexPageTable
      tableData={tableData}
      fieldsToIgnore={fieldsToIgnore}
      fieldNameMappings={fieldNameMappings}
      actionIdField={actionIdField}
      actionPaths={actionPaths}
      loading={loading}
      showFilters={true}
      dateFields={dateFields}
      headerContent={headerContent}
      onSortColumnChange={() => {}}
      pageSize={pageSize}
      pageNumber={pageNumber}
      totalResults={totalResults}
      pageNumberUpdate={onPageNumberChange}
      pageSizeUpdate={onPageSizeChange}
      searchFieldName="Search"
      searchFieldPlaceholder="Search"
      options={{ disablePagination: !hasManufacturingPermission }}
    />
  );
};

export default ManufacturingDataPreviousSightTable;
