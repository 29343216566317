import { Button, Form, Grid, Message, Modal } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { expectedRow1Values, mappingValues } from "./ManufacturingData.members";
import {
  uploadManufacturingDataSubmission,
  uploadManufacturingDataSubmissionOverwrite,
} from "./ManufacturingData.functions";

import { ManufacturingDataUploadFileContainer } from "./ManufacturingDataUploadFileContainer";

const ManufacturingUploadModal = ({
  showUploadModal,
  setShowUploadModal,
  customerGroup,
  selectedSalesPeriodId,
  onModalSubmit,
  uploadingSubmissionModal,
  setUploadingSubmissionModal
}) => {
  const [fileError, setFileError] = useState(false);
  const [lineValidationErrors, setLineValidationErrors] = useState([]);
  const [canOverwrite, setCanOverwrite] = useState(false);
  const [uploadedFileData, setUploadedFileData] = useState([]);
  const [uploadedFileName, setUploadedFileName] = useState();
  const [fileLoading, setFileLoading] = useState(false);
  const [customersDropdownData, setCustomersDropdownData] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(-1);
  const [loadingSubmissionUpload, setLoadingSubmissionUpload] = useState(false);
  const [fileData, setFileData] = useState();
  
  const handleSubmitOverwrite = async () => {
    const customerGroupId = customerGroup.customerGroupId;

    if (!uploadingSubmissionModal)
    {
      await uploadManufacturingDataSubmissionOverwrite({
        customerId: selectedCustomer,
        salesPeriodId: selectedSalesPeriodId,
        customerGroupId: customerGroupId,
        documentName: uploadedFileName,
        submissionData: uploadedFileData,
        setShowUploadModal,
        setLoadingSubmissionUpload,
        onModalSubmit,
        setLineValidationErrors,
        fileData,
        setUploadingSubmissionModal
      });
    }
  };

  const handleSubmit = async () => {
    const customerGroupId = customerGroup.customerGroupId;

    if (!uploadingSubmissionModal)
    {
      await uploadManufacturingDataSubmission({
        customerId: selectedCustomer,
        salesPeriodId: selectedSalesPeriodId,
        customerGroupId: customerGroupId,
        documentName: uploadedFileName,
        submissionData: uploadedFileData,
        setShowUploadModal,
        setLoadingSubmissionUpload,
        setCanOverwrite,
        onModalSubmit,
        setLineValidationErrors,
        fileData,
        setUploadingSubmissionModal
      });
    }
  };

  const onFileSelected = async ({ excelFileValues, fileName, file }) => {
    setCanOverwrite(false);
    setUploadedFileData(excelFileValues);
    setUploadedFileName(fileName);
    setLineValidationErrors([]);
    setFileData(file);
  };

  const selectedCustomerChange = (event, { value }) => {
    setCanOverwrite(false);
    setSelectedCustomer(value);
  };

  useEffect(() => {
    const customers = customerGroup.customers.map((customer) => ({
      key: customer.customerId,
      text: customer.customerName,
      value: customer.customerId,
    }));

    const customersWithAllOption = [
      {
        key: -1,
        text: "All Customers",
        value: -1,
      },
      ...customers,
    ];

    setCustomersDropdownData(customersWithAllOption);
  }, [customerGroup]);

  const isSubmissionButtonDisabled = () =>
    !selectedCustomer ||
    !fileError ||
    !uploadedFileName ||
    fileLoading ||
    loadingSubmissionUpload ||
    lineValidationErrors.length > 0;

  return (
    <Modal
      open={showUploadModal}
      closeOnDimmerClick={false}
      size="small"
      onClose={() => setShowUploadModal(false)}
    >
      <Modal.Header>Upload Submission</Modal.Header>
      <Modal.Content>
        <Form>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <Form.Dropdown
                  id="customer"
                  fluid
                  label="Customer"
                  placeholder="Select Customer"
                  options={customersDropdownData}
                  value={selectedCustomer}
                  selection
                  onChange={selectedCustomerChange}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <ManufacturingDataUploadFileContainer
                  setFileError={setFileError}
                  onFileSelected={onFileSelected}
                  expectedRow1Values={expectedRow1Values}
                  mappingValues={mappingValues}
                  setFileLoading={setFileLoading}
                  fileLoading={fileLoading}
                  lineValidationErrors={lineValidationErrors}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
        {canOverwrite && (
          <Message className="ms-warning-message">
            <Message.Header>
              Manufacturing insights data submission already exists
            </Message.Header>
            <Message.Content>
              You can replace the existing submission by clicking "Overwrite"
              below.
            </Message.Content>
          </Message>
        )}
      </Modal.Content>
      <Modal.Actions className="ms-modal-actions">
        {canOverwrite ? (
          <Button
            content="Overwrite"
            type="submit"
            disabled={isSubmissionButtonDisabled()}
            floated="right"
            onClick={handleSubmitOverwrite}
            primary
            loading={loadingSubmissionUpload}
            className="ms-btn-overwrite"
          />
        ) : (
          <Button
            content="Submit"
            type="submit"
            disabled={isSubmissionButtonDisabled()}
            floated="right"
            onClick={async () => await handleSubmit()}
            primary
            loading={loadingSubmissionUpload}
          />
        )}
        <Button
          secondary
          content="Cancel"
          disabled={loadingSubmissionUpload}
          onClick={() => setShowUploadModal(false)}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ManufacturingUploadModal;
