import { notifyError } from "../lib/notifications";
import { createAxios } from "./axiosWrapper";

const salesPeriodServiceUrl = `${process.env.REACT_APP_CUSTOMER_INTEGRATION_URL}/api/salesPeriod`;

const axios = () => createAxios(salesPeriodServiceUrl);

export const getAllSalesPeriods = async () => {
  try {
    const request = `get-sales-period-lookup`;
    const salesPeriodResults = await axios().get(request);
    return salesPeriodResults.data || [];
  } catch (err) {
    notifyError("Failed to fetch all sights.", err);
    return [];
  }
};

export const getAllSalesPeriodsByITOYear = async (itoYear) => {
  try {
    const request = `get-sales-period-lookup-by-ito-year?itoYear=${itoYear}`;
    const salesPeriodResults = await axios().get(request);
    return salesPeriodResults.data || [];
  } catch (err) {
    notifyError("Failed to fetch all sights.", err);
    return [];
  }
};

export const getCurrentSalesPeriod = async () => {
  try {
    const request = `get-current-sales-period`;
    const salesPeriodResults = await axios().get(request);
    return salesPeriodResults.data;
  } catch (err) {
    notifyError("Failed to fetch current sights.", err);
  }
};

export const getCurrentSalesPeriodForApplications = async () => {
  try {
    const request = `get-current-sales-period-for-applications`;
    const salesPeriodResults = await axios().get(request);
    return salesPeriodResults.data;
  } catch (err) {
    notifyError("Failed to fetch current sights.", err);
  }
};

export const getAllSalesPeriodSnapShots = async (customerId) => {
  try {
    const request = `get-sales-periods-for-sales-period-value-snapshots/${customerId}`;
    const salesPeriodResults = await axios().get(request);
    return salesPeriodResults.data || [];
  } catch (err) {
    notifyError("Failed to fetch the sight snapshots for the selected customer.", err);
    return [];
  }
};
