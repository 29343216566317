import "./MobileSearch.scss";

import { Input } from "semantic-ui-react";
import React from "react";

export const MobileSearch = ({searchData}) => {

    return (
        <Input fluid icon='search' className="mobile-search" placeholder='Search' onChange={(_, { value }) => searchData(value)} />
    );
}

export default MobileSearch;