import React from "react";
export const CommunicationsContext = React.createContext({
  communications: [],
  loadingCommunications: false,
  typeIdFilter: null,
  communicationTypes: [],
  salesPeriods: [],
  releasedDateFromFilter: "",
  releasedDateToFilter: "",
  filterLoading: false,
  updateFilter: (filterType, filterValue) => {},
  markCommunicationAsReadClicked: (communication) => () => {},
  markAllCommunicationAsReadClicked: () => {},
});
