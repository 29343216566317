import React, { useEffect, useState } from "react";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { isNil } from "ramda";
import { getEmbedToken } from "../../lib/apiEmbeddedReports";
import { Message } from "semantic-ui-react";

export const ReportFrame = ({ embeddedReportId, onLoad }) => {
  const [embedToken, setEmbedToken] = useState(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsError(false);
        const token = await getEmbedToken(embeddedReportId);
        setEmbedToken(token);
      } catch (err) {
        setIsError(true);
      }
    })();
  }, [embeddedReportId]);

  if (isError) {
    onLoad();
    return (
      <Message
        icon="warning circle"
        header={`Error`}
        content={`There was a problem fetching the report. Please contact support.`}
      />
    );
  }

  if (isNil(embedToken)) {
    return null;
  }

  return (
    <PowerBIEmbed
      embedConfig={{
        type: "report",
        id: embedToken.reportId,
        accessToken: embedToken.embedToken,
        embedUrl: embedToken.reportUrl,
        tokenType: models.TokenType.Embed,
      }}
      cssClassName="report"
      eventHandlers={new Map([["loaded", () => onLoad()]])}
    />
  );
};
