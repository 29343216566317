import React from "react";

export const Legal = () => {
  const pageContent = (
    <React.Fragment>
      <div>
        <p>
          <strong>INTRODUCTION:</strong>
        </p>
        <ul>
          <li>
            Sightholder Manufacturing Insights Reports (the "<b>Reports</b>") are published 
            by De Beers Global Sightholder Sales (Proprietary) Ltd ("<b>GSS</b>")[1] for the 
            purpose of supporting a Sightholder’s rough diamond manufacturing business. 
          </li>
          <li>
            The Reports contain technical data from sources including GSS Sight box assortment 
            data ("<b>GSS Data</b>"), and Sightholder manufacturing data from the processing of GSS 
            Sight boxes supplied to a Sightholder by GSS ("<b>Sightholder Data</b>"). GSS Data and 
            Sightholder Data has been analysed (as applicable) by GSS to provide a Sightholder 
            with a collective analysis focusing on key metrics of Sightholder manufacturing 
            activity in respect of the processing of GSS Sight boxes (hereafter together 
            referred to as the "<b>Report Data</b>" within the Legal Disclaimer).  
          </li>
          <li>
            In accessing and/or reviewing the Reports, a Sightholder agrees to be bound by the 
            provisions set out in this Legal Disclaimer, and further acknowledges that GSS 
            reserves the right to withdraw access to the Reports at any time in the event of 
            suspected or proven non-compliance of these terms. 
          </li>
        </ul>
        <p>
          <strong>THE DATA:</strong>
        </p>
        <ul>
          <li>
            Sightholder Data has been submitted by GSS Sightholders on a voluntary, non-contractual basis. 
            GSS has undertaken all reasonable measures to review Sightholder Data submissions to ensure 
            accuracy, however, this does not extend to verification of Sightholder Data and/or that 
            Sightholder submissions are complete or consistently submitted. A Report should not be 
            interpreted to mean that GSS, GSS Sight boxes, and/or the diamond industry (as the case may be) 
            will necessarily perform in accordance with the outcomes published in a Report. 
          </li>
          <li>
            The Report Data represents GSS’ internal analysis of an individual Sight box, including as 
            applicable, a comparison against historical GSS Sight boxes. In accordance with paragraph 
            5.5 of the 2021-2023 Supply Agreement (including any successor or updated version of the 
            2021-2023 Supply Agreement as may be entered into between GSS and a Sightholder), and for 
            the avoidance of any doubt, a Sightholder acknowledges that, (a) by their nature, the size, 
            model, colour, and quality of rough diamonds will vary from time to time; and (b) that GSS 
            undertakes to use its reasonable endeavours only to ensure that, insofar as practicable, 
            there is consistency as to the size, type, colour and quality of rough diamonds contained 
            in each individual Sight box that it supplies to a Sightholder, including by reference to 
            the respective processes adopted by GSS to compose an individual Sight box.
          </li>
          <li>
            The Report Data is supplied by GSS for information purposes only to enable a Sightholder to 
            obtain an enhanced understanding of Sightholder manufacturing activity. GSS does not offer 
            any representation or warranty to the accuracy or completeness of the Report and no reliance 
            should be placed on the Report Data disclosed to a Sightholder for any purpose without further 
            evaluation and/or analysis, in all instances as relevant to a Sightholders specific manufacturing 
            requirement. Further, GSS excludes all liability to the extent permitted by law, for any reliance 
            and/or action taken by a Sightholder in respect of the Report Data, whether for breach of contract, 
            warranty, and/or tort (including negligence) for any indirect or consequential loss or damage, 
            including but not limited to loss of profit and/or goodwill.  
          </li>
        </ul>
        <p>
          <strong>CONFIDENTIALITY:</strong>
        </p>
        <ul>
          <li>
            The Report Data is GSS Confidential Information and is therefore subject to the confidentiality 
            provisions contained within your 2021-2023 Supply Agreement, specifically paragraphs 9 and 19 
            (including any successor or updated version of the 2021-2023 Supply Agreement as may be entered 
            into between GSS and a Sightholder).
          </li>
          <li>
            Ahead of evaluating and/or analysing the Report Data, GSS requests a recipient Sightholder to 
            review the confidentiality provisions contained in the 2021-2023 Supply Agreement which places 
            obligations on a Sightholder in respect of (i) accessing the Report Data; (ii) how the Report 
            Data is hosted; and (iii) to whom the Report Data may be disclosed. As applicable, a Sightholder 
            may be required under the terms of the 2021-2023 Supply Agreement to implement separate confidentiality 
            arrangements to protect the Confidential Information supplied by GSS.
          </li>
        </ul>
        <cite>
          [1]        References in this Legal Disclaimer to “GSS” shall be taken to include a reference to rough 
          diamond supply from De Beers Global Sightholder Sales (Proprietary) Limited, De Beers Consolidated Mines 
          (Proprietary) Limited, and/or Namibia Diamond Trading Company (Proprietary) Limited (as and where applicable).
        </cite>
      </div>
    </React.Fragment>
  );

  return pageContent;
};
