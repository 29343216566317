import "./StickyTotalFooter.scss";

import { Grid, Segment } from "semantic-ui-react";
import React, { useState } from "react";

import { BackLink } from "../BackLink/BackLink";

export const StickyTotalFooter = ({ content, backUrl }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Segment raised className="sticky-total-footer">
      {!expanded && (
        <Grid columns="equal" className="minimized-footer">
          <Grid.Row>
            <Grid.Column>
              {content.highlight.name && content.highlight.content && (
                <h5 className="footer-main-text">
                  {content.highlight.name}: {content.highlight.content}
                </h5>
              )}
              {content.highlight.showBackButton && (
                <BackLink backUrl={backUrl} />
              )}
            </Grid.Column>
            {content.totals?.length > 0 && (
              <Grid.Column textAlign="right">
                <span
                  className="show-hide-link"
                  tabIndex={0}
                  role="link"
                  onClick={() => setExpanded(!expanded)}
                  onKeyDown={() => setExpanded(!expanded)}
                >
                  View more
                </span>
              </Grid.Column>
            )}
          </Grid.Row>
          {(!content.totals ||
            content.totals?.length <= 0 ||
            content.showButtonsWhileCollapsed) && (
            <Grid.Row>
              <Grid.Column>{content.buttonContent}</Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      )}
      {expanded && (
        <Grid className="maximized-footer" columns="equal">
          <Grid.Row>
            <Grid.Column>
              {!content.highlight.showBackButton && (
                <h5 className="footer-total-title">Totals:</h5>
              )}
              {content.highlight.showBackButton && (
                <BackLink backUrl={backUrl} />
              )}
            </Grid.Column>
            <Grid.Column textAlign="right">
              <span
                className="show-hide-link"
                tabIndex={0}
                role="link"
                onClick={() => setExpanded(!expanded)}
                onKeyDown={() => setExpanded(!expanded)}
              >
                Hide
              </span>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Grid columns={content.totals.length > 1 ? 2 : 1}>
                {content.totals.map((item, index) => {
                  return (
                    <Grid.Column key={index} className="total-content">
                      {item.name}:{" "}
                      <span className="right-aligned">{item.content}</span>
                    </Grid.Column>
                  );
                })}
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>{content.buttonContent}</Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </Segment>
  );
};

export default StickyTotalFooter;
