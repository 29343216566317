import { Helmet } from "react-helmet";
import { Grid, Message } from "semantic-ui-react";
import React, { useState, useEffect } from "react";
import "./Reports.scss";
import { LoaderComponent } from "../../common/LoaderComponent";
import { ReportTabs } from "./ReportTabs";
import { getPowerBiReportList, fetchPowerBiReportPermissions } from "../../lib/apiEmbeddedReports";
import MobileNotAvailable from "../../common-mobile/MobileNotAvailable/MobileNotAvailable";

export const ReportsContainer = () => {
  const [reportList, setReportList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasPowerBiReportsPermission, setHasPowerBiReportsPermission] = useState(false);

  useEffect(() => {
    (async () => {
      const hasPermission = await fetchPowerBiReportPermissions();
      setHasPowerBiReportsPermission(hasPermission);

      if (hasPermission) {
        setReportList([]);
        const reports = await getPowerBiReportList();

        setReportList(reports);
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <Helmet>
        <title>De Beers Sightholder Portal - Reports</title>
      </Helmet>
      {!hasPowerBiReportsPermission ? (
        <Message
          icon="warning circle"
          header={`Reports`}
          content={`You do not have access to view reports.`}
        />
      ) : (
        <>
          <Grid columns={16} className="desktop">
            <Grid.Row className="">
              <Grid.Column width={16}>
                <LoaderComponent
                  loading={isLoading}
                  message="Fetching your reports"
                >
                  <ReportTabs reportList={reportList} />
                </LoaderComponent>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <MobileNotAvailable />
        </>
      )}
    </>
  );
};

export default ReportsContainer;
