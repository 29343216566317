import React, { Fragment } from "react";
import { Header, Modal, Grid, Button } from "semantic-ui-react";
import "./ConfirmationModal.scss";

export const confirmationChoices = {
  cancel: "cancel",
  confirm: "confirm",
};

export const ConfirmationModal = ({
  open,
  closeModal,
  heading,
  subHeading,
  content,
}) => {
  return (
    <Fragment>
      <Modal
        open={open}
        closeIcon
        onClose={closeModal(confirmationChoices.cancel)}
        size={"large"}
      >
        <Modal.Content>
          <Modal.Description>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Header>{heading}</Header>
                  <span className="sub-header">{subHeading}</span>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>{content}</Grid.Column>
              </Grid.Row>

              <Grid.Row className="desktop">
                <Grid.Column>
                  <Button
                    className="btn-submit"
                    floated="right"
                    onClick={closeModal(confirmationChoices.confirm)}
                  >
                    Confirm
                  </Button>
                  <Button
                    className="btn-cancel"
                    floated="right"
                    onClick={closeModal(confirmationChoices.cancel)}
                  >
                    Cancel
                  </Button>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row className="spacer mobile-view">
                <Grid.Column className="modal-action-buttons">
                  <Button
                    className="modal-action-button"
                    secondary
                    onClick={closeModal(confirmationChoices.cancel)}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="modal-action-button"
                    primary
                    onClick={closeModal(confirmationChoices.confirm)}
                  >
                    Confirm
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
};
export default ConfirmationModal;
