import { notifyError } from "./notifications";

const { createAxios } = require("./axiosWrapper");

const notificationsUrl = `${process.env.REACT_APP_CUSTOMER_INTEGRATION_URL}/api/Notifications`;

const axios = () => createAxios(notificationsUrl);

export const getUnreadNotificationCountForUser = async () => {
  try {
    const requestUrl = `get-unread-notifications-count-for-user`;

    const unreadCount = await axios().get(requestUrl);

    return unreadCount.data;
  } catch (err) {
    notifyError("Failed to load notification count", err);
  }
};

export const getNotificationsForUser = async (
  limit = 10,
  searchFilter,
  typeFilter,
  locationFilter,
  salesPeriodFilter
) => {
  try {
    const requestUrl = `find-for-user?limit=${limit}${
      searchFilter ? "&search=" + searchFilter : ""
    }${typeFilter ? "&typeId=" + typeFilter : ""}${
      locationFilter ? "&locationId=" + locationFilter : ""
    }${salesPeriodFilter ? "&salesPeriodId=" + salesPeriodFilter : ""}`;

    const notifications = await axios().get(requestUrl);

    return notifications.data;
  } catch (err) {
    notifyError("Failed to load notifications", err);
  }
};

export const patchNotificationMarkAsRead = async ({ notificationId }) => {
  try {
    const requestUrl = `${notificationId}`;

    const notification = await axios().patch(requestUrl);

    return notification.data;
  } catch (err) {
    notifyError("Failed to mark notification as read", err);
  }
};

export const patchNotificationMarkAllAsRead = async () => {
  try {
    const requestUrl = "mark-all-as-read";

    await axios().patch(requestUrl);
  } catch (err) {
    notifyError("Failed to mark all notifications as read", err);
  }
};

export const getNotificationTypesAsync = async () => {
  try {
    const requestUrl = `get-notification-types`;

    const notificationTypes = await axios().get(requestUrl);

    return notificationTypes.data;
  } catch (err) {
    notifyError("Failed to load notification types", err);
  }
};
