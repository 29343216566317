export const orchestrateLogoutAsync = async (
  oktaAuth,
  setLoggedOut,
  setSpsLogin
) => {
  await oktaAuth.signOut();
  setLoggedOut(true);
  setSpsLogin({
    isLoggedIn: false,
    isPending: false,
  });
};
