import React, { useState } from "react";
import {
  UilDownloadAlt,
  UilExport,
  UilMinusCircle,
} from "@iconscout/react-unicons";
import {
  deleteSubmission,
  downloadManufacturingDataSubmission,
  generateManufacturingDataTemplate,
  getCurrentSightManufacturingDataSubmissions,
} from "./ManufacturingData.functions";

import { Grid } from "semantic-ui-react";
import { IconButton } from "../../../../common/IconButton/IconButton";
import IndexPageTable from "../../../../common/IndexPageTable/IndexPageTable";
import ManufacturingUploadModal from "./ManufacturingUploadModal";

const ManufacturingDataCurrentSightTable = ({
  customerGroup,
  currentSalesPeriod,
  hasManufacturingPermission,
  isManufacturingWindowOpen,
  tableData,
  setTableData,
  setLoading,
  loading,
}) => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadingSubmissionModal, setUploadingSubmissionModal] = useState(false);
  const [loadingTemplateDownload, setLoadingTemplateDownload] = useState(false);

  const actionIdField = "manufacturingSubmissionId";

  const actionPaths = {
    Download: {
      onClick: (data) => {
        downloadManufacturingDataSubmission({
          submissionId: data.manufacturingSubmissionId,
          fileName: data.documentName,
        });
      },
      icon: <UilDownloadAlt size={25} />,
    },
    Remove: {
      onClick: (data) => {
        deleteSubmission({
          submissionId: data.manufacturingSubmissionId,
          tableData,
          setTableData,
        });
      },
      icon: <UilMinusCircle size={25} />,
      disabled: !isManufacturingWindowOpen,
    },
  };

  const fieldNameMappings = {
    SALESPERIODNAME: { name: "Sales Period", order: 1 },
    CUSTOMERGROUP: { name: "Customer Group", order: 2 },
    CUSTOMERNAME: { name: "Customer Name", order: 3 },
    DOCUMENTNAME: { name: "Document Name", order: 4 },
    DATECREATED: { name: "Date Uploaded", order: 5 },
    UPLOADEDBY: { name: "Uploaded By", order: 6 },
  };

  const fieldsToIgnore = ["MANUFACTURINGSUBMISSIONID"];

  const dateFields = ["DATECREATED"];

  const headerContent = (
    <React.Fragment>
      <Grid.Row className="table-header">
        <Grid.Column width={6}>
          <p>Data for sight {currentSalesPeriod?.name}</p>
        </Grid.Column>
        <Grid.Column width={10}>
          <IconButton
            primary
            floated="right"
            Icon={UilExport}
            onClick={() => setShowUploadModal(true)}
            disabled={!hasManufacturingPermission || !isManufacturingWindowOpen}
          >
            Upload Manufacturing Insights Data
          </IconButton>
          <IconButton
            floated="right"
            secondary
            Icon={UilDownloadAlt}
            onClick={() => {
              generateManufacturingDataTemplate({ setLoadingTemplateDownload });
            }}
            loading={loadingTemplateDownload}
            disabled={!hasManufacturingPermission}
          >
            Download Template
          </IconButton>
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  );

  const onModalSubmit = () => {
    getCurrentSightManufacturingDataSubmissions({
      setLoading,
      setTableData,
      salesPeriod: currentSalesPeriod,
      customerGroup: customerGroup,
    });
  };

  return (
    <React.Fragment>
      {showUploadModal && (
        <ManufacturingUploadModal
          customerGroup={customerGroup}
          showUploadModal={showUploadModal}
          setShowUploadModal={setShowUploadModal}
          selectedSalesPeriodId={currentSalesPeriod?.salesPeriodId}
          onModalSubmit={onModalSubmit}
          uploadingSubmissionModal={uploadingSubmissionModal}
          setUploadingSubmissionModal={setUploadingSubmissionModal}
        />
      )}
      <IndexPageTable
        tableData={tableData}
        fieldsToIgnore={fieldsToIgnore}
        fieldNameMappings={fieldNameMappings}
        actionIdField={actionIdField}
        actionPaths={actionPaths}
        headerContent={headerContent}
        loading={loading}
        showFilters={false}
        dateFields={dateFields}
        options={{ disablePagination: true }}
        onSortColumnChange={() => {}}
      />
    </React.Fragment>
  );
};

export default ManufacturingDataCurrentSightTable;
