import React from "react";

export const CustomerGroupsContext = React.createContext({
  customerGroups: [],
  setCustomerGroups: () => {},
});

export const SelectedCustomerGroupContext = React.createContext({
  selectedCustomerGroup: null,
  setSelectedCustomerGroup: () => {},
});

export const SelectedCustomerContext = React.createContext({
  selectedCustomer: null,
  setSelectedCustomer: () => {},
});
