import React, { useState, useEffect } from "react";
import { NotificationsContext } from "./NotificationsContext";
import { findIndex, propEq, update } from "ramda";
import { patchNotificationMarkAllAsRead } from "../../lib/apiNotifications";
import {
  fetchNotifications,
  fetchUnreadCount,
  markNotificationAsRead,
  getNotificationTypes,
  getSalesPeriods,
  getSalesLocationsAsync,
} from "./notifications.functions";
import { isLoggedIn } from "../../lib/authentication";

export const NotificationsContextProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [totalNotifications, setTotalNotifications] = useState(10);
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [searchFilter, setSearchFilter] = useState(null);
  const [locationFilter, setLocationFilter] = useState(null);
  const [typeFilter, setTypeFilter] = useState(null);
  const [salesPeriodFilter, setSalesPeriodFilter] = useState(null);
  const [filterLoading, setFilterLoading] = useState(false);
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [locations, setLocations] = useState([]);
  const [salesPeriods, setSalesPeriods] = useState([]);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  const startupFn = async () => {
    setFilterLoading(true);
    try {
      if (isLoggedIn()) {
        await Promise.all([
          getNotificationTypes(setNotificationTypes),
          getSalesLocationsAsync(setLocations),
          getSalesPeriods(setSalesPeriods),
        ]);
      }
    } finally {
      setFilterLoading(false);
    }
  };

  useEffect(() => {
    startupFn();
  }, []);

  useEffect(() => {
    if (isLoggedIn()) {
      fetchNotifications({
        setNotifications,
        setLoading: setLoadingNotifications,
        setTotalNotifications,
        limit: totalNotifications,
        searchFilter,
        typeFilter,
        locationFilter,
        salesPeriodFilter,
      });
    }
  }, [
    totalNotifications,
    searchFilter,
    typeFilter,
    locationFilter,
    salesPeriodFilter,
  ]);

  useEffect(() => {
    if (isLoggedIn()) {
      fetchUnreadCount({
        unreadCount: unreadMessagesCount,
        setUnreadCount: setUnreadMessagesCount,
        firstLoad: true,
      });
      const interval = setInterval(() => {
        fetchUnreadCount({
          unreadCount: unreadMessagesCount,
          setUnreadCount: setUnreadMessagesCount,
          firstLoad: false,
        });
      }, 120000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [unreadMessagesCount]);

  const viewAllClicked = () => {
    fetchNotifications({
      setNotifications,
      setLoading: setLoadingNotifications,
      setTotalNotifications,
      limit: totalNotifications,
      searchFilter,
      typeFilter,
      locationFilter,
      salesPeriodFilter,
    });
  };

  const markAllAsReadClicked = async () => {
    await patchNotificationMarkAllAsRead();
    fetchNotifications({
      setNotifications,
      setLoading: setLoadingNotifications,
      setTotalNotifications,
      limit: totalNotifications,
      searchFilter,
      typeFilter,
      locationFilter,
      salesPeriodFilter,
    });
    setUnreadMessagesCount(0);
  };

  const updateFilter = (filterType, filterValue) => {
    if (filterType === "search") {
      if (
        filterValue.length > 2 ||
        (filterValue?.length < 3 && searchFilter?.length > 0)
      ) {
        setSearchFilter(filterValue);
      }
    } else if (filterType === "location") {
      setLocationFilter(filterValue);
    } else if (filterType === "sight") {
      setSalesPeriodFilter(filterValue);
    } else if (filterType === "type") {
      setTypeFilter(filterValue);
    }
  };

  const updateNotification = (notification) => {
    const notificationIndex = findIndex(
      propEq("notificationId", notification.notificationId),
      notifications
    );
    setNotifications(update(notificationIndex, notification, notifications));
    setUnreadMessagesCount(unreadMessagesCount - 1);
  };

  const markNotificationAsReadClicked = (notification) => async () => {
    if (!notification.dateRead) {
      await markNotificationAsRead({
        notificationId: notification.notificationId,
        setNotification: updateNotification,
      });
    }
  };

  const notificationContext = {
    notifications,
    loadingNotifications,
    locationFilter,
    typeFilter,
    notificationTypes,
    locations,
    salesPeriods,
    filterLoading,
    unreadMessagesCount,
    viewAllClicked,
    updateFilter,
    markNotificationAsReadClicked,
    markAllAsReadClicked,
  };

  return (
    <NotificationsContext.Provider value={notificationContext}>
      {children}
    </NotificationsContext.Provider>
  );
};
