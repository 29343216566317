export const columnHeadings = [
  "Sight",
  "Year",
  "Box number",
  "Lot",
  "Box Description",
  "Number of rough stones",
  "Original Weight",
  "Owned",
  "Contracted",
  "Start Date",
  "End Date",
  "Rough rejection weight",
  "Makeable rejections weight",
  "Weight after sawing",
  "Weight after bruting/ coning",
  "Weight after blocking",
  "Final polished weight",
  "Number of polished stones",
  "Polished cts Single cut, eight cut, swiss cut,…",
  "Polished cts Round (full cut- brilliant)",
  "Polished cts Pear",
  "Polished cts Cushion",
  "Polished cts Princess",
  "Polished cts Heart",
  "Polished cts Oval",
  "Polished cts Marquise",
  "Polished cts Emerald",
  "Polished cts Radiant",
  "Polished cts Proprietary or branded makes",
  "Polished cts Other",
  "Polished cts below 0.01",
  "Polished cts 0.01-0.03",
  "Polished cts 0.04-0.07",
  "Polished cts 0.08-0.14",
  "Polished cts 0.15-0.17",
  "Polished cts 0.18-0.22",
  "Polished cts 0.23-0.29",
  "Polished cts 0.3-0.39",
  "Polished cts 0.4-0.49",
  "Polished cts 0.5-0.69",
  "Polished cts 0.7-0.89",
  "Polished cts 0.9-0.99",
  "Polished cts 1-1.49",
  "Polished cts 1.5-1.99",
  "Polished cts 2-2.99",
  "Polished cts 3-3.99",
  "Polished cts 4-4.99",
  "Polished cts 5-9.99",
  "Polished cts 10+",
  "Polished cts EX",
  "Polished cts VG",
  "Polished cts GD",
  "Polished cts FR",
  "Polished cts PR",
  "Polished cts FCY",
  "Polished cts D",
  "Polished cts E",
  "Polished cts F",
  "Polished cts G",
  "Polished cts H",
  "Polished cts I",
  "Polished cts J",
  "Polished cts K",
  "Polished cts L",
  "Polished cts M",
  "Polished cts N",
  "Polished cts O",
  "Polished cts P",
  "Polished cts Below Q",
  "Polished cts Fancy",
  "Polished cts TTLC",
  "Polished cts TLC",
  "Polished cts LC",
  "Polished cts CL",
  "Polished cts DC",
  "Polished cts TTLB",
  "Polished cts TLB",
  "Polished cts LB",
  "Polished cts BR",
  "Polished cts DB",
  "Polished cts FL",
  "Polished cts IF",
  "Polished cts VVS1",
  "Polished cts VVS2",
  "Polished cts VS1",
  "Polished cts VS2",
  "Polished cts SI1",
  "Polished cts SI2",
  "Polished cts SI3",
  "Polished cts I1",
  "Polished cts I2",
  "Polished cts I3",
  "AGS",
  "HRD",
  "GIA",
  "IGI",
  "GSI",
  "DBGIS",
  "Other",
];

export const expectedRow1Values = ["", ...columnHeadings];

export const mappingValues = [
  "",
  "sight",
  "year",
  "boxNumber",
  "lot",
  "boxDescription",
  "numberOfRoughStones",
  "originalWeight",
  "owned",
  "contracted",
  "startDate",
  "endDate",
  "roughRejectionWeight",
  "makeableRejectionsWeight",
  "weightAfterSawing",
  "weightAfterBrutingOrConing",
  "weightAfterBlocking",
  "finalPolishedWeight",
  "numberOfPolishedStones",
  "polishedCtsSingle",
  "polishedCtsRound",
  "polishedCtsPear",
  "polishedCtsCushion",
  "polishedCtsPrincess",
  "polishedCtsHeart",
  "polishedCtsOval",
  "polishedCtsMarquise",
  "polishedCtsEmerald",
  "polishedCtsRadiant",
  "polishedCtsProprietaryOrBranded",
  "polishedCtsOther",
  "polishedCtsBelow0_01",
  "polishedCts0_01to0_03",
  "polishedCts0_04to0_07",
  "polishedCts0_08to0_14",
  "polishedCts0_15to0_17",
  "polishedCts0_18to0_22",
  "polishedCts0_23to0_29",
  "polishedCts0_3to0_39",
  "polishedCts0_4to0_49",
  "polishedCts0_5to0_69",
  "polishedCts0_7to0_89",
  "polishedCts0_9to0_99",
  "polishedCts1to1_49",
  "polishedCts1_5to1_99",
  "polishedCts2to2_99",
  "polishedCts3to3_99",
  "polishedCts4to4_99",
  "polishedCts5to9_99",
  "polishedCtsAbove10",
  "polishedCtsEx",
  "polishedCtsVg",
  "polishedCtsGd",
  "polishedCtsFr",
  "polishedCtsPr",
  "polishedCtsFcy",
  "polishedCtsD",
  "polishedCtsE",
  "polishedCtsF",
  "polishedCtsG",
  "polishedCtsH",
  "polishedCtsI",
  "polishedCtsJ",
  "polishedCtsK",
  "polishedCtsL",
  "polishedCtsM",
  "polishedCtsN",
  "polishedCtsO",
  "polishedCtsP",
  "polishedCtsBelowQ",
  "polishedCtsFancy",
  "polishedCtsTtlc",
  "polishedCtsTlc",
  "polishedCtsLc",
  "polishedCtsCl",
  "polishedCtsDc",
  "polishedCtsTtlb",
  "polishedCtsTlb",
  "polishedCtsLb",
  "polishedCtsBr",
  "polishedCtsDb",
  "polishedCtsFl",
  "polishedCtsIf",
  "polishedCtsVvs1",
  "polishedCtsVvs2",
  "polishedCtsVs1",
  "polishedCtsVs2",
  "polishedCtsSi1",
  "polishedCtsSi2",
  "polishedCtsSi3",
  "polishedCtsI1",
  "polishedCtsI2",
  "polishedCtsI3",
  "ags",
  "hrd",
  "gia",
  "igi",
  "gsi",
  "dbgis",
  "other",
];
