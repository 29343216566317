import { notifyError } from "../lib/notifications";
import { createAxios } from "./axiosWrapper";

const orderServiceUrl = `${process.env.REACT_APP_CUSTOMER_INTEGRATION_URL}/api/order`;

const axios = () => createAxios(orderServiceUrl);

export const getOrdersLookupForCustomerAndSalesPeriod = async (
  customerId,
  salesPeriodId
) => {
  try {
    const request =
      `get-orders-lookup-for-customer-and-sales-period` +
      `?customerId=${encodeURIComponent(customerId)}` +
      `&salesPeriodId=${encodeURIComponent(salesPeriodId)}`;

    const customerOrderApplicationWindows = await axios().get(request);
    return customerOrderApplicationWindows.data;
  } catch (err) {
    notifyError("Failed to load ITO's.", err);
  }
};

export const getITOYearsForOrdersIndex = async (
  shouldDefaultToDeliverySchedulePeriod
) => {
  try {
    let request = `get-ito-years`;
    if (shouldDefaultToDeliverySchedulePeriod)
      request += `?shouldDefaultToDeliverySchedulePeriod=${encodeURIComponent(
        shouldDefaultToDeliverySchedulePeriod
      )}`;

    const itoYears = await axios().get(request);
    return itoYears.data;
  } catch (err) {
    notifyError("Failed to load ITO Years.", err);
  }
};

export const getOrdersAllocationsForCustomerAndSalesPeriod = async (
  customerId,
  salesPeriodId
) => {
  try {
    const request =
      `get-orders-allocations-for-customer-and-sales-period` +
      `?customerId=${encodeURIComponent(customerId)}` +
      `&salesPeriodId=${encodeURIComponent(salesPeriodId)}`;

    const customerOrderApplicationWindows = await axios().get(request);
    return customerOrderApplicationWindows.data;
  } catch (err) {
    notifyError("Failed to load ITO allocations.", err);
  }
};

export const getAdditionalApplicationData = async ({
  customerId,
  salesPeriodId,
  selectedOrderTypeId,
}) => {
  try {
    const request =
      `get-additional-applications-for-customer-and-sales-period` +
      `?customerId=${encodeURIComponent(customerId)}` +
      `&salesPeriodId=${encodeURIComponent(salesPeriodId)}` +
      `&orderTypeId=${encodeURIComponent(selectedOrderTypeId ?? "")}`;

    const additionalApplications = await axios().get(request);
    return additionalApplications.data;
  } catch (err) {
    notifyError("Failed to load additional applications", err);
  }
};

export const getNonItoAdditionalApplicationData = async ({
  customerId,
  salesPeriodId,
  selectedOrderType,
}) => {
  try {
    const request =
      `get-non-ito-additional-applications-for-customer-and-sales-period` +
      `?customerId=${encodeURIComponent(customerId)}` +
      `&salesPeriodId=${encodeURIComponent(salesPeriodId)}` +
      `${
        selectedOrderType
          ? `&orderTypeId=${encodeURIComponent(selectedOrderType)}`
          : ""
      }`;

    const additionalApplications = await axios().get(request);
    return additionalApplications.data;
  } catch (err) {
    notifyError("Failed to load non-ITO additional applications", err);
  }
};

export const getBothNonItoAndItoAdditionalApplicationData = async (
  customerId,
  salesPeriodId,
  selectedOrderType
) => {
  if (customerId && salesPeriodId) {
    const [itoResult, nonItoResult] = await Promise.all([
      getAdditionalApplicationData({
        customerId,
        salesPeriodId,
        selectedOrderTypeId: selectedOrderType,
      }),
      getNonItoAdditionalApplicationData({
        customerId,
        salesPeriodId,
        selectedOrderType,
      }),
    ]);

    let result = [];

    if (itoResult && itoResult.length > 0) {
      result = result.concat(itoResult);
    }
    if (nonItoResult && nonItoResult.length > 0) {
      result = result.concat(nonItoResult);
    }

    return result;
  } else {
    return [];
  }
};

export const getOrdersBulkPdsData = async (customerId) => {
  try {
    if (customerId) {
      const request =
        "orders-bulk-pds-data?customerId=" + encodeURIComponent(customerId);

      const bulkPds = await axios().get(request);
      return bulkPds && bulkPds.data ? bulkPds.data : [];
    }
    return [];
  } catch (err) {
    notifyError("Failed to retrieve seed data for the bulk PDS template", err);
  }
};

export const getMidtermPdsData = async (customerId) => {
  try {
    if (customerId) {
      const request = `midterm-pds-data?customerId=${encodeURIComponent(
        customerId
      )}`;

      const response = await axios().get(request);
      return response && response.data ? response.data : [];
    }
    return [];
  } catch (err) {
    notifyError("Failed to retrieve Midterm PDS data", err);
  }
};

export const saveOrdersBulkPdsData = async (customerId, bulkPdsData) => {
  try {
    const response = await axios().post(
      `orders-bulk-pds-data/${customerId}`,
      bulkPdsData
    );
    return response;
  } catch (err) {
    notifyError("Failed to process bulk PDS upload", err);
    throw err;
  }
};

export const saveMidtermPdsData = async (customerId, bulkPdsData) => {
  try {
    const response = await axios().post(
      `midterm-pds-data/${customerId}`,
      bulkPdsData
    );
    return response;
  } catch (err) {
    notifyError("Failed to save Midterm PDS", err);
    throw err;
  }
};

export const getOpenApplicationWindowFilter = async ({
  customerGroupId,
  customerId,
}) => {
  try {
    if (customerId === 0) {
      customerId = null;
    }

    const response = await axios().get(`open-application-window-filter`, {
      params: {
        customerGroupId,
        customerId,
      },
    });
    return response.data;
  } catch (err) {
    notifyError("Failed to fetch ITO application window filter", err);
    throw err;
  }
};

export const getOrderAllocationLastUpdate = async (customerId) => {
  try {
    const requestUrl = `get-orders-allocations-last-update`;

    const lastUpdateResponse = await axios().get(requestUrl, {
      params: {
        customerId,
      },
    });

    return lastUpdateResponse.data;
  } catch (err) {
    notifyError("Failed to retrieve last allocation update date", err);
  }
};

export const getOrderTypesForCustomerAndSalesPeriod = async (
  customerId,
  salesPeriodId
) => {
  try {
    const request =
      `get-order-types-for-customer-and-sales-period` +
      `?customerId=${encodeURIComponent(customerId)}` +
      `&salesPeriodId=${encodeURIComponent(salesPeriodId)}`;

    const customerOrderTypes = await axios().get(request);
    return customerOrderTypes.data;
  } catch (err) {
    notifyError("Failed to ITO types.", err);
  }
};

export const getOrdersSummaryForCustomerAndSalesPeriod = async (
  customerId,
  salesPeriodId
) => {
  try {
    const request =
      `get-orders-summary-for-customer-and-sales-period` +
      `?customerId=${encodeURIComponent(customerId)}` +
      `&salesPeriodId=${encodeURIComponent(salesPeriodId)}`;

    const customerOrderApplicationWindows = await axios().get(request);
    return customerOrderApplicationWindows.data;
  } catch (err) {
    notifyError("Failed to load ITO's.", err);
  }
};

export const getPdsLastUpdateDetails = async (customerId, orderIds) => {
  try {
    const request =
      `get-order-last-update-event-details/${encodeURIComponent(customerId)}`;
      
    const lastPdsUpdateEventDetails = await axios().post(request, orderIds);
    return lastPdsUpdateEventDetails.data;
  } catch (err) {
    notifyError("Failed to load last update details.", err);
  }

}; 