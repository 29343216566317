import React from "react";
import { Divider, Segment } from "semantic-ui-react";
import { StandardHeading } from "../../common/StandardHeading/StandardHeading";
import { StandardSmallHeading } from "../../common/StandardSmallHeading/StandardSmallHeading";
import Helmet from "react-helmet";
import "./Tutorials.scss";

export const Tutorials = () => {
  return (
    <>
      <Helmet>
        <title>De Beers Sightholder Portal - Tutorials</title>
      </Helmet>
      <StandardHeading>
        Welcome to our Sightholder Portal Tutorial Series
      </StandardHeading>
      <div className="wide-videos">
        <Segment>
          <StandardSmallHeading>Tutorial 1</StandardSmallHeading>
          <Divider />
          <iframe
            title="video1"
            src="https://player.vimeo.com/video/528227753?dnt=1"
            width="1458"
            height="820"
            frameborder="0"
            allowfullscreen=""
          ></iframe>
        </Segment>
        <Segment>
          <StandardSmallHeading>Tutorial 2</StandardSmallHeading>
          <Divider />
          <iframe
            title="video2"
            src="https://player.vimeo.com/video/528227888?dnt=1"
            width="1458"
            height="820"
            frameborder="0"
            allowfullscreen=""
          ></iframe>
        </Segment>
        <Segment>
          <StandardSmallHeading>Tutorial 3</StandardSmallHeading>
          <Divider />
          <iframe
            title="video3"
            src="https://player.vimeo.com/video/528227941?dnt=1"
            width="1458"
            height="820"
            frameborder="0"
            allowfullscreen=""
          ></iframe>
        </Segment>
        <Segment>
          <StandardSmallHeading>Tutorial 4</StandardSmallHeading>
          <Divider />
          <iframe
            title="video4"
            src="https://player.vimeo.com/video/528227998?dnt=1"
            width="1458"
            height="820"
            frameborder="0"
            allowfullscreen=""
          ></iframe>
        </Segment>
      </div>
      <div className="narrow-videos">
        <Segment>
          <StandardSmallHeading>Tutorial 1</StandardSmallHeading>
          <Divider />
          <iframe
            title="video1"
            src="https://player.vimeo.com/video/528227753?dnt=1"
            width="1230"
            height="692"
            frameborder="0"
            allowfullscreen=""
          ></iframe>
        </Segment>
        <Segment>
          <StandardSmallHeading>Tutorial 2</StandardSmallHeading>
          <Divider />
          <iframe
            title="video2"
            src="https://player.vimeo.com/video/528227888?dnt=1"
            width="1230"
            height="692"
            frameborder="0"
            allowfullscreen=""
          ></iframe>
        </Segment>
        <Segment>
          <StandardSmallHeading>Tutorial 3</StandardSmallHeading>
          <Divider />
          <iframe
            title="video3"
            src="https://player.vimeo.com/video/528227941?dnt=1"
            width="1230"
            height="692"
            frameborder="0"
            allowfullscreen=""
          ></iframe>
        </Segment>
        <Segment>
          <StandardSmallHeading>Tutorial 4</StandardSmallHeading>
          <Divider />
          <iframe
            title="video4"
            src="https://player.vimeo.com/video/528227998?dnt=1"
            width="1230"
            height="692"
            frameborder="0"
            allowfullscreen=""
          ></iframe>
        </Segment>
      </div>
    </>
  );
};
