export const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm z";
export const DATE_FORMAT = "DD/MM/YYYY";
export const TIME_FORMAT = "HH:mm z";
export const EXCEL_NUMBER_FORMAT = "#,##0;";
export const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
