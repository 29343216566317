import React, { useState } from "react";

import Excel from "exceljs";
import ManufacturingDataUploadFileButton from "./ManufacturingDataUploadFileButton";
import { notifyError } from "../../../../lib/notifications";
import { withFormik } from "formik";
import { isEmpty } from "lodash";

const isTruthy = (value) => !!value;

const checkWorkbookLayout = (workbook, expectedRow1Values) => {
  if (workbook) {
    var worksheet = getManufacturingDataWorksheet(workbook, expectedRow1Values);

    if(worksheet) {
      return true;
    }
  }
  return false;
};

const getManufacturingDataWorksheet = (workbook, expectedRow1Values) => {
  for(let i = 0; i < workbook.worksheets.length; i++) {
    if(checkWorkSheetHeaderRow(workbook.worksheets[i], expectedRow1Values)) {
      return workbook.worksheets[i];
    }
  }
  return null;
};

const checkWorkSheetHeaderRow = (worksheet, expectedRow1Values) => {
  const row1Values = worksheet.getRow(1).values;
    if (row1Values.length !== expectedRow1Values.length) {
      return false;
    } else {
      for (let i = 1; i < expectedRow1Values.length; i++) {
        if (expectedRow1Values[i].toUpperCase() !== row1Values[i].toUpperCase()) {
          return false;
        }
      }
    }
    return true;
};

const getCellStringValue = (cellValue) => {
  return isTruthy(cellValue) || cellValue === 0
    ? cellValue.toString().trim()
    : "";
};

const readWorkbookContent = ({ workbook, mappingValues, expectedRow1Values }) => {
  const worksheet = getManufacturingDataWorksheet(workbook, expectedRow1Values);
  const dataset = [];
  
  worksheet.eachRow(function(row, rowNumber) {
    if(rowNumber >= 2) {
      const item = {};
      let isEmptyRow = true;
      for (let k = 1; k < mappingValues.length; k++) {
        item[mappingValues[k]] = getCellStringValue(
          row.getCell(k).value
        );

        if(!isEmpty(item[mappingValues[k]]) && isEmptyRow) {
          isEmptyRow = false;
        }
      }

      if(!isEmptyRow) {
        dataset.push(item);
      }      
    }
  });

  return dataset;
};

const isExcelFile = (file) => {
  return (
    file?.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
    file?.type === "application/vnd.ms-excel"
  );
};

const ManufacturingDataUploadFileForm = withFormik({
  mapPropsToValues: ({ mappingValues, expectedRow1Values }) => ({
    selectedFile: null,
    excelWorkbook: null,
    data: null,
    expectedRow1Values,
    mappingValues,
  }),
  displayName: "ManufacturingDataUploadFileForm",
})(ManufacturingDataUploadFileButton);

export const ManufacturingDataUploadFileContainer = ({
  onFileSelected,
  expectedRow1Values,
  disabled,
  mappingValues,
  children,
  buttonText = "Select a file to Upload",
  setFileError,
  setFileLoading,
  fileLoading,
  lineValidationErrors,
}) => {
  const [validationError, setValidationError] = useState();

  const readFile = async (values, file, setFieldValue) => {
    try {
      setFileLoading(true);
      const fileReader = new FileReader();
      fileReader.onload = async function (e) {
        const data = e.target.result;
        const workbook = new Excel.Workbook();
        await workbook.xlsx.load(data);
        await setFieldValue("excelWorkbook", workbook);
        if (checkWorkbookLayout(workbook, values.expectedRow1Values)) {
          const workbookContent = readWorkbookContent({
            workbook,
            file,
            mappingValues: values.mappingValues,
            expectedRow1Values: values.expectedRow1Values,
          });
          await setFieldValue("data", workbookContent, false);
          onFileSelected({
            excelFileValues: workbookContent,
            fileName: file.name,
            file,
          });
          setValidationError();
        } else {
          setValidationError(`Has the wrong spreadsheet layout.`);
        }
      };
      fileReader.readAsArrayBuffer(file);
    } catch (error) {
      notifyError("Could not read the file.", error);
    } finally {
      setFileLoading(false);
    }
  };

  const onFileChange = async (event, setFieldValue, values) => {
    setValidationError();
    const files = event.target.files;
    if (files.length > 0) {
      const excelFile = files[0];
      if (isExcelFile(excelFile)) {
        await readFile(values, excelFile, setFieldValue);
      } else {
        setValidationError(`Has the wrong file type.`);
      }

      await setFieldValue("selectedFile", excelFile);
    }
  };

  return (
    <>
      <ManufacturingDataUploadFileForm
        disabled={disabled}
        mappingValues={mappingValues}
        expectedRow1Values={expectedRow1Values}
        onFileSelected={onFileSelected}
        onFileChange={onFileChange}
        buttonText={buttonText}
        setFileError={setFileError}
        fileLoading={fileLoading}
        validationError={validationError}
        lineValidationErrors={lineValidationErrors}
      >
        {children}
      </ManufacturingDataUploadFileForm>
    </>
  );
};
