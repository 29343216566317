import "./MenuContent.scss";

import { Dropdown, Menu } from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";

import classNames from "classnames";
import { tokenUserPermissions } from "../../lib/tokenUserPermissions";
import { validateRole } from "../../lib/validateRole";

export const MenuContent = withRouter(({ location, history }) => {
  const [currentPath, setCurrentPath] = useState("");
  const extractCurrentLocation = (setCurrentPath, pathname) => {
    const path = pathname.split("/")[1] || "dashboard";
    setCurrentPath(path);
  };

  useEffect(() => {
    extractCurrentLocation(setCurrentPath, location.pathname);
  }, [location.pathname]);

  const activeLeftNavCheck = (path) => ({
    "active-left-nav": path === currentPath,
  });

  const navigateToLink = (link) => {
    history.push(link);
  };

  return (
    <Fragment>
      {validateRole(tokenUserPermissions.CUSTOMER_PORTAL) && (
        <Menu.Item
          as={Link}
          to={"/dashboard"}
          className={classNames(
            "menu-item",
            "dashboard",
            activeLeftNavCheck("dashboard")
          )}
        >
          HOME
        </Menu.Item>
      )}
      {validateRole(tokenUserPermissions.CUSTOMER_PORTAL) && (
        <Menu.Item
          as={Link}
          to={"/orders"}
          className={classNames(
            "menu-item",
            "orders",
            activeLeftNavCheck("orders")
          )}
        >
          ITO
        </Menu.Item>
      )}
      {validateRole(tokenUserPermissions.CUSTOMER_PORTAL) && (
        <Menu.Item
          as={Link}
          to={"/current-sight"}
          className={classNames(
            "menu-item",
            "current-sight",
            activeLeftNavCheck("current-sight")
          )}
        >
          CURRENT SIGHT
        </Menu.Item>
      )}
      {validateRole(tokenUserPermissions.CUSTOMER_PORTAL) && (
        <Menu.Item
          as={Link}
          to={"/applications"}
          className={classNames(
            "menu-item",
            "applications",
            activeLeftNavCheck("applications")
          )}
        >
          APPLICATIONS REVIEW
        </Menu.Item>
      )}
      {validateRole(tokenUserPermissions.CUSTOMER_PORTAL) && (
        <Menu.Item
          as={Link}
          to={"/ito-year-overview"}
          className={classNames(
            "menu-item",
            "ito-year-overview",
            activeLeftNavCheck("ito-year-overview")
          )}
        >
          ITO YEAR OVERVIEW
        </Menu.Item>
      )}
      {validateRole(tokenUserPermissions.CUSTOMER_PORTAL) && (
        <Menu.Item
          as={Link}
          to={"/invoices"}
          className={classNames(
            "menu-item",
            "invoices",
            activeLeftNavCheck("invoices")
          )}
        >
          INVOICES
        </Menu.Item>
      )}
      <Dropdown
        item
        text="USEFUL LINKS"
        className={classNames(
          "menu-item",
          "useful-links",
          activeLeftNavCheck("useful-links")
        )}
      >
        <Dropdown.Menu>
          <Dropdown.Item
            as="a"
            target="_blank"
            href="https://www.debeersgroup.com/sustainability-and-ethics/leading-ethical-practices-across-the-industry/best-practice-principles-assurance-programme"
          >
            BPP Documentation
          </Dropdown.Item>
          <Dropdown.Item
            as="a"
            target="_blank"
            href="https://www.debeersbpp.com/"
          >
            BPP Smart System
          </Dropdown.Item>
          <Dropdown.Item
            as="a"
            target="_blank"
            href="https://sightholdersignature.debeersgroup.com/"
          >
            Sightholder Signature
          </Dropdown.Item>
          <Dropdown.Item
            as="a"
            target="_blank"
            href="https://angloamerican.box.com/s/yqcqkvrius9j060v9puewijako8u1wjb"
          >
            Supply Documentation
          </Dropdown.Item>
          <Dropdown.Item
            as="a"
            target="_blank"
            href="https://angloamerican.app.box.com/folder/149462619322?s=m2qum6abzt82h49l3b57cu1ydho6kgx7"
          >
            Insights Documentation
          </Dropdown.Item>
          <Dropdown.Item as="a" onClick={() => navigateToLink("/tutorials")}>
            Tutorials
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
});
