export const services = {
  planningFlexibility: {
    noPds: "No PDS",
    limitedFlexibility: "Limited flexibility",
    fullFlexibility: "Full flexibility",
    noFlexibility: "No flexibility",
  },
};

export const applicationWindowTypes = {
  deliveryScheduleSubmissionAdjustment: 1,
  itoSwapRequests: 2,
  applications: 3,
  additionalGoodsApplication: 4,
  midTermItoRephasing: 5,
  midtermItoSwapRequests: 6,
  additionalBroughtForward: 7,
  inPlanBalanceSwapRequests: 8,
  manufacturingDataSubmission: 9,
};

export const boxOccurrenceTypeEnums = {
  Default: 0,
  NEqualLots: 1,
  MaxMinBoxesWithRemainderInOneBox: 2,
  MaxMinBoxesWithRemainderSpreadEvenly: 3,
  DoNotSplit: 4,
  CustomerInformsKAM: 5,
};

export const applicationModeEnums = {
  create: "Create",
  edit: "Edit",
  readOnly: "ReadOnly",
};

export const notificationMetadataEnum = {
  customerId: 1,
};

export const exemptedDeclarationCustomers = {
  "Chow Sang Sang Jewellery Company Ltd":
    "Chow Sang Sang Jewellery Company Ltd",
  "Chow Tai Fook Jewellery Co. Ltd": "Chow Tai Fook Jewellery Co. Ltd",
  "Laurelton Diamonds": "Laurelton Diamonds",
  "Luk Fook Diamond (International) Co Ltd":
    "Luk Fook Diamond (International) Co Ltd",
  "Signet Jewelers Limited": "Signet Jewelers Limited",
};

export const financialSubmissionTypes = {
  "Financial Workbook (Excel Version)": 1,
  "Stamped Financial Workbook (Pdf Version)": 2,
  "IFRS Consolidation Group organisational Structure": 3,
};

export const notificationTypes = {
  UnAssigned: 0,
  ApplicationsDeliveryScheduleSubmissionAdjustment: 1,
  ApplicationsITOSwapRequests: 2,
  ApplicationsApplications: 3,
  ApplicationsAdditionalGoodsApplication: 4,
  AllocationsSightbysight: 5,
  AllocationsITOAnnouncements: 6,
  ITOAnnouncement: 7,
  MidTermPdsRephasing: 8,
  MidTermITOSwapRequests: 9,
  FinancialSubmissionWindowAnnouncement: 10,
  AdditionalBroughtForwardsApplications: 11,
  InPlanBalanceSwapApplications: 12,
  ManufacturingDataSubmission: 13,
};
