import "./ManufacturingData.scss";
import { Divider, Message } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import {
  checkIfManufacturingWindowIsOpen,
  fetchCustomerGroup,
  getCurrentSightManufacturingDataSubmissions,
  searchPreviousSightManufacturingDataSubmissions,
} from "./ManufacturingData.functions.js";

import { LoaderComponent } from "../../../../common/LoaderComponent";
import ManufacturingDataCurrentSightTable from "./ManufacturingDataCurrentSightTable";
import ManufacturingDataPreviousSightTable from "./ManufacturingDataPreviousSightTable";

import { StandardSubheading } from "../../../../common/StandardSubheading/StandardSubheading";

export const ManufacturingData = ({
  currentSalesPeriod,
  selectedCustomerGroup,
}) => {
  const [customerGroup, setCustomerGroup] = useState();
  const [isManufacturingWindowOpen, setIsManufacturingWindowOpen] =
    useState(false);
  const [hasManufacturingPermission, setHasManufacturingPermission] =
    useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingPreviousSight, setLoadingPreviousSight] = useState(true);
  const [tableDataPreviousSight, setTableDataPreviousSight] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [loadingCurrentSight, setLoadingCurrentSight] = useState(true);
  const [tableDataCurrentSight, setTableDataCurrentSight] = useState([]);

  useEffect(() => {
    checkIfManufacturingWindowIsOpen({
      currentSalesPeriod,
      setIsManufacturingWindowOpen,
    });
  }, [currentSalesPeriod]);

  useEffect(() => {
    const fetchManufacturingIndexTableData = async () => {
      if (selectedCustomerGroup && currentSalesPeriod) {
        setCustomerGroup();
        setLoadingPreviousSight(true);
        setLoadingCurrentSight(true);
        const customerGroupResult = await fetchCustomerGroup({
          setCustomerGroup,
          customerGroupId: selectedCustomerGroup,
          setHasManufacturingPermission,
          setLoading,
        });

        await getCurrentSightManufacturingDataSubmissions({
          setLoading: setLoadingCurrentSight,
          setTableData: setTableDataCurrentSight,
          salesPeriod: currentSalesPeriod,
          customerGroup: customerGroupResult,
        });

        setLoadingPreviousSight(true);
        await searchPreviousSightManufacturingDataSubmissions({
          setLoading: setLoadingPreviousSight,
          setTableData: setTableDataPreviousSight,
          currentSalesPeriod: currentSalesPeriod,
          customerGroup: customerGroupResult,
          pageSize: 10,
          pageNumber: 1,
          setTotalResults,
          searchString: "",
        });
      }
    };

    fetchManufacturingIndexTableData();
  }, [selectedCustomerGroup, currentSalesPeriod]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (customerGroup && currentSalesPeriod) {
      setLoadingPreviousSight(true);
      searchPreviousSightManufacturingDataSubmissions({
        setLoading: setLoadingPreviousSight,
        setTableData: setTableDataPreviousSight,
        currentSalesPeriod: currentSalesPeriod,
        customerGroup: customerGroup,
        pageSize,
        pageNumber,
        setTotalResults,
        searchString,
      });
    }
  }, [searchString, pageSize, pageNumber]);

  const pageContent = (
    <React.Fragment>
      <StandardSubheading>
        <div className="flex justify-space-between">
          <span className="sub-heading-text">
            Manufacturing Insights Data: {customerGroup && customerGroup.name}
          </span>
        </div>
      </StandardSubheading>

      <Divider />

      <LoaderComponent loading={loading}>
        {!hasManufacturingPermission ? (
          <Message
            icon="warning circle"
            header={`Manufacturing Insights Data Access`}
            content={`You do not have access to view or submit manufacturing insights data for this customer group.`}
          />
        ) : (
          <React.Fragment>
            <div className="ms-margin-bottom-2">
              <p>
                Please upload your Manufacturing Insights Data to support our
                continued journey towards a more insight and data driven,
                collaborative and customer centric future.
              </p>

              <p>
                Where your business operates within a group of Sightholder
                entities, you can choose to either upload as a group with a
                single consolidated data file upload, or as separate entities.{" "}
              </p>
            </div>
            <div className="ms-margin-bottom-2">
              <p>
                <strong>
                  The data files submitted to De Beers should be named in the
                  following format:
                </strong>
              </p>

              <p>
                When Submitting per Group please use the following format:
                Customer Group Name - dd/mm/yyyy.xlsx
              </p>

              <p>
                When Submitting per Customer please use the following format:
                Sightholder Name - dd/mm/yyyy.xlsx
              </p>
            </div>
            {!isManufacturingWindowOpen && (
              <Message
                icon="copy outline"
                header={`Manufacturing Insights Data Submission Window`}
                content={`There is currently no manufacturing insights data submission window open for this sales period.`}
              />
            )}
            <ManufacturingDataCurrentSightTable
              currentSalesPeriod={currentSalesPeriod}
              customerGroup={customerGroup}
              hasManufacturingPermission={hasManufacturingPermission}
              isManufacturingWindowOpen={isManufacturingWindowOpen}
              loading={loadingCurrentSight}
              tableData={tableDataCurrentSight}
              setTableData={setTableDataCurrentSight}
              setLoading={setLoadingCurrentSight}
            />
            <ManufacturingDataPreviousSightTable
              hasManufacturingPermission={hasManufacturingPermission}
              pageSize={pageSize}
              setPageSize={setPageSize}
              tableData={tableDataPreviousSight}
              loading={loadingPreviousSight}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              totalResults={totalResults}
              setSearchString={setSearchString}
              searchString={searchString}
            />
          </React.Fragment>
        )}
      </LoaderComponent>
    </React.Fragment>
  );

  return pageContent;
};
