import { useState, useEffect, useContext } from "react";
import { useOktaAuth } from "@okta/okta-react";
import {
  handleOktaTokenRefreshAsync,
  handleSpsTokenRefreshAsync,
} from "./TokenRefreshHandler.functions";
import { SpsLoginContext } from "../../modules/login/SpsLoginContext";

export const TokenRefreshHandler = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [oktaRefreshTrigger, setOktaRefreshTrigger] = useState(0);
  const { spsLogin } = useContext(SpsLoginContext);
  const [shouldRefreshToken, setShouldRefreshToken] = useState(false);

  useEffect(() => {

    setShouldRefreshToken(true)

  }, []);

  useEffect(() => {
    setShouldRefreshToken(true)
  }, []);

  useEffect(() => {
    const oktaTokenRefreshTimeout = null;
    if (spsLogin.isLoggedIn) {
      handleOktaTokenRefreshAsync(
        oktaTokenRefreshTimeout,
        authState,
        oktaAuth,
        setOktaRefreshTrigger,
        oktaRefreshTrigger
      );
    }
    return () => clearTimeout(oktaTokenRefreshTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oktaRefreshTrigger, spsLogin]);

  useEffect(() => {
    const spsTokenRefreshTimeout = null;
    if (spsLogin.isLoggedIn) {
      if (authState?.isAuthenticated && authState?.accessToken?.accessToken && shouldRefreshToken) {
        handleSpsTokenRefreshAsync(
          authState.accessToken.accessToken,
          spsTokenRefreshTimeout,
          setShouldRefreshToken
        );
        setShouldRefreshToken(false);
      }
    }
    return () => clearTimeout(spsTokenRefreshTimeout);
  }, [authState, spsLogin, shouldRefreshToken]);

  return null;
};
