import { Button, Grid, Message, Table } from "semantic-ui-react";
import React, { useEffect } from "react";
import {
  UilCheckCircle,
  UilExclamationCircle,
  UilPlusCircle,
} from "@iconscout/react-unicons";

const onSelectFileClick = () => {
  document.getElementById("file-select").click();
};

const ManufacturingDataUploadFileButton = ({
  validationError,
  setFieldValue,
  values,
  onFileChange,
  expectedRow1Values,
  mappingValues,
  setFileError,
  fileLoading,
  lineValidationErrors,
  buttonText,
}) => {
  useEffect(() => {
    setFieldValue("expectedRow1Values", expectedRow1Values);
    setFieldValue("mappingValues", mappingValues);
  }, [expectedRow1Values, mappingValues, setFieldValue]);

  useEffect(() => {
    setFileError(!validationError);
  }, [validationError, setFileError]);

  return (
    <div>
      <Grid padded="horizontally">
        <Grid.Row>
          <Button
            primary
            onClick={onSelectFileClick}
            type="button"
            loading={fileLoading}
            disabled={fileLoading}
            icon
          >
            <div className="flex">
              <div>{buttonText}</div>
              <UilPlusCircle className="icon-margin"></UilPlusCircle>
            </div>
          </Button>
          <input
            id="file-select"
            type="file"
            hidden
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={(event) => {
              onFileChange(event, setFieldValue, values);
            }}
          />
        </Grid.Row>
        <Grid.Row>
          {values.selectedFile &&
            !fileLoading &&
            (!validationError ? (
              <Message className="ms-hundred-percent-width">
                <div className="ms-flex-secondary">
                  <Message.Content>
                    <strong>File Name: </strong>
                    {values.selectedFile.name}
                  </Message.Content>
                  <UilCheckCircle color="#1E561F"></UilCheckCircle>
                </div>
              </Message>
            ) : (
              <Message negative className="ms-hundred-percent-width">
                <div className="ms-flex-secondary">
                  <Message.Content>
                    <strong>File Name: </strong>
                    {values.selectedFile.name}
                  </Message.Content>
                  <UilExclamationCircle color="#973937"></UilExclamationCircle>
                </div>
                <ul>
                  <li>{validationError}</li>
                </ul>
              </Message>
            ))}
        </Grid.Row>
        {lineValidationErrors && lineValidationErrors.length > 0 && (
          <React.Fragment>
            <Grid.Row>
              <Message className="ms-hundred-percent-width" negative>
                <div className="ms-flex-secondary">
                  <Message.Content>
                    <strong>Upload Status: </strong>
                    Upload failed due to validation errors. Please fix all
                    errors and reupload the file.
                  </Message.Content>
                </div>
              </Message>
            </Grid.Row>
            <Grid.Row>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Line Number</Table.HeaderCell>
                    <Table.HeaderCell>Column Name</Table.HeaderCell>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                  </Table.Row>
                  {lineValidationErrors.map((item, index) => {
                    return (
                      <Table.Row key={index}>
                        <Table.Cell>{item.lineNumber}</Table.Cell>
                        <Table.Cell>{item.columnName}</Table.Cell>
                        <Table.Cell>{item.description}</Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Header>
              </Table>
            </Grid.Row>
          </React.Fragment>
        )}
      </Grid>
    </div>
  );
};

export default ManufacturingDataUploadFileButton;
