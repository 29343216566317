import { notifyError } from "./notifications";
import { createAxios } from "./axiosWrapper";

const bankListUrl = `${process.env.REACT_APP_CUSTOMER_INTEGRATION_URL}/api/Bank`;

const axios = () => createAxios(bankListUrl);

export const getBanksForCustomer = async (customerGroupId) => {
  try {
    const requestUrl = `by-customer-group-id/${customerGroupId}`;

    const banksList = await axios().get(requestUrl);
    
    return banksList.data;
  } catch (err) {
    notifyError("Failed to load banks list for customer", err);
  }
};

export const postBanks = async (banks) => {
  try {
    await axios().post("", banks);
  } catch (err) {
    notifyError("Failed to save bank list for customer", err);
  }
};
