import { notifyError } from "./notifications";
import { createAxios } from "./axiosWrapper";
import { sortBy } from "lodash";

const userApiUrl = `${process.env.REACT_APP_CUSTOMER_INTEGRATION_URL}/api`;

const axios = () => createAxios(userApiUrl);

export const getUserCustomers = async () => {
  try {
    const requestUrl = "get-user-customers";
    const customerGroupRequest = await axios().get(`/user/${requestUrl}`);
    const response = customerGroupRequest.data || [];
    return sortBy(response, ["customerGroupName"]);
  } catch (error) {
    notifyError("Failed to get the customers.", error);
  }
};
