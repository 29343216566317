import React from "react";
import { Segment } from "semantic-ui-react";
import { StandardHeading } from "../../common/StandardHeading/StandardHeading";

export const DesktopNotAvailable = () => {
  return (
    <Segment compact padded="very" className="desktop-message">
      <StandardHeading>
        Large screen view coming soon.
      </StandardHeading>
      <p>
        Please decrease the size of your browser window, decrease your screen
        resolution or use a smaller device.
      </p>
    </Segment>
  );
};

export default DesktopNotAvailable;
