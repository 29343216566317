import React, { useContext, useState } from "react";
import { Divider, Card, Input, Select } from "semantic-ui-react";
import "./NotificationsContainer.scss";
import { NotificationCard } from "./NotificationCard";
import { StandardSubheading } from "../../common/StandardSubheading/StandardSubheading";
import { LinkButton } from "../../common/LinkButton/LinkButton";
import { UilArrowLeft } from "@iconscout/react-unicons";
import { LoaderComponent } from "../../common/LoaderComponent";
import { NotificationsContext } from "./NotificationsContext";

export const NotificationsComponent = ({
  showAdditionalFilters = false,
  closePopup,
}) => {
  const [viewingNotification, setViewingNotification] = useState();
  const {
    notifications,
    loadingNotifications,
    typeFilter,
    notificationTypes,
    locations,
    salesPeriods,
    filterLoading,
    viewAllClicked,
    updateFilter,
    markNotificationAsReadClicked,
    markAllAsReadClicked,
  } = useContext(NotificationsContext);

  const viewNotification = (notification) => async () => {
    markNotificationAsReadClicked(notification)();
    setViewingNotification(notification);
  };

  return (
    <>
      <div className="notifications-container">
        <StandardSubheading>Notifications</StandardSubheading>
        <LinkButton onClick={viewAllClicked} className="view-all-button">
          View all
        </LinkButton>
      </div>
      <Divider />
      <div className="">
        <Input
          placeholder="Search..."
          loading={filterLoading}
          onChange={(event, data) => updateFilter("search", data.value)}
          iconPosition="left"
          icon="search"
          fluid
        />
      </div>
      {showAdditionalFilters && (
        <div>
          <div className="notification-filter-field" key={"filter1"}>
            <Select
              options={locations}
              clearable={true}
              onChange={(_, { value }) => {
                updateFilter("location", value);
              }}
              closeOnChange={true}
              placeholder="Location"
              loading={filterLoading}
            />
            <Select
              options={salesPeriods}
              clearable={true}
              onChange={(_, { value }) => {
                updateFilter("sight", value);
              }}
              closeOnChange={true}
              placeholder="Sight"
              loading={filterLoading}
            />
            <Select
              options={notificationTypes}
              clearable={true}
              onChange={(_, { value }) => {
                updateFilter("type", value);
              }}
              closeOnChange={true}
              value={typeFilter}
              placeholder="Type"
              loading={filterLoading}
            />
          </div>
        </div>
      )}

      {viewingNotification && (
        <div className="notifications-container">
          <UilArrowLeft
            className="back-arrow"
            onClick={() => setViewingNotification(null)}
          />
        </div>
      )}
      {!viewingNotification && (
        <div className="notifications-container justify-right">
          <LinkButton onClick={markAllAsReadClicked} floated="right">
            Mark all as read
          </LinkButton>
        </div>
      )}
      <div className="notifications-list">
        {loadingNotifications && (
          <LoaderComponent loading={loadingNotifications}></LoaderComponent>
        )}
        {!loadingNotifications &&
          !viewingNotification &&
          notifications &&
          notifications.map((notification, index) => (
            <NotificationCard
              key={index}
              notification={notification}
              markAsRead={markNotificationAsReadClicked(notification)}
              viewNotification={viewNotification(notification)}
              closePopup={closePopup}
            />
          ))}
        {viewingNotification && (
          <NotificationCard
            notification={viewingNotification}
            markAsRead={markNotificationAsReadClicked(viewingNotification)}
            viewingNotification={true}
            closePopup={closePopup}
          />
        )}
        {!loadingNotifications &&
          notifications &&
          notifications.length === 0 && (
            <Card className="notification-card">No notifications</Card>
          )}
      </div>
    </>
  );
};
