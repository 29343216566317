import { notifyError } from "./notifications";
import { createAxios } from "./axiosWrapper";

const boxControllerUrl = `${process.env.REACT_APP_CUSTOMER_INTEGRATION_URL}/api/Box`;

const axios = () => createAxios(boxControllerUrl);

export const getAllBoxes = async () => {
  try {
    const response = await axios().get("get-all-boxes-eager-loaded");
    return response.data;
  } catch (err) {
    notifyError("Could not fetch all of the boxes", err);
  }
};

export const searchBoxes = async (filters, limit) => {
  try {
    const requestUrl = `/search-boxes-additional-applications?active=${filters.active}&customerId=${filters.customerId}&limit=${limit}`;

    const boxTemplateResults = await axios().get(requestUrl);
    const boxTemplates = boxTemplateResults.data;
    return boxTemplates;
  } catch (err) {
    notifyError("Failed to search boxes", err);
  }
};

export const getAllCategories = async (customerId, salesPeriodId) => {
  try {
    if (customerId && salesPeriodId) {
      const requestUrl = `/get-box-categories?customerId=${customerId}&salesPeriod=${salesPeriodId}`;

      const categoryResults = await axios().get(requestUrl);

      const categories = categoryResults.data;

      return categories;
    } else {
      return { categories: [] };
    }
  } catch (error) {
    notifyError("Failed to load all categories", error);
  }
};
