import "./ExpandCollapse.scss";

import * as Unicon from "@iconscout/react-unicons";

import { Button } from "semantic-ui-react";
import React from "react";

export const ExpandCollapse = ({ expanded, setExpandedState }) => {
  return (
    <div className="collapse-container">
      {expanded && (
        <Button
          className="collapse-link"
          onClick={() => {
            setExpandedState(false);
          }}
        >
          Collapse all <Unicon.UilAngleUp size={20} />
        </Button>
      )}
      {!expanded && (
        <Button
          className="collapse-link"
          onClick={() => {
            setExpandedState(true);
          }}
        >
          View all <Unicon.UilAngleDown size={20} />
        </Button>
      )}
    </div>
  );
};
