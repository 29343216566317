import { getAllSalesPeriods } from "../../lib/apiSalesPeriod";
import {
  getAllCommunicationTypes,
  getCommunications,
  patchCommunicationMarkAsRead,
  patchCommunicationsMarkAllAsRead,
} from "../../lib/apiCustomerPortalCommunications";
import { notifyError } from "../../lib/notifications";

export const fetchCommunications = async ({
  setLoading,
  pageSize,
  pageNumber,
  setCommunications,
  titleFilter,
  typeIdFilter,
  salesPeriodIdFilter,
  releasedDateFromFilter,
  releasedDateToFilter,
  sortColumn,
  sortDirection,
}) => {
  setLoading(true);
  try {
    const communicationOptions = {
      pageSize,
      pageNumber,
      titleFilter,
      typeIdFilter,
      salesPeriodIdFilter,
      releasedDateFromFilter,
      releasedDateToFilter,
      sortColumn,
      sortDirection,
    };

    const response = await getCommunications(communicationOptions);

    setCommunications(response ? response.data : []);
  } finally {
    setLoading(false);
  }
};

export const markCommunicationAsRead = async ({
  communicationId,
  setCommunication,
}) => {
  try {
    const communication = await patchCommunicationMarkAsRead({
      communicationId,
    });
    setCommunication(communication);
  } catch (err) {
    notifyError("Error while marking communication as read", err);
  }
};

export const markAllCommunicationsAsRead = async () => {
  try {
    await patchCommunicationsMarkAllAsRead();
  } catch (err) {
    notifyError("Error while marking communication as read", err);
  }
};

export const getCommunicationTypesList = async (setCommunicationTypes) => {
  const communicationTypeList = await getAllCommunicationTypes();
  if (communicationTypeList) {
    setCommunicationTypes(
      communicationTypeList.map((communicationType) => {
        return {
          name: communicationType.name,
          key: communicationType.communicationTypeId,
          text: communicationType.name,
          value: communicationType.communicationTypeId,
        };
      })
    );
  }
};

export const getSalesPeriods = async (setSalesPeriods) => {
  const allSalesPeriods = await getAllSalesPeriods();
  if (allSalesPeriods) {
    setSalesPeriods(
      allSalesPeriods.map((salesPeriod) => {
        return {
          name: salesPeriod.name,
          key: salesPeriod.salesPeriodId,
          text: salesPeriod.name,
          value: salesPeriod.salesPeriodId,
        };
      })
    );
  }
};
