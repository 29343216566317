import React, { useState, useEffect, useContext } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { orchestrateLogoutAsync } from "./Logout.functions";
import { Redirect } from "react-router-dom";
import { SpsLoginContext } from "../../modules/login/SpsLoginContext";
import { LoaderComponent } from "../../common/LoaderComponent";

export const Logout = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [loggedOut, setLoggedOut] = useState(false);
  const { setSpsLogin } = useContext(SpsLoginContext);

  useEffect(() => {
    orchestrateLogoutAsync(oktaAuth, setLoggedOut, setSpsLogin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (authState.isAuthenticated || authState.isPending || !loggedOut) {
    return (
      <div>
        <LoaderComponent loading={true} message={"Busy logging out..."} />
      </div>
    );
  }

  return <Redirect to="/" />;
};

export default Logout;
