import {
  getUserBoxFavourites,
  updateServerFavouriteAsync,
  upsertNonItoAdditionalApplication,
} from "../../../../lib/apiAdditionalApplications";
import { prop, sortBy } from "ramda";

import { getBothNonItoAndItoAdditionalApplicationData } from "../../../../lib/apiOrder";
import { notifySuccess } from "../../../../lib/notifications";
import { searchBoxes } from "../../../../lib/apiProductManagement";
import { uniqBy } from "lodash";

const sortByBoxDescription = sortBy(prop("boxDescription"));
const sortByCategory = sortBy(prop("text"));

export const getBoxes = async (
  setLoading,
  setBoxes,
  setBoxOptions,
  setCategoryOptions,
  customerId
) => {
  try {
    setLoading(true);

    const boxes = await searchBoxes({ active: true, customerId }, 0);

    if (boxes && boxes.data && boxes.data.length > 0) {
      const sortedBoxes = sortByBoxDescription(boxes.data);

      setBoxes(sortedBoxes);
      setBoxOptions(
        sortedBoxes.map((box) => {
          return {
            key: box.boxId,
            text: box.name,
            value: box.boxId,
          };
        })
      );

      const categoryOptions = uniqBy(sortedBoxes, "category").map(
        (category) => {
          return {
            key: category.category,
            text: category.categoryName,
            value: category.category,
          };
        }
      );

      setCategoryOptions(sortByCategory(categoryOptions));
    }
  } finally {
    setLoading(false);
  }
};

export const getBoxCategories = async (setCategoryOptions, customerId) => {
  const boxes = await searchBoxes({ active: true, customerId }, 0);

  if (boxes && boxes.data && boxes.data.length > 0) {
    const sortedBoxes = sortByBoxDescription(boxes.data);

    const categoryOptions = uniqBy(sortedBoxes, "category").map((category) => {
      return {
        key: category.category,
        text: category.categoryName,
        value: category.category,
      };
    });

    setCategoryOptions(sortByCategory(categoryOptions));
  }
};

export const getExistingAdditionalApplications = async (
  setLoading,
  setApplications,
  customerId,
  salesPeriodId,
  selectedOrderType,
  setValidationErrors
) => {
  try {
    setLoading(true);

    const additionalApplications = await getBothNonItoAndItoAdditionalApplicationData(
      customerId,
      salesPeriodId,
      selectedOrderType
    );

    if (additionalApplications) {
      setApplications(additionalApplications);
    } else {
      setValidationErrors([
        "Ensure current sight has applicable application window",
      ]);
    }
  } finally {
    setLoading(false);
  }
};

export const updateAdditionalApplications = async (
  setLoading,
  customerId,
  salesPeriodId,
  nonItoAdditionalApplicationEntries
) => {
  try {
    setLoading(true);
    await upsertNonItoAdditionalApplication({
      customerId: customerId,
      salesPeriodId: salesPeriodId,
      nonItoAdditionalApplicationEntries: nonItoAdditionalApplicationEntries,
    });

    notifySuccess("Additional application request successfully saved");
  } finally {
    setLoading(false);
  }
};

export const updateServerFavourite = async (customerId, boxId, value) => {
  await updateServerFavouriteAsync(customerId, boxId, value);
};

export const getUserBoxFavouritesAsync = async (
  customerId,
  setFavourites,
  setOnlyShowFavourites
) => {
  const favourites = await getUserBoxFavourites(customerId);

  setFavourites(favourites);
  if (favourites?.length > 0) {
    setOnlyShowFavourites(true);
  } else {
    setOnlyShowFavourites(false);
  }
};
