import * as Unicons from "@iconscout/react-unicons";

import {
  ConfirmationModal,
  confirmationChoices,
} from "../../common/ConfirmationModal/ConfirmationModal";
import { Divider, Grid } from "semantic-ui-react";
import React, { useContext, useEffect, useState } from "react";
import {
  deleteSubmissionFile,
  downloadSubmission,
  downloadSubmissionTemplate,
  getFinancialComplianceInformation,
} from "./FinancialComplianceScripts";

import { IconButton } from "../../common/IconButton/IconButton";
import IndexPageTable from "../../common/IndexPageTable/IndexPageTable";
import { SelectedCustomerGroupContext } from "../../common/CustomerGroupSelector/CustomerGroupSelectorContext";
import { StandardSmallHeading } from "../../common/StandardSmallHeading/StandardSmallHeading";
import { StandardSubheading } from "../../common/StandardSubheading/StandardSubheading";
import { UploadTemplateModal } from "./UploadFile/UploadTemplateModal";
import { fetchCustomerGroup } from "./BankList.functions";
import moment from "moment";

export const FinancialCompliance = () => {
  const { selectedCustomerGroup } = useContext(SelectedCustomerGroupContext);
  const [tableData, setTableData] = useState([]);
  const [sortDirection, setSortDirection] = useState("descending");
  const [sortColumn, setSortColumn] = useState("");
  const [loading, setLoading] = useState(false);
  const [templateLoading, setTemplateLoading] = useState(false);

  const [uploadFileModalOpen, setUploadFileModalOpen] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [refreshSubmissions, setRefreshSubmissions] = useState({});
  const [uploadSubmissionWindowId, setUploadSubmissionWindowId] = useState();
  const [uploadFinancialDocumentType, setUploadFinancialDocumentType] =
    useState();
  const [uploadRequiredFileType, setUploadRequiredFileType] = useState();
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);
  const [confirmationText, setConfirmationText] = useState(false);
  const [deleteFinancialSubmissionId, setDeleteFinancialSubmissionId] =
    useState();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [customerGroup, setCustomerGroup] = useState();

  useEffect(() => {
    fetchCustomerGroup({
      setCustomerGroup,
      customerGroupId: selectedCustomerGroup,
    });
  }, [selectedCustomerGroup]);

  useEffect(() => {
    setSortColumn();

    if (selectedCustomerGroup) {
      getFinancialComplianceInformation(
        selectedCustomerGroup,
        setTableData,
        setLoading
      );
    }
  }, [selectedCustomerGroup, refreshSubmissions]);

  const fieldsToIgnore = [
    "FINANCIALSUBMISSIONID",
    "FINANCIALSUBMISSIONWINDOWID",
    "SUBMISSIONWINDOWSTARTDATE",
  ];

  const rendererFields = [
    "SUBMISSIONWINDOWENDDATE",
    "DOCUMENTNAME",
    "DATEUPLOADED",
    "UPLOADEDBY",
  ];

  const fieldNameMappings = {
    DOCUMENTTYPE: { name: "Document Type", order: 1 },
    SUBMISSIONWINDOWENDDATE: {
      name: "Submission Window",
      order: 2,
      render: (row) => {
        return (
          moment(row.submissionWindowStartDate).format("DD/MM/YYYY") +
          " - " +
          moment(row.submissionWindowEndDate).format("DD/MM/YYYY")
        );
      },
    },
    DOCUMENTNAME: {
      name: "Document Name",
      order: 3,
      render: (row) => {
        return row.documentName ?? "-";
      },
    },
    DATEUPLOADED: {
      name: "Date Uploaded",
      order: 4,
      render: (row) => {
        return row.dateUploaded ?? "-";
      },
    },
    UPLOADEDBY: {
      name: "Uploaded By",
      order: 5,
      render: (row) => {
        return row.uploadedBy ?? "-";
      },
    },
  };

  const onSortColumnChange = (column) => () => {
    if (column !== sortColumn) {
      setSortDirection("ascending");
      return;
    }

    setSortDirection(
      sortDirection === "ascending" ? "descending" : "ascending"
    );
  };

  const iconSize = 25;

  const actionPaths = {
    Upload: {
      onClick: (data) => {
        onUploadClick(data);
      },
      icon: <Unicons.UilUploadAlt size={iconSize} />,
      disabled: (data) => {
        return data.categoryLevelView;
      },
      loading: loadingUpload,
    },
    Download: {
      onClick: (data) => {
        downloadSubmission(data.financialSubmissionId, data.documentName);
      },
      icon: <Unicons.UilDownloadAlt size={iconSize} />,
      disabled: (data) => {
        return !data.dateUploaded;
      },
    },
    Remove: {
      onClick: (data) => {
        setDeleteFinancialSubmissionId(data.financialSubmissionId);
        setConfirmationText(
          `Are you sure you want to delete ${data.documentName}?`
        );
        setDeleteConfirmationModalOpen(true);
      },
      icon: <Unicons.UilMinusCircle size={iconSize} />,
      disabled: (data) => {
        return !data.dateUploaded;
      },
    },
  };

  const onUploadClick = async (data) => {
    await setUploadSubmissionWindowId(data.financialSubmissionWindowId);
    await setUploadFinancialDocumentType(data.documentType);
    await setUploadRequiredFileType(
      data.documentType === "Financial Workbook (Excel Version)"
        ? "excel"
        : "pdf"
    );

    setUploadFileModalOpen(true);
    setLoadingUpload(true);
  };

  const uploadComplete = () => {
    setUploadFileModalOpen(false);

    setLoadingUpload(false);
  };

  const refreshExplan = () => {
    setRefreshSubmissions({});
    uploadComplete();
  };

  const closeConfirmationModal = (confirmationChoice) => async () => {
    setDeleteConfirmationModalOpen(false);
    if (confirmationChoices.confirm === confirmationChoice) {
      await deleteSubmissionFile(deleteFinancialSubmissionId, setDeleteLoading);
      setRefreshSubmissions({});
    }
  };

  return (
    <React.Fragment>
      <StandardSubheading>
        <div className="flex justify-space-between">
          <span className="sub-heading-text">
            Financial Compliance Submissions:{" "}
            {customerGroup && customerGroup.name}
          </span>
        </div>
      </StandardSubheading>
      <Divider />

      <Grid>
        <Grid.Row>
          <Grid.Column width="6">
            <h4>
              Please upload the following documents to verify your financial
              compliance:
            </h4>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <p>
              <b>1. Financial Workbook(Excel version)</b> <br />
              Please upload a copy of the financial workbook that has been
              completed by the lead auditor of the applicant Group. Please note
              that only the original version of the excel template will be
              accepted. Any other workbooks or changes to the original will make
              your submission invalid.
            </p>
          </Grid.Column>
          <Grid.Column>
            <p>
              <b>2. Stamped Financial Workbook(PDF version)</b> <br />
              Please upload a PDF version of the Financial Workbook that has
              been completed, signed and officially stamped on each page by the
              lead auditor of the Applicant Group. The data in the .PDF file
              must exactly match that of the excel file. Any inconsistency will
              make this submission invalid by the Lead Auditor of the Applicant
              Group.
            </p>
          </Grid.Column>
          <Grid.Column>
            <p>
              <b>3. ‘IFRS Consolidation Group’ Organisational</b> <br />
              Structure (PDF) Please upload an organogram of your IFRS or US
              GAAP Consolidation Group Organisational Structure. All uploads
              should be in .PDF format, signed and officially stamped by the
              Lead Auditor of the Applicant Group.
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <StandardSmallHeading>
        <div className="flex justify-space-between">
          Documents
          <IconButton
            floated="right"
            primary
            Icon={Unicons.UilDownloadAlt}
            onClick={() => downloadSubmissionTemplate(setTemplateLoading, selectedCustomerGroup)}
            loading={templateLoading}
          >
            Download Financial Workbook Template
          </IconButton>
        </div>
      </StandardSmallHeading>

      <IndexPageTable
        tableData={tableData}
        fieldsToIgnore={fieldsToIgnore}
        fieldNameMappings={fieldNameMappings}
        actionIdField={"orderId"}
        actionPaths={actionPaths}
        onSortColumnChange={onSortColumnChange}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        searchFieldName={["Search"]}
        searchFieldPlaceholder="Search"
        loading={loading}
        showFilters={false}
        options={{ disablePagination: true }}
        rendererFields={rendererFields}
      />
      <UploadTemplateModal
        open={uploadFileModalOpen}
        closeModal={uploadComplete}
        setUpdateData={refreshExplan}
        financialSubmissionWindowId={uploadSubmissionWindowId}
        customerGroupId={selectedCustomerGroup}
        financialDocumentType={uploadFinancialDocumentType}
        requiredFileType={uploadRequiredFileType}
      />
      <ConfirmationModal
        open={deleteConfirmationModalOpen}
        closeModal={closeConfirmationModal}
        heading="Delete Financial Compliance Document"
        subHeading={confirmationText}
        loading={deleteLoading}
      />
    </React.Fragment>
  );
};
