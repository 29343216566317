import {
  getNotificationsForUser,
  getUnreadNotificationCountForUser,
  patchNotificationMarkAsRead,
  getNotificationTypesAsync,
} from "../../lib/apiNotifications";
import { notifyError, notifyInfo } from "../../lib/notifications";
import { getAllSalesPeriods } from "../../lib/apiSalesPeriod";
import { getSalesLocations } from "../../lib/apiOrderManagement";

export const fetchUnreadCount = async ({
  unreadCount,
  setUnreadCount,
  firstLoad,
}) => {
  const newUnreadCount = await getUnreadNotificationCountForUser();

  if (parseInt(unreadCount) !== parseInt(newUnreadCount)) {
    setUnreadCount(newUnreadCount);
    if (!firstLoad) {
      notifyInfo("You have new unread notifications");
    }
  }
};

export const fetchNotifications = async ({
  setLoading,
  setNotifications,
  setTotalNotifications,
  limit,
  searchFilter,
  typeFilter,
  locationFilter,
  salesPeriodFilter,
}) => {
  setLoading(true);

  try {
    const notifications = await getNotificationsForUser(
      limit,
      searchFilter,
      typeFilter,
      locationFilter,
      salesPeriodFilter
    );

    setTotalNotifications(notifications?.total);
    setNotifications(notifications?.data);
  } finally {
    setLoading(false);
  }
};

export const markNotificationAsRead = async ({
  notificationId,
  setNotification,
}) => {
  try {
    const notification = await patchNotificationMarkAsRead({ notificationId });

    setNotification(notification);
  } catch (err) {
    notifyError("Error while marking notification as read", err);
  }
};

export const getNotificationTypes = async (setNotificationTypes) => {
  const notificationTypesList = await getNotificationTypesAsync();

  if (notificationTypesList && notificationTypesList.length > 0) {
    setNotificationTypes(
      notificationTypesList.map((notificationType) => {
        return {
          name: notificationType.description,
          key: notificationType.notificationTypeId,
          text: notificationType.description,
          value: notificationType.notificationTypeId,
        };
      })
    );
  }
};

export const getSalesPeriods = async (setSalesPeriods) => {
  const allSalesPeriods = await getAllSalesPeriods();

  if (allSalesPeriods) {
    setSalesPeriods(
      allSalesPeriods.map((salesPeriod) => {
        return {
          name: salesPeriod.name,
          key: salesPeriod.salesPeriodId,
          text: salesPeriod.name,
          value: salesPeriod.salesPeriodId,
        };
      })
    );
  }
};

export const getSalesLocationsAsync = async (setSalesLocations) => {
  const allSalesLocations = await getSalesLocations();

  if (allSalesLocations?.data) {
    setSalesLocations(
      allSalesLocations.data.map((salesLocation) => {
        return {
          name: salesLocation.salesLocationName,
          key: salesLocation.salesLocationId,
          text: salesLocation.salesLocationName,
          value: salesLocation.salesLocationId,
        };
      })
    );
  }
};
