import { isEmpty, isNil } from "lodash";
import {
  fetchUserCustomerGroupPermissions,
  getBoxInsightUploadData,
} from "../../../../lib/apiBoxInsights";
import { getAllSalesPeriods } from "../../../../lib/apiSalesPeriod";
import { notifyError } from "../../../../lib/notifications";
import * as fileSaver from "file-saver";
import * as Excel from "exceljs/dist/exceljs";
import { getCustomerGroup } from "../../../../lib/apiOrderManagement";
import { getCurrentSalesPeriod } from "../../../../lib/apiSalesPeriod";

const percentNumberFormat = "#0%";

export const fetchSalesPeriods = async ({
  setSelectedSalesPeriod,
  setSalesPeriodsDropdownData,
}) => {
  const results = await getAllSalesPeriods();
  if (!isNil(results)) {
    const dropDownData = results.map((value) => {
      return {
        key: value.salesPeriodReference,
        value: value.salesPeriodId,
        text: value.name,
      };
    });

    const currentSalesPeriod =  await getCurrentSalesPeriod();

    setSelectedSalesPeriod({
      key: currentSalesPeriod.salesPeriodReference,
      value: currentSalesPeriod.salesPeriodId,
      text: currentSalesPeriod.name
    });
    setSalesPeriodsDropdownData(dropDownData);
    return;
  }
  setSalesPeriodsDropdownData([]);
};

export const fetchCustomerGroup = async ({
  setCustomerGroup,
  customerGroupId,
  setLoading,
  setHasBoxInsightsPermission,
}) => {
  if (customerGroupId) {
    const hasPermission = await fetchUserCustomerGroupPermissions(
      customerGroupId
    );
    setHasBoxInsightsPermission(hasPermission);
    const customerGroup = await getCustomerGroup(customerGroupId);
    setCustomerGroup(customerGroup);

    return customerGroup;
  }
};

export const downloadBoxInsightUploadFile = async ({
  customerGroupId,
  salesPeriodId,
  customerGroupName,
  salesPeriodName,
  setLoading,
}) => {
  try {
    setLoading(true);

    var reportData = await getBoxInsightUploadData({
      customerGroupId,
      salesPeriodId,
    });
    if (!isNil(reportData) && !isEmpty(reportData)) {
      generateExcelReport(reportData, { customerGroupName, salesPeriodName });
    }
  } catch (error) {
    notifyError("Failed to compile box insights report.", error);
  } finally {
    setLoading(false);
  }
};

const populateBoxInsightsReportExcelTitle = (worksheet, boxInsightsInfo) => {
  worksheet.getCell("A1").value = "Box Insights Report";
  worksheet.getCell("A3").value = "Sightholder Group:";
  worksheet.getCell("A4").value = "Sight:";
  worksheet.getCell("A5").value = "";

  worksheet.getCell("B3").value = boxInsightsInfo.customerGroupName;
  worksheet.getCell("B4").value = boxInsightsInfo.salesPeriodName;

  var cellsToStyle = ["A1", "A3", "A4", "A5", "B3", "B4"];

  styleTitleCell(worksheet, cellsToStyle);
};

const styleTitleCell = (worksheet, cells) => {
  cells.forEach((cell) => {
    worksheet.getCell(cell).font = {
      name: "Franklin Gothic Book",
      size: 14,
      bold: true,
    };
  });
};

const populateBoxInsightsReportExcelHeader = (worksheet) => {
  worksheet.columns = [
    { key: "customerName", width: 30 },
    { key: "boxNumber", width: 20 },
    { key: "boxDescription", width: 40 },
    { key: "size", width: 20 },
    { key: "dataType", width: 25 },
    { key: "attributeDesc", width: 30 },
    { key: "percentage", width: 20 },
  ];

  worksheet.addRow(8).values = [
    "Customer Name",
    "Box Number",
    "Sales Box Group Desc",
    "Size",
    "Data Type",
    "Attribute Desc",
    "%($)",
  ];

  applyHeaderStyleToCurrentRow(worksheet);
};

const populateBoxInsightsReportExcelBody = (worksheet, data) => {
  worksheet.getColumn(6).numFmt = percentNumberFormat;
  data.forEach((entry) => {
    worksheet.addRow({
      customerName: entry.customer.customerName,
      boxNumber: entry.boxNumber,
      boxDescription: entry.box.name,
      size: entry.size,
      dataType: entry.dataType,
      attributeDesc: entry.attributeDescription,
      percentage: entry.dollarValue,
    }).font = {
      name: "Segoe UI",
      size: 9,
      bold: entry.attributeDescription === "Total",
    };
  });
};

const applyHeaderStyleToCurrentRow = (worksheet) => {
  for (
    let currentColumn = 1;
    currentColumn <= worksheet.columnCount;
    currentColumn++
  ) {
    worksheet.getCell(worksheet.rowCount, currentColumn).font = {
      name: "Franklin Gothic Book",
      size: 10,
      bold: true,
      color: { argb: "FFFFFF" },
    };

    worksheet.getCell(worksheet.rowCount, currentColumn).fill = {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "172F4A" },
      fgColor: { argb: "172F4A" },
    };

    worksheet.getCell(worksheet.rowCount, currentColumn).alignment = {
      horizontal: "center",
    };
  }
};

const downloadWorkbook = (workbook) => {
  workbook.xlsx.writeBuffer().then((data) => {
    fileSaver.saveAs(
      new Blob([data]),
      "BoxInsights.xlsx",
      { autoBom: false },
      true
    );
  });
};

export const generateExcelReport = (data, boxInsightsInfo) => {
  const workbook = new Excel.Workbook("BoxInsights");
  const worksheet = workbook.addWorksheet("BoxInsights", {
    views: [{ state: "frozen", ySplit: 6 }],
  });

  populateBoxInsightsReportExcelTitle(worksheet, boxInsightsInfo);
  populateBoxInsightsReportExcelHeader(worksheet);
  populateBoxInsightsReportExcelBody(worksheet, data);

  worksheet.unprotect();
  downloadWorkbook(workbook);
};
