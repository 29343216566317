import {
  deleteManufacturingSubmission,
  downloadManufacturingSubmissionTemplate,
  downloadSingleSubmission,
  getCustomerGroupPermissions,
  getManufacturingSubmissions,
  searchManufacturingSubmissions,
  uploadManufacturingSubmissions,
  uploadManufacturingSubmissionsOverwrite,
} from "../../../../lib/apiManufacturingData";

import { applicationWindowTypes } from "../../../../lib/enums";
import { filter } from "ramda";
import { getCustomerGroup } from "../../../../lib/apiOrderManagement";
import moment from "moment";
import { validateManufacturingDataSubmission } from "./ManufacturingData.validations";

export const deleteSubmission = async ({
  submissionId,
  tableData,
  setTableData,
}) => {
  const result = await deleteManufacturingSubmission({ submissionId });
  const deletedItemFilter = filter(
    (x) => x.manufacturingSubmissionId !== result.manufacturingSubmissionId
  );

  setTableData(deletedItemFilter(tableData));
};

export const fetchCustomerGroup = async ({
  setCustomerGroup,
  customerGroupId,
  setLoading,
  setHasManufacturingPermission,
}) => {
  if (customerGroupId) {
    await fetchCustomerGroupPermissions({
      customerGroupId,
      setLoading,
      setHasManufacturingPermission,
    });
    const customerGroup = await getCustomerGroup(customerGroupId);
    setCustomerGroup(customerGroup);

    return customerGroup;
  }
};

export const fetchCustomerGroupPermissions = async ({
  customerGroupId,
  setLoading,
  setHasManufacturingPermission,
}) => {
  setLoading(true);
  setHasManufacturingPermission(false);
  if (customerGroupId) {
    const customerGroupPermissions = await getCustomerGroupPermissions({
      customerGroupId,
    });
    setHasManufacturingPermission(customerGroupPermissions);
    setLoading(false);
  }
};

export const getCurrentSightManufacturingDataSubmissions = async ({
  setLoading,
  setTableData,
  salesPeriod,
  customerGroup,
}) => {
  const salesPeriodId = salesPeriod.salesPeriodId;
  const customerGroupId = customerGroup.customerGroupId;

  const result = await getManufacturingSubmissions({
    limit: 0,
    skip: 0,
    salesPeriodId,
    customerGroupId,
  });

  const mappedTableValues = result?.data?.map((result) => ({
    manufacturingSubmissionId: result.manufacturingSubmissionId,
    salesPeriodName: result.salesPeriodName,
    customerGroup: result.customerGroupName,
    customerName: result.customerName ? result.customerName : "All Customers",
    documentName: result.documentName,
    dateCreated: result.dateCreated,
    uploadedBy: result.uploadedByUser,
  }));

  setTableData(mappedTableValues);
  setLoading(false);
};

export const searchPreviousSightManufacturingDataSubmissions = async ({
  setLoading,
  setTableData,
  currentSalesPeriod,
  customerGroup,
  pageSize,
  pageNumber,
  setTotalResults,
  searchString,
}) => {
  const salesPeriodId = currentSalesPeriod.salesPeriodId;
  const customerGroupId = customerGroup.customerGroupId;

  const result = await searchManufacturingSubmissions({
    limit: pageSize,
    skip: pageNumber,
    salesPeriodId,
    customerGroupId,
    searchString,
  });

  const mappedTableValues = result?.data.map((result) => ({
    manufacturingSubmissionId: result.manufacturingSubmissionId,
    salesPeriodName: result.salesPeriodName,
    customerGroup: result.customerGroupName,
    customerName: result.customerName ? result.customerName : "All Customers",
    documentName: result.documentName,
    dateCreated: result.dateCreated,
    uploadedBy: result.uploadedByUser,
  }));

  setTotalResults(result?.total);
  setTableData(mappedTableValues);
  setLoading(false);
};

const defaultIfEmpty = (value, defaultValue) => {
  return value ? value : defaultValue;
};

const prepareSubmissionData = (submissionData) => {
  const copiedSubmissionData = JSON.parse(JSON.stringify(submissionData));
  const formattedSubmissionData = copiedSubmissionData.map((data) => {
    data.numberOfRoughStones = defaultIfEmpty(data.numberOfRoughStones, 0);
    data.owned = defaultIfEmpty(data.owned, 0);
    data.contracted = defaultIfEmpty(data.contracted, 0);
    data.startDate = moment(data.startDate).format("DD/MM/YYYY hh:mm:ss");
    data.endDate = data.endDate ? moment(data.endDate).format("DD/MM/YYYY hh:mm:ss") : data.endDate;
    data.roughRejectionWeight = defaultIfEmpty(data.roughRejectionWeight, 0);
    data.makeableRejectionsWeight = defaultIfEmpty(data.makeableRejectionsWeight, 0);
    data.weightAfterSawing = defaultIfEmpty(data.weightAfterSawing, 0);
    data.weightAfterBrutingOrConing = defaultIfEmpty(data.weightAfterBrutingOrConing, 0);
    data.weightAfterBlocking = defaultIfEmpty(data.weightAfterBlocking, 0);
    data.finalPolishedWeight = defaultIfEmpty(data.finalPolishedWeight, 0);
    data.numberOfPolishedStones = defaultIfEmpty(data.numberOfPolishedStones, 0);
    data.polishedCtsSingle = defaultIfEmpty(data.polishedCtsSingle, 0);
    data.polishedCtsRound = defaultIfEmpty(data.polishedCtsRound, 0);
    data.polishedCtsPear = defaultIfEmpty(data.polishedCtsPear, 0);
    data.polishedCtsCushion = defaultIfEmpty(data.polishedCtsCushion, 0);
    data.polishedCtsPrincess = defaultIfEmpty(data.polishedCtsPrincess, 0);
    data.polishedCtsHeart = defaultIfEmpty(data.polishedCtsHeart, 0);
    data.polishedCtsOval = defaultIfEmpty(data.polishedCtsOval, 0);
    data.polishedCtsMarquise = defaultIfEmpty(data.polishedCtsMarquise, 0);
    data.polishedCtsEmerald = defaultIfEmpty(data.polishedCtsEmerald, 0);
    data.polishedCtsRadiant = defaultIfEmpty(data.polishedCtsRadiant, 0);
    data.polishedCtsProprietaryOrBranded = defaultIfEmpty(data.polishedCtsProprietaryOrBranded, 0);
    data.polishedCtsOther = defaultIfEmpty(data.polishedCtsOther, 0);
    data.polishedCtsBelow0_01 = defaultIfEmpty(data.polishedCtsBelow0_01, 0);
    data.polishedCts0_01to0_03 = defaultIfEmpty(data.polishedCts0_01to0_03, 0);
    data.polishedCts0_04to0_07 = defaultIfEmpty(data.polishedCts0_04to0_07, 0);
    data.polishedCts0_08to0_14 = defaultIfEmpty(data.polishedCts0_08to0_14, 0);
    data.polishedCts0_15to0_17 = defaultIfEmpty(data.polishedCts0_15to0_17, 0);
    data.polishedCts0_18to0_22 = defaultIfEmpty(data.polishedCts0_18to0_22, 0);
    data.polishedCts0_23to0_29 = defaultIfEmpty(data.polishedCts0_23to0_29, 0);
    data.polishedCts0_3to0_39 = defaultIfEmpty(data.polishedCts0_3to0_39, 0);
    data.polishedCts0_4to0_49 = defaultIfEmpty(data.polishedCts0_4to0_49, 0);
    data.polishedCts0_5to0_69 = defaultIfEmpty(data.polishedCts0_5to0_69, 0);
    data.polishedCts0_7to0_89 = defaultIfEmpty(data.polishedCts0_7to0_89, 0);
    data.polishedCts0_9to0_99 = defaultIfEmpty(data.polishedCts0_9to0_99, 0);
    data.polishedCts1to1_49 = defaultIfEmpty(data.polishedCts1to1_49, 0);
    data.polishedCts1_5to1_99 = defaultIfEmpty(data.polishedCts1_5to1_99, 0);
    data.polishedCts2to2_99 = defaultIfEmpty(data.polishedCts2to2_99, 0);
    data.polishedCts3to3_99 = defaultIfEmpty(data.polishedCts3to3_99, 0);
    data.polishedCts4to4_99 = defaultIfEmpty(data.polishedCts4to4_99, 0);
    data.polishedCts5to9_99 = defaultIfEmpty(data.polishedCts5to9_99, 0);
    data.polishedCtsAbove10 = defaultIfEmpty(data.polishedCtsAbove10, 0);
    data.polishedCtsEx = defaultIfEmpty(data.polishedCtsEx, 0);
    data.polishedCtsVg = defaultIfEmpty(data.polishedCtsVg, 0);
    data.polishedCtsGd = defaultIfEmpty(data.polishedCtsGd, 0);
    data.polishedCtsFr = defaultIfEmpty(data.polishedCtsFr, 0);
    data.polishedCtsPr = defaultIfEmpty(data.polishedCtsPr, 0);
    data.polishedCtsFcy = defaultIfEmpty(data.polishedCtsFcy, 0);
    data.polishedCtsD = defaultIfEmpty(data.polishedCtsD, 0);
    data.polishedCtsE = defaultIfEmpty(data.polishedCtsE, 0);
    data.polishedCtsF = defaultIfEmpty(data.polishedCtsF, 0);
    data.polishedCtsG = defaultIfEmpty(data.polishedCtsG, 0);
    data.polishedCtsH = defaultIfEmpty(data.polishedCtsH, 0);
    data.polishedCtsI = defaultIfEmpty(data.polishedCtsI, 0);
    data.polishedCtsJ = defaultIfEmpty(data.polishedCtsJ, 0);
    data.polishedCtsK = defaultIfEmpty(data.polishedCtsK, 0);
    data.polishedCtsL = defaultIfEmpty(data.polishedCtsL, 0);
    data.polishedCtsM = defaultIfEmpty(data.polishedCtsM, 0);
    data.polishedCtsN = defaultIfEmpty(data.polishedCtsN, 0);
    data.polishedCtsO = defaultIfEmpty(data.polishedCtsO, 0);
    data.polishedCtsP = defaultIfEmpty(data.polishedCtsP, 0);
    data.polishedCtsBelowQ = defaultIfEmpty(data.polishedCtsBelowQ, 0);
    data.polishedCtsFancy = defaultIfEmpty(data.polishedCtsFancy, 0);
    data.polishedCtsTtlc = defaultIfEmpty(data.polishedCtsTtlc, 0);
    data.polishedCtsTlc = defaultIfEmpty(data.polishedCtsTlc, 0);
    data.polishedCtsLc = defaultIfEmpty(data.polishedCtsLc, 0);
    data.polishedCtsCl = defaultIfEmpty(data.polishedCtsCl, 0);
    data.polishedCtsDc = defaultIfEmpty(data.polishedCtsDc, 0);
    data.polishedCtsTtlb = defaultIfEmpty(data.polishedCtsTtlb, 0);
    data.polishedCtsTlb = defaultIfEmpty(data.polishedCtsTlb, 0);
    data.polishedCtsLb = defaultIfEmpty(data.polishedCtsLb, 0);
    data.polishedCtsBr = defaultIfEmpty(data.polishedCtsBr, 0);
    data.polishedCtsDb = defaultIfEmpty(data.polishedCtsDb, 0);
    data.polishedCtsFl = defaultIfEmpty(data.polishedCtsFl, 0);
    data.polishedCtsIf = defaultIfEmpty(data.polishedCtsIf, 0);
    data.polishedCtsVvs1 = defaultIfEmpty(data.polishedCtsVvs1, 0);
    data.polishedCtsVvs2 = defaultIfEmpty(data.polishedCtsVvs2, 0);
    data.polishedCtsVs1 = defaultIfEmpty(data.polishedCtsVs1, 0);
    data.polishedCtsVs2 = defaultIfEmpty(data.polishedCtsVs2, 0);
    data.polishedCtsSi1 = defaultIfEmpty(data.polishedCtsSi1, 0);
    data.polishedCtsSi2 = defaultIfEmpty(data.polishedCtsSi2, 0);
    data.polishedCtsSi3 = defaultIfEmpty(data.polishedCtsSi3, 0);
    data.polishedCtsI1 = defaultIfEmpty(data.polishedCtsI1, 0);
    data.polishedCtsI2 = defaultIfEmpty(data.polishedCtsI2, 0);
    data.polishedCtsI3 = defaultIfEmpty(data.polishedCtsI3, 0);
    data.ags = defaultIfEmpty(data.ags, 0);
    data.hrd = defaultIfEmpty(data.hrd, 0);
    data.gia = defaultIfEmpty(data.gia, 0);
    data.igi = defaultIfEmpty(data.igi, 0);
    data.gsi = defaultIfEmpty(data.gsi, 0);
    data.dbgis = defaultIfEmpty(data.dbgis, 0);
    data.other = defaultIfEmpty(data.other, 0);

    return data;
  });

  return formattedSubmissionData;
};

export const uploadManufacturingDataSubmissionOverwrite = async ({
  customerId,
  salesPeriodId,
  customerGroupId,
  documentName,
  submissionData,
  setShowUploadModal,
  setLoadingSubmissionUpload,
  onModalSubmit,
  setLineValidationErrors,
  fileData,
  setUploadingSubmissionModal
}) => {
  setUploadingSubmissionModal(true)
  setLoadingSubmissionUpload(true);
  const formattedSubmissionData = prepareSubmissionData(submissionData);
  const errors = validateManufacturingDataSubmission(formattedSubmissionData);
  const submission = {
    customerGroupId,
    customerId: customerId !== -1 ? customerId : null,
    salesPeriodId,
    documentName,
    manufacturingSubmissionData: formattedSubmissionData,
  };

  if (errors.length === 0) {
    await uploadManufacturingSubmissionsOverwrite(submission, fileData);
    setShowUploadModal(false);
    onModalSubmit();
  } else {
    setLineValidationErrors(errors);
  }

  setUploadingSubmissionModal(false)
  setLoadingSubmissionUpload(false);
};

export const uploadManufacturingDataSubmission = async ({
  customerId,
  salesPeriodId,
  customerGroupId,
  documentName,
  submissionData,
  setShowUploadModal,
  setLoadingSubmissionUpload,
  setCanOverwrite,
  onModalSubmit,
  setLineValidationErrors,
  fileData,
  setUploadingSubmissionModal
}) => {
  setUploadingSubmissionModal(true)
  setLoadingSubmissionUpload(true);
  const formattedSubmissionData = prepareSubmissionData(submissionData);
  const errors = validateManufacturingDataSubmission(formattedSubmissionData);
  const submission = {
    customerGroupId,
    customerId: customerId !== -1 ? customerId : null,
    salesPeriodId,
    documentName,
    manufacturingSubmissionData: formattedSubmissionData,
  };

  if (errors.length === 0) {
    var alreadyExists = await uploadManufacturingSubmissions(
      submission,
      fileData
    );
    if (alreadyExists === true) {
      setCanOverwrite(true);
    } else {
      setShowUploadModal(false);
      onModalSubmit();
    }
  } else {
    setLineValidationErrors(errors);
  }

  setUploadingSubmissionModal(false)
  setLoadingSubmissionUpload(false);
};

export const generateManufacturingDataTemplate = async ({
  setLoadingTemplateDownload,
}) => {
  setLoadingTemplateDownload(true);
  await downloadManufacturingSubmissionTemplate();
  setLoadingTemplateDownload(false);
};

export const downloadManufacturingDataSubmission = async ({
  submissionId,
  fileName,
}) => {
  await downloadSingleSubmission({
    submissionId,
    fileName,
  });
};

export const checkIfManufacturingWindowIsOpen = ({
  currentSalesPeriod,
  setIsManufacturingWindowOpen,
}) => {
  setIsManufacturingWindowOpen(
    currentSalesPeriod &&
      currentSalesPeriod.currentApplicationWindows &&
      Array.isArray(currentSalesPeriod.currentApplicationWindows) &&
      currentSalesPeriod.currentApplicationWindows.some(
        (applicationWindow) =>
          applicationWindow.salesPeriodID ===
            currentSalesPeriod.salesPeriodId &&
          applicationWindow.applicationWindowTypeId ===
            applicationWindowTypes.manufacturingDataSubmission
      )
  );
};
