import React from "react";
import "./Popup.scss";
import { Popup as SemanticPopup } from "semantic-ui-react";

export const Popup = ({ children, Icon, iconClassNames, ...props }) => (
  <SemanticPopup
    {...props}
    trigger={
      <Icon size={25} className={[iconClassNames, "popup-icon"].join(" ")} />
    }
  >
    {children}
  </SemanticPopup>
);
