import { notifyError, notifySuccess } from "../lib/notifications";

import { createAxios } from "./axiosWrapper";

const applicationServiceUrl = `${process.env.REACT_APP_CUSTOMER_INTEGRATION_URL}/api/`;

const applicationAxios = () => createAxios(applicationServiceUrl);

export const submitRequest = async (data) => {
  try {
    const explanRequestData = [data];

    const boxCategoryData = await applicationAxios().post(
      "application/create-explan-request",
      explanRequestData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return boxCategoryData.data;
  } catch (err) {
    notifyError("Failed to submit Ex-Plan request.", err);
  }
};

export const editExplanApplication = async (explanRequestData) => {
  try {
    const boxCategoryData = await applicationAxios().post(
      "application/create-explan-request",
      explanRequestData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return boxCategoryData.data;
  } catch (err) {
    notifyError("Failed to update Ex-Plan request.", err);
  }
};

export const getMobileExPlanApplications = async (
  salesPeriodId,
  customerId,
  orderTypeId,
  setLoadingBoxCategoryData,
  setExPlanData
) => {
  try {
    setLoadingBoxCategoryData(true);
    const routeSuffix =
      "box/get-box-categories-mobile?customerId=" +
      customerId +
      "&salesPeriod=" +
      salesPeriodId +
      "&orderTypeId=" +
      orderTypeId;
    const applicationResults = await applicationAxios().get(routeSuffix);
    const applicationsResultData = applicationResults.data;

    setExPlanData(applicationsResultData);
  } catch (err) {
    notifyError("Failed to get Ex-Plan request(s)", err);
  } finally {
    setLoadingBoxCategoryData(false);
  }
};

export const getExPlanApplications = async (
  salesPeriodId,
  customerId,
  orderTypeId,
  setLoadingBoxCategoryData,
  setExPlanData
) => {
  try {
    setLoadingBoxCategoryData(true);
    const routeSuffix =
      "box/get-box-categories?customerId=" +
      customerId +
      "&salesPeriod=" +
      salesPeriodId +
      "&orderTypeId=" +
      orderTypeId;
    const applicationResults = await applicationAxios().get(routeSuffix);
    const applicationsResultData = applicationResults.data;
    setExPlanData(applicationsResultData);
  } catch (err) {
    notifyError("Failed to get Ex-Plan request(s)", err);
  } finally {
    setLoadingBoxCategoryData(false);
  }
};

export const cancelExplanApplication = async (
  customerId,
  salesPeriodId,
  orderTypeId
) => {
  try {
    await applicationAxios().delete(
      "application/cancel-explan-request?salesPeriodId=" +
        salesPeriodId +
        "&customerId=" +
        customerId +
        "&orderTypeId=" +
        orderTypeId
    );

    notifySuccess(
      "Ex-Plan Request Successfully Cancelled",
      "Changes applied to Ex-Plan requests."
    );
  } catch (err) {
    notifyError("Failed to cancel Ex-Plan application.", err);
  }
};

export const exportExplanApplication = async (
  customerId,
  salesPeriodId,
  orderTypeId
) => {
  try {
    const result = await applicationAxios().get(
      "application/ex-plan-export?salesPeriodId=" +
        salesPeriodId +
        "&customerId=" +
        customerId +
        "&orderTypeId=" +
        orderTypeId
    );
    return result.data;
  } catch (err) {
    notifyError("Failed to cancel Ex-Plan application.", err);
  }
};
export const importExplanApplication = async (
  customerId,
  salesPeriodId,
  orderTypeId,
  explanImportEntries
) => {
  try {
    const response = await applicationAxios().post(
      "application/ex-plan-import",
      {
        customerId: customerId,
        salesPeriodId: salesPeriodId,
        orderTypeId: orderTypeId,
        explanImportEntries: explanImportEntries,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (err) {
    notifyError("Failed to import Ex-Plan request.", err);
  }
};
