import "./Footer.scss";

import { Container, Grid } from "semantic-ui-react";

import React from "react";

export const Footer = () => {
  const today = new Date();

  return (
    <Container className="site-footer">
      <Grid className="site-footer-content">
        <Grid.Row>
          <Grid.Column>
            <h5>Group Websites:</h5>
            <a
              href={`https://www.debeersgroup.com/`}
              target="_blank"
              rel="noreferrer"
            >
              De Beers Group
            </a>
            &nbsp;|&nbsp;
            <a
              href={`https://www.debeersgroup.com/about-us/our-operations/sales`}
              target="_blank"
              rel="noreferrer"
            >
              Auctions
            </a>
            &nbsp;|&nbsp;
            <a
              href={`http://canada.debeersgroup.com/en/index.html`}
              target="_blank"
              rel="noreferrer"
            >
              De Beers Canada
            </a>
            &nbsp;|&nbsp;
            <a
              href={`http://www.debeers.com/`}
              target="_blank"
              rel="noreferrer"
            >
              De Beers Jewellers
            </a>
            &nbsp;|&nbsp;
            <a
              href={`http://www.diamondroute.com/`}
              target="_blank"
              rel="noreferrer"
            >
              Diamond Route
            </a>
            &nbsp;|&nbsp;
            <a href={`https://www.e6.com/`} target="_blank" rel="noreferrer">
              Element Six
            </a>
            &nbsp;|&nbsp;
            <a
              href={`http://www.forevermark.com/`}
              target="_blank"
              rel="noreferrer"
            >
              Forevermark
            </a>
            &nbsp;|&nbsp;
            <a href={`https://gemfair.com/`} target="_blank" rel="noreferrer">
              GemFair
            </a>
            &nbsp;|&nbsp;
            <a
              href={`http://www.debeersgroupservices.com/`}
              target="_blank"
              rel="noreferrer"
            >
              Industry Services
            </a>
            &nbsp;|&nbsp;
            <a
              href={`http://www.debeersgroupinstitute.com/`}
              target="_blank"
              rel="noreferrer"
            >
              Institute of Diamonds
            </a>
            &nbsp;|&nbsp;
            <a
              href={`http://www.debtech.com/`}
              target="_blank"
              rel="noreferrer"
            >
              Technology
            </a>
            &nbsp;|&nbsp;
            <a href={`https://www.tracr.com/`} target="_blank" rel="noreferrer">
              Tracr
            </a>
            &nbsp;|&nbsp;
            <a
              href={`https://lightboxjewelry.com/`}
              target="_blank"
              rel="noreferrer"
            >
              Lightbox
            </a>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <p>
              De Beers UK Limited member of the Anglo American plc group{" "}
              {today.getFullYear()}
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <a href="/cookies">Our Use of Cookies</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <a href="/termsconditions">Terms and Conditions</a>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};
