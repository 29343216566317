import React, { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { SpsLoginContext } from "./SpsLoginContext";
import {
  clearAuthStorage,
  handleOktaToken,
  setUserNameCache,
  isLoggedIn,
} from "../../lib/authentication";
import { LoaderComponent } from "../../common/LoaderComponent";
import { useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";

const showLoader = (authState, spsLogin) => {
  return (
    authState.isPending ||
    spsLogin.isPending ||
    (authState.isAuthenticated && !spsLogin.isLoggedIn)
  );
};

const checkIfShouldTriggerOktaLogout = async (
  authState,
  timer,
  cycles,
  setCycles,
  oktaAuth
) => {
  if (authState.isPending && cycles > 2) {
    clearInterval(timer);
    await oktaAuth.signOut();
  } else {
    setCycles((prevState) => prevState + 1);
  }
};

const oktaSetLoggedInAsync = async (
  accessToken,
  setSpsLogin,
  setLoginException
) => {
  setSpsLogin({ isLoggedIn: false, isPending: true });
  try {
    setLoginException(false);
    await handleOktaToken(accessToken.accessToken);
    if (isLoggedIn()) {
      await setUserNameCache();
      setSpsLogin({ isLoggedIn: true, isPending: false });
    }
  } catch {
    setSpsLogin({ isLoggedIn: false, isPending: false });
    setLoginException(true);
  }
};

const SpsLogin = ({ children }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [loginException, setLoginException] = useState(false);
  const [cycles, setCycles] = useState(0);
  const [spsLogin, setSpsLogin] = useState({
    isLoggedIn: isLoggedIn(),
    isPending: !isLoggedIn() && authState.isAuthenticated,
  });
  const isLoggedInValue = {
    spsLogin: spsLogin,
    setSpsLogin: setSpsLogin,
  };
  const location = useLocation();

  useEffect(() => {
    if (!authState.isAuthenticated && !authState.isPending) {
      setSpsLogin({ isLoggedIn: false, isPending: false });
      clearAuthStorage();
    } else if (
      !spsLogin.isLoggedIn &&
      authState.isAuthenticated &&
      !authState.isPending
    ) {
      oktaSetLoggedInAsync(
        authState.accessToken,
        setSpsLogin,
        setLoginException
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  useEffect(() => {
    if (showLoader(authState, spsLogin)) {
      const timer = setInterval(() => {
        checkIfShouldTriggerOktaLogout(
          authState,
          timer,
          cycles,
          setCycles,
          oktaAuth
        );
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [authState, spsLogin, cycles, oktaAuth]);

  if (loginException) {
    return <Redirect to="/logout" />;
  }

  if (location.pathname === "/logout") {
    return null;
  }

  if (showLoader(authState, spsLogin)) {
    return (
      <div>
        <LoaderComponent loading={true} message={"Busy logging in..."} />
      </div>
    );
  }

  return (
    <SpsLoginContext.Provider value={isLoggedInValue}>
      {children}
    </SpsLoginContext.Provider>
  );
};

export default SpsLogin;
