import React, { useState, useEffect } from "react";
import {
  Header,
  Modal,
  Grid,
  Button,
  Table,
  Divider,
  Message,
} from "semantic-ui-react";
import { uploadFile, validateFileType } from "../FinancialComplianceScripts";
import { head } from "ramda";
import * as Unicons from "@iconscout/react-unicons/";
import { notifySuccess } from "./../../../lib/notifications";

export const UploadTemplateModal = ({
  open,
  closeModal,
  setUpdateData,
  financialSubmissionWindowId,
  customerGroupId,
  financialDocumentType,
  requiredFileType,
}) => {
  const [errors, setErrors] = useState([]);
  const [file, setFile] = useState(null);
  const [statusError, setStatusError] = useState(null);
  const [loadingUpload, setLoadingUpload] = useState(false);

  useEffect(() => {
    if (errors && errors.length > 0) {
      setStatusError(
        "Upload status: Upload failed due to validation errors, please upload a new file and try again."
      );
    }
  }, [errors, setStatusError]);

  const onUploadClick = async () => {
    document.getElementById("upload-select").click();
  };

  const handleFileSelect = async (event) => {
    try {
      if (event.target.files && event.target.files.length > 0) {
        setErrors([]);
        const _file = head(event.target.files);
        await validateFileType({
          file: _file,
          setError: setStatusError,
          filetype: requiredFileType,
        });
        setFile(_file);
      }
    } finally {
      document.getElementById("upload-select").value = null;
    }
  };

  const uploadComplete = async (isSuccessful) => {
    if (isSuccessful) {
      notifySuccess("Your upload was successful");
    }
    setErrors([]);
    setFile(null);
    setUpdateData(true);
    closeModal();
  };

  const handleUpload = async () => {
    try {
      setErrors([]);
      await uploadFile({
        file,
        financialSubmissionWindowId,
        customerGroupId,
        financialDocumentType,
        setErrors: setErrors,
        uploadComplete: uploadComplete,
        setLoading: setLoadingUpload
      });
    } finally {
      document.getElementById("upload-select").value = null;
    }
  };

  return (
    <Modal
      open={open}
      closeIcon
      onClose={closeModal}
      size={"large"}
      className="download-bulk-pds-template-modal"
    >
      <Modal.Content>
        <Modal.Description>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header>Upload a new file</Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <input
                  id="upload-select"
                  type="file"
                  hidden
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf"
                  onChange={handleFileSelect}
                />
                <Button
                  primary
                  onClick={onUploadClick}
                  icon
                  labelPosition="right"
                >
                  Add a new file
                  <Unicons.UilPlusCircle size={12} className={"icon"} />
                </Button>
                <Divider>
                  {file && (
                    <Message visible warning={!!statusError}>
                      <Unicons.UilFileBlank size={24} />
                      {file && file.name}
                      {statusError ? (
                        <Unicons.UilExclamationCircle
                          size={24}
                          className="float-right warning"
                        />
                      ) : (
                        <Unicons.UilCheckCircle
                          size={24}
                          className="float-right success"
                        />
                      )}
                    </Message>
                  )}
                </Divider>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                {statusError && (
                  <Message negative>
                    <Message.Header>{statusError}</Message.Header>
                  </Message>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                {errors && errors.length > 0 && (
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Line no.</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell>Column</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {errors.map((error, index) => {
                        return (
                          <Table.Row key={index}>
                            <Table.Cell>
                              {error.lineNumber ? error.lineNumber : "-"}
                            </Table.Cell>
                            <Table.Cell>{error.columnName}</Table.Cell>
                            <Table.Cell>{error.description}</Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                {
                  <Button
                    primary
                    floated="right"
                    onClick={handleUpload}
                    disabled={loadingUpload || !file || !!statusError}
                    loading={loadingUpload}
                  >
                    Upload
                  </Button>
                }
                <Button
                  basic
                  color="black"
                  floated="right"
                  onClick={closeModal}
                >
                  Cancel
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default UploadTemplateModal;
