import moment from "moment";
import { handleOktaToken, getToken, logout } from "../../lib/authentication";
import jwt from "jsonwebtoken";

export const handleOktaTokenRefreshAsync = async (
  oktaTokenRefreshTimeout,
  authState,
  oktaAuth,
  setOktaRefreshTrigger
) => {
  let milliSecondsLeftUntilExpiry = 5000;

  if (authState?.accessToken?.accessToken) {
    milliSecondsLeftUntilExpiry = getTokenExpiry(
      authState.accessToken.accessToken
    );
  }

  oktaAuth.authStateManager.subscribe(() => {});
  await oktaAuth.authStateManager.updateAuthState();

  //fall back logic if no refresh token is pass from Okta
  if (authState.isAuthenticated && !authState.refreshToken?.refreshToken) {
    const accessToken = oktaAuth.getAccessToken();

    const session = await oktaAuth.session.get();

    if (accessToken && session.status !== "INACTIVE") {
      milliSecondsLeftUntilExpiry = getTokenExpiry(accessToken);

      //https://github.com/okta/okta-oidc-js/issues/815
      //https://github.com/okta/okta-oidc-js/issues/744
      /*
        Since upgrading to v 4 this might no longer be an issue?
        using authService._oktaAuth.session.get() and authService.getAccessToken()
        due to open bugs on Okta and the two issues list above describe the logic behind the work arounds
        briefly authService._oktaAuth.session.get() keeps the Okta session alive
        authService.getAccessToken() gets a new JWT token from Okta if the current one has expired
      */
    } else {
      logout();
    }
  }
  oktaTokenRefreshTimeout = setTimeout(() => {
    setOktaRefreshTrigger((oktaRefreshTrigger) => oktaRefreshTrigger + 1);
  }, getEarlyTokenRefreshTimeoutValue(milliSecondsLeftUntilExpiry));
};

export const handleSpsTokenRefreshAsync = async (
  refreshToken,
  spsTokenRefreshTimeout,
  setShouldRefreshToken
) => {
  let milliSecondsLeftUntilExpiry = 5000;

  try {
    await handleOktaToken(refreshToken);
  } finally {
    const accessToken = getToken();
    if (accessToken) {
      milliSecondsLeftUntilExpiry = getTokenExpiry(accessToken);
    } else {
      logout();
    }
  }

  spsTokenRefreshTimeout = setTimeout(() => {
    setShouldRefreshToken(true);
  }, getEarlyTokenRefreshTimeoutValue(milliSecondsLeftUntilExpiry));
};

const getEarlyTokenRefreshTimeoutValue = (milliSecondsLeftUntilExpiry) => {
  const fiveMilliseconds = 5 * 1000;
  if (milliSecondsLeftUntilExpiry < 0) {
    return fiveMilliseconds;
  }
  const threeMinutesInMilliseconds = 3 * 60 * 1000;
  const earlyTimeout = milliSecondsLeftUntilExpiry - threeMinutesInMilliseconds;
  if (earlyTimeout > 0) {
    return earlyTimeout;
  } else {
    return Math.trunc(milliSecondsLeftUntilExpiry * 0.5);
  }
};

const getTokenExpiry = (token) => {
  const { exp } = jwt.decode(token);
  const tokenExpiryTime = moment.unix(exp);
  return tokenExpiryTime.diff(moment());
};
