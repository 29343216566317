import "./NotificationsContainer.scss";

import { Icon, Label, Popup } from "semantic-ui-react";
import React, { useContext, useState } from "react";

import { NotificationsComponent } from "./NotificationsComponent";
import { NotificationsContext } from "./NotificationsContext";

export const NotificationsContainer = () => {
  const { unreadMessagesCount } = useContext(NotificationsContext);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <Popup
      on="click"
      open={isPopupOpen}
      onClose={closePopup}
      position="bottom center"
      className="notification-popup"
      trigger={
        <Icon
          name="bell outline"
          size="large"
          className="notification-bell"
          onClick={togglePopup}
        >
          {unreadMessagesCount !== 0 && (
            <Label circular className="unread-count">
              {unreadMessagesCount}
            </Label>
          )}
        </Icon>
      }
    >
      <div className="notification-div">
        <NotificationsComponent
          showAdditionalFilters={false}
          closePopup={closePopup}
        />
      </div>
    </Popup>
  );
};
