import * as Unicons from "@iconscout/react-unicons";
import "../../styles/shared.scss";
import { Button, Radio, Message } from "semantic-ui-react";
import React, { useContext, useEffect, useState } from "react";
import {
  calculateInPlanSum,
  onCapValueChange,
  onCheckedChange,
  onValueChange,
  saveMobileExplanRequestChanges,
} from "./AddExplanNonItoComponent.functions";
import { clone, mergeRight, propOr } from "ramda";

import { BackLink } from "../../common-mobile/BackLink/BackLink";
import CustomerSelectionSlider from "../../common-mobile/CustomerSelectionSlider/CustomerSelectionSlider";
import DetailCard from "../../common-mobile/DetailCard/DetailCard";
import DropDownFilter from "../../common-mobile/DropdownFilter/DropdownFilter";
import { ExpandCollapse } from "../../common-mobile/ExpandCollapse/ExpandCollapse";
import MobileSearch from "../../common-mobile/MobileSearch/MobileSearch";
import NumberFormat from "react-number-format";
import { SelectedCustomerContext } from "../../common/CustomerGroupSelector/CustomerGroupSelectorContext";
import { StandardHeading } from "../StandardHeading/StandardHeading";
import { StandardSubheading } from "../StandardSubheading/StandardSubheading";
import StickyTotalFooter from "../../common-mobile/StickyTotalFooter/StickyTotalFooter";
import { formatCurrency } from "../../lib/formatters";
import { getBoxCategories } from "../../modules/current-sight/additional-application/additional-application-create/AdditionalRequestModal.functions";
import { getCurrentSalesPeriod } from "../../lib/apiSalesPeriod";
import { getCustomerOrderTypes } from "../../modules/current-sight/additional-application/AdditionalApplications.functions";
import { getExPlanApplications } from "../../lib/apiExplan";
import { notifyError } from "../../lib/notifications";
import { useHistory } from "react-router-dom";
import DesktopNotAvailable from "../../common-mobile/MobileNotAvailable/DesktopNotAvailable";


const CategorySection = ({
  explanCategory,
  setDirtyDataDictionary,
  readjustTotal,
  setHasErrors,
  displayErrorBanner,
  setDisplayErrorBanner,
  dirtyDataDictionary,
  cardsExpanded,
  setCardsExpanded,
  categoryValidationError,
  categoryValidationErrorIndex
}) => {
  const [value, setValue] = useState(0);
  const [indexKey, setIndexKey] = useState(0);

  useEffect(() => {
    setValue(propOr(0, "value", explanCategory));
    for (let i = 0; i < dirtyDataDictionary.boxes.length; i++) {
      if (dirtyDataDictionary?.boxes[i]?.category === explanCategory.category) {
        setIndexKey(i);
      }
    }
  }, [explanCategory, dirtyDataDictionary]);


  return (
    <>
      <StandardHeading blueUnderline={true}>
        Category: {explanCategory.category}
      </StandardHeading>
      <StandardSubheading>Overall Category Value ($)</StandardSubheading>     
      <NumberFormat
        value={value}
        prefix="$"
        thousandSeparator
        isAllowed={(values) => {
          const { floatValue } = values;
          return floatValue >= 0 || !floatValue;
        }}
        decimalScale={0}
        onValueChange={({ value: newValue }) => { setValue(+newValue); }}
        onBlur={() => {
          onValueChange(
            explanCategory,
            setDirtyDataDictionary,
            readjustTotal,
            setHasErrors,
            displayErrorBanner,
            setDisplayErrorBanner,
          )({ value, indexKey });
        }
        }
      />
      {(categoryValidationError != null && (categoryValidationErrorIndex != null && parseInt(categoryValidationErrorIndex) === parseInt(indexKey))) && <label className="error-text" >{categoryValidationError}</label>}
      <ExpandCollapse
        expanded={cardsExpanded}
        setExpandedState={setCardsExpanded}
      />

      {explanCategory.displayCards.map((cardDetails, index) => {
        return (
          <DetailCard
            key={index}
            card={cardDetails}
            isEditing={true}
            expandCard={cardsExpanded}
          />
        );
      })}
    </>
  );
};


export const CreateTextEdit = ({
  props,
  box,
  setDirtyDataDictionary,
  setHasErrors,
  displayErrorBanner,
  setDisplayErrorBanner,
  boxIndex,
  categoryIndex,
  boxError,
  boxErrorText,
}) => {
  const [editingValue, setEditingValue] = useState(0);


  useEffect(() => {
    setEditingValue(box.capValue);
  }, [box]);

  return (
    <div>
      <NumberFormat
        value={editingValue}
        prefix="$"
        thousandSeparator
        isAllowed={({ floatValue }) => floatValue >= 0 || !floatValue}
        decimalScale={0}
        disabled={!box.type || box.type === "Not required"}
        onValueChange={({ value: newValue }) => {
          setEditingValue(+newValue);
        }}
        onBlur={() => {
          onCapValueChange(
            box,
            setDirtyDataDictionary,
            setHasErrors,
            displayErrorBanner,
            setDisplayErrorBanner,
            boxIndex,
            categoryIndex
          )({ capValue: editingValue });
        }}
      ></NumberFormat>
      {boxError && <label className="error-text">{boxErrorText}</label>}
    </div>
  );
};

export const MobileExplanRequestAddEdit = ({
  match: {
    params: { customerId, orderType },
  },
}) => {
  const history = useHistory();
  const { selectedCustomer } = useContext(SelectedCustomerContext);
  const [boxCategoryData, setBoxCategoryData] = useState();
  const [tableData, setTableData] = useState([]);
  const [currentSalesPeriod, setCurrentSalesPeriod] = useState(null);
  const [loadingSalesPeriod, setLoadingSalesPeriod] = useState(false);
  const [orderTypes, setOrderTypes] = useState([]);
  const [loadingOrderTypes, setLoadingOrderTypes] = useState(true);
  const [selectedOrderType, setSelectedOrderType] = useState({
    id: 0,
    name: "",
  });
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [filterCategoryId, setFilterCategoryId] = useState();

  const [search, setSearch] = useState();
  const [totalExPlanValue, setTotalExPlanValue] = useState(0);

  const [explanDisplayData, setExplanDisplayData] = useState([]);
  const [historicalDataDictionary, setHistoricalDataDictionary] = useState({});
  const [dirtyDataDictionary, setDirtyDataDictionary] = useState({});

  const [hasErrors, setHasErrors] = useState(false);
  const [displayErrorBanner, setDisplayErrorBanner] = useState(false);
  const [cardsExpanded, setCardsExpanded] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [categoryValidationError, setCategoryValidationError] = useState(null);
  const [categoryValidationErrorIndex, setCategoryValidationErrorIndex] = useState(null);

  useEffect(() => {
    if (displayErrorBanner) {
      //This notify error breaks, its missing some param values - Armand 2022-06-01
      notifyError("Submission is prevented as there are errors on the page");
    }
  }, [displayErrorBanner]);

  useEffect(() => {
    if (orderType) {
      const selected = orderTypes.find((x) => x.key === +orderType);
      if (selected) {
        selected.id = +orderType;

        setSelectedOrderType(selected);
      }
    }
  }, [orderType, orderTypes]);

  useEffect(() => {
    let totalInitialExPlanValue = 0;

    if (boxCategoryData?.boxCategories && boxCategoryData?.categories) {
      const tempBoxCategories = boxCategoryData.boxCategories;
      const tempCategories = boxCategoryData.categories;
      let tempTableData = [];
      tempTableData = [];
      const _initialState = {};

      tempCategories.forEach((category) => {
        const tempBoxForCategory = tempBoxCategories.filter((box) => {
          return box.category === category.name;
        });

        const total = calculateInPlanSum(tempBoxForCategory);
        tempTableData.push({
          category: category.name,
          total: total,
          data: tempBoxForCategory,
          value: tempBoxForCategory[0]?.value || 0,
        });


        if (
          tempBoxForCategory[0]?.value > 0 ||
          tempBoxForCategory.some((box) => box.type !== "Not required")
        ) {
          _initialState[category.name] = {};
          _initialState[category.name].value =
            tempBoxForCategory[0]?.value || 0;
          totalInitialExPlanValue += _initialState[category.name].value;
          _initialState[category.name].boxes = {};
          _initialState[category.name].capValues = [];
          tempBoxForCategory.forEach((box) => {
            _initialState[category.name].boxes[box.boxId] = box.type;
            _initialState[category.name].capValues[box.boxId] = box.capValue;
          });

        }
      });

      const dirty = { boxes: tempTableData };

      setTotalExPlanValue(totalInitialExPlanValue);
      setTableData(tempTableData);
      setDirtyDataDictionary(dirty);

    }
  }, [boxCategoryData]);

  useEffect(() => {
    buildMobileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, filterCategoryId, search, dirtyDataDictionary]);

  useEffect(() => {
    if (
      currentSalesPeriod?.salesPeriodId &&
      selectedCustomer &&
      selectedOrderType &&
      selectedOrderType.id !== 0
    ) {
      getExPlanData(
        currentSalesPeriod.salesPeriodId,
        selectedCustomer,
        selectedOrderType
      );
    }
  }, [selectedCustomer, currentSalesPeriod, selectedOrderType]);

  const getExPlanData = async (
    salesPeriodId,
    customerId,
    selectedOrderType
  ) => {
    if (
      salesPeriodId &&
      customerId &&
      selectedOrderType &&
      selectedOrderType.id !== 0
    ) {
      await getExPlanApplications(
        salesPeriodId,
        customerId,
        selectedOrderType.id ?? selectedOrderType.key,
        setLoadingOrderTypes,
        setBoxCategoryData
      );
    }
  };

  useEffect(() => {
    if (selectedCustomer && currentSalesPeriod) {
      getCustomerOrderTypes(
        setOrderTypes,
        setSelectedOrderType,
        selectedCustomer,
        currentSalesPeriod.salesPeriodId,
        setLoadingOrderTypes
      );
    }
  }, [selectedCustomer, currentSalesPeriod]);

  useEffect(() => {
    if (selectedCustomer) {
      getBoxCategories(setCategoryOptions, selectedCustomer);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (categoryOptions && categoryOptions[0]) {
      setFilterCategoryId(categoryOptions[0].value);
    }
  }, [categoryOptions])

  useEffect(() => {
    async function fetchData() {
      setLoadingSalesPeriod(true);
      const currentSP = await getCurrentSalesPeriod();
      setCurrentSalesPeriod(currentSP);
      setLoadingSalesPeriod(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    setHistoricalDataDictionary({
      current: clone(dirtyDataDictionary),
      previous: clone(historicalDataDictionary),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirtyDataDictionary]);

  const popHistory = () => {
    if (historicalDataDictionary.previous) {
      setDirtyDataDictionary(clone(historicalDataDictionary.previous.current));
      setHistoricalDataDictionary(
        clone(historicalDataDictionary.previous.previous)
      );
    }
  };

  const orderTypeChange = (orderType) => {
    const selectedOT = orderTypes.find((x) => x.key === orderType);
    setSelectedOrderType((prev) =>
      mergeRight(prev, {
        id: selectedOT?.value,
        name: selectedOT?.text,
      })
    );
  };

  const buildMobileData = () => {

    let filteredData = clone(dirtyDataDictionary.boxes);

    if (filteredData && filteredData.length > 0) {
      if (
        filterCategoryId &&
        filterCategoryId > 0 &&
        categoryOptions.length > 0
      ) {
        const categoryName = categoryOptions.find(
          (x) => x.key === filterCategoryId
        ).text;

        filteredData = filteredData.filter(
          (x) => x.category.indexOf(categoryName) >= 0
        );

        if (search) {
          filteredData[0].data = filteredData[0].data.filter(
            (x) =>
              x.boxDescription.toLowerCase().indexOf(search.toLowerCase()) >= 0
          );
        }

        setCategoryValidationError(null);
        setCategoryValidationErrorIndex(null);
        setHasErrors(false);

        const displayData = filteredData?.map((category) => {
          let categoryIndex = 0;
          var categoryValue = 0;
          var categoryBoxValuesTotal = 0;
          var boxErrorList = [];
          var boxValueError = false;

          for (let i = 0; i < dirtyDataDictionary.boxes.length; i++) {
            if (dirtyDataDictionary?.boxes[i]?.category === category.category) {
              categoryIndex = i;
              categoryValue = dirtyDataDictionary.boxes[i].value;

              for (let j = 0; j < dirtyDataDictionary.boxes[i].data.length; j++) {
                categoryBoxValuesTotal += dirtyDataDictionary.boxes[i].data[j].capValue;
                var boxValue = dirtyDataDictionary.boxes[i].data[j].capValue;
                if (boxValue > categoryValue) {
                  boxValueError = true;
                  boxErrorList.push(dirtyDataDictionary.boxes[i].data[j].boxId);
                }
              }

              if (categoryBoxValuesTotal < categoryValue) {
                setCategoryValidationError("The total box value should be greater than or equal to the category value");
                setCategoryValidationErrorIndex(categoryIndex);
                setHasErrors(true);
              }
              else if (boxValueError) {
                setCategoryValidationError("A box value cannot exceed the category total");
                setCategoryValidationErrorIndex(categoryIndex);
                setHasErrors(true);
              }
            }
          }

          var errorsFound = false;

          for (let i = 0; i < dirtyDataDictionary.boxes.length; i++) {
            categoryValue = dirtyDataDictionary.boxes[i].value;

            categoryBoxValuesTotal = 0;

            for (let j = 0; j < dirtyDataDictionary.boxes[i].data.length; j++) {
              categoryBoxValuesTotal += dirtyDataDictionary.boxes[i].data[j].capValue;
            }

            if (categoryBoxValuesTotal < categoryValue) {
              errorsFound = true;
            }
            else if (boxValueError) {
              errorsFound = true;
            }
          }

          if (!errorsFound) {
            setHasErrors(false);
            setCategoryValidationError(null);
            setCategoryValidationErrorIndex(null);
          }
          else {
            setHasErrors(true);
          }

          return {
            category: category.category,
            total: category.total,
            value: category.value,
            displayCards: category?.data?.map((box, boxIndex) => {
              return createMobileCardData(box, boxIndex, categoryIndex, boxErrorList.includes(box.boxId), boxErrorList.includes(box.boxId) ?? "The box value cannot exceed the category value");
            }),
          };
        });

        setExplanDisplayData(displayData);
      }
    }
  };

  const createMobileCardData = (box, boxIndex, categoryIndex, boxError, boxErrorText) => {
    const card = {
      highlighted: {
        name: "Box Description",
        content: box.boxDescription,
      },    
      content: [
        {
          name: "Min Box ($)",
          content: formatCurrency(box.minBoxSize ?? 0),
        },
        {
          name: "In-Plan Target ($)",
          content: formatCurrency(box.inPlanTarget ?? 0),
        },
      ],
      editContent: [
        createRadioEdit(box, boxIndex, categoryIndex),
        createTextEdit(box, boxIndex, categoryIndex, boxError, boxErrorText),
      ],
    };

    return card;
  };

  const createRadioEdit = (box, boxIndex, categoryIndex) => {

    const editItem = {
      name: "Box Preference",
      bold: true,
      edit: (
        <>
          <Radio
            checked={box.type === "Specifically"}
            label="Specifically"
            disabled={false}
            onChange={() => {
              onCheckedChange(
                "Specifically",
                box,
                setDirtyDataDictionary,
                box.value,
                setHasErrors,
                displayErrorBanner,
                setDisplayErrorBanner,
                boxIndex,
                categoryIndex
              );
            }}
          />
          <Radio
            checked={box.type === "Preferred"}
            label="Preferred"
            disabled={false}
            onChange={() => {
              onCheckedChange(
                "Preferred",
                box,
                setDirtyDataDictionary,
                box.value,
                setHasErrors,
                displayErrorBanner,
                setDisplayErrorBanner,
                boxIndex,
                categoryIndex
              );
            }}
          />
          <Radio
            checked={box.type === "Acceptable"}
            label="Acceptable"
            disabled={false}
            onChange={() => {
              onCheckedChange(
                "Acceptable",
                box,
                setDirtyDataDictionary,
                box.value,
                setHasErrors,
                displayErrorBanner,
                setDisplayErrorBanner,
                boxIndex,
                categoryIndex
              );
            }}
          />
          <Radio
            checked={!box.type || box.type === "Not required"}
            label="Not required"
            disabled={false}
            onChange={() => {
              onCheckedChange(
                "Not required",
                box,
                setDirtyDataDictionary,
                box.value,
                setHasErrors,
                displayErrorBanner,
                setDisplayErrorBanner,
                boxIndex,
                categoryIndex
              );
            }}
          />
        </>
      ),
    };

    return editItem;
  };

  const createTextEdit = (box, boxIndex, categoryIndex, boxError, boxErrorText) => {
    const editItem = {
      name: "Box value ($)",
      edit: (
        <CreateTextEdit
          box={box}
          setDirtyDataDictionary={setDirtyDataDictionary}
          setHasErrors={setHasErrors}
          displayErrorBanner={displayErrorBanner}
          setDisplayErrorBanner={setDisplayErrorBanner}
          boxIndex={boxIndex}
          categoryIndex={categoryIndex}
          boxError={boxError}
          boxErrorText={boxErrorText}
        />
      ),
    };

    return editItem;
  };

  const saveExplanRequest = async () => {
    const explanData = dirtyDataDictionary.boxes;

    setSaveLoading(true);
    const success = await saveMobileExplanRequestChanges(
      explanData,
      customerId ?? selectedCustomer,
      currentSalesPeriod.salesPeriodId,
      selectedOrderType,
      () => { }
    );

    await getExPlanData();
    setSaveLoading(false);
    if (success) {
      history.push("/current-sight/explan");
    }

  };

  const totalContent = {
    highlight: {
      showBackButton: true,
    },
    totals: [
      {
        name: "Total Ex-Plan for all categories ($)",
        content: formatCurrency(totalExPlanValue),
      },
    ],
    buttonContent: (
      <div >

        <div className="row">

          <div className="col-lg-12">
            {hasErrors && (
              <div className="row" style={{ marginBottom: "5px" }} >
                <Message error>
                  Submission is prevented as there are errors on the page
                </Message>
              </div>
            )}
          </div>

        </div>

        <div className="fit-buttons">

          <Button
            secondary
            onClick={popHistory}
            disabled={!historicalDataDictionary?.previous?.current?.boxes}
            className="less-padding"
          >
            <Unicons.UilHistory size={25} />
          </Button>
          <Button
            primary
            className="stretch-button"
            loading={saveLoading}
            onClick={() => saveExplanRequest()}
            disabled={hasErrors}
          >
            Submit
          </Button>
        </div>


      </div>
    ),
    showButtonsWhileCollapsed: true,
  };

  const readjustTotal = (previousValue, newValue) =>
    setTotalExPlanValue(
      (prev) =>
        parseFloat(prev) - parseFloat(previousValue) + parseFloat(newValue)
    );

  return (
    <>
      <div className="explan-mobile-content mobile-view">
        <CustomerSelectionSlider
          currentSight={currentSalesPeriod?.name}
          loadingCurrentSalesPeriod={loadingSalesPeriod}
        />

        <BackLink backUrl="/current-sight/explan" />

        <StandardHeading>Ex-Plan Request</StandardHeading>

        <DropDownFilter
          header="Select Order Type"
          options={orderTypes}
          currentSelected={selectedOrderType}
          onChange={orderTypeChange}
          loading={loadingOrderTypes}
        />
        <DropDownFilter
          header="Select Category"
          options={categoryOptions}
          currentSelected={filterCategoryId}
          defaultValue={1}
          onChange={(value) => {
            setFilterCategoryId(value);
          }}
        />

        <MobileSearch searchData={setSearch} />

        {filterCategoryId > 0 && (
          <>
            {explanDisplayData.map((explanCategory, index) => {
              return (
                <CategorySection
                  key={index}
                  explanCategory={explanCategory}
                  setDirtyDataDictionary={setDirtyDataDictionary}
                  readjustTotal={readjustTotal}
                  setHasErrors={setHasErrors}
                  displayErrorBanner={displayErrorBanner}
                  setDisplayErrorBanner={setDisplayErrorBanner}
                  dirtyDataDictionary={dirtyDataDictionary}
                  cardsExpanded={cardsExpanded}
                  setCardsExpanded={setCardsExpanded}
                  categoryValidationError={categoryValidationError}
                  categoryValidationErrorIndex={categoryValidationErrorIndex}
                />
              );
            })}
          </>
        )}
        <StickyTotalFooter
          content={totalContent}
          backUrl="/current-sight/explan"
        />
      </div>
      <DesktopNotAvailable />
    </>
  );
};
