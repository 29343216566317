import moment from "moment";

export const validateManufacturingDataSubmission = (submissionData) => {
  const errors = [];

  submissionData.forEach((data, index) => {
    if (isNaN(parseFloat(data.sight)) || isNaN(Number(data.sight)) || !Number.isInteger(Number(data.sight))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Sight",
        description: `'${data.sight}' is not a valid integer.`,
      });
    } else if(Number(data.sight) <= 0) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Sight",
        description: `'${data.sight}' must be greater than 0.`,
      });
    }

    if (isNaN(parseFloat(data.year)) || isNaN(Number(data.year)) || !Number.isInteger(Number(data.year))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Year",
        description: `'${data.year}' is not a valid integer.`,
      });
    } else if(Number(data.year) <= 0) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Year",
        description: `'${data.year}' must be greater than 0.`,
      });
    }

    if (isNaN(parseFloat(data.boxNumber)) || isNaN(Number(data.boxNumber)) || !Number.isInteger(Number(data.boxNumber))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Box number",
        description: `'${data.boxNumber}' is not a valid integer.`,
      });
    } else if(Number(data.boxNumber) <= 0) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Box number",
        description: `'${data.boxNumber}' must be greater than 0.`,
      });
    }

    if (!data.lot) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Lot",
        description: `'${data.lot}' is an empty string.`,
      });
    }

    if (!data.boxDescription) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Box Description",
        description: `'${data.boxDescription}' is an empty string.`,
      });
    }

    if (!Number.isInteger(Number(data.numberOfRoughStones))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Number of rough stones",
        description: `'${data.numberOfRoughStones}' is not a valid integer.`,
      });
    }

    if (isNaN(parseFloat(data.originalWeight)) || isNaN(Number(data.originalWeight))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Original Weight",
        description: `'${data.originalWeight}' is not a valid decimal.`,
      });
    } else if(Number(data.originalWeight) <= 0) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Original Weight",
        description: `'${data.originalWeight}' must be greater than 0.`,
      });
    }

    if (isNaN(Number(data.owned))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Owned",
        description: `'${data.owned}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.contracted))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Contracted",
        description: `'${data.contracted}' is not a valid decimal.`,
      });
    }

    if (!moment(data.startDate, "DD/MM/YYYY hh:mm:ss", true).isValid()) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Start Date",
        description: `'${data.startDate}' is not a valid date. Must be in the format DD/MM/YYYY.`,
      });
    }
    //No required
    if (data.endDate && !moment(data.endDate, "DD/MM/YYYY hh:mm:ss", true).isValid()) {
      errors.push({
        lineNumber: index + 2,
        columnName: "End Date",
        description: `'${data.endDate}' is not a valid date. Must be in the format DD/MM/YYYY.`,
      });
    }

    if (isNaN(Number(data.roughRejectionWeight))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Rough rejection weight",
        description: `'${data.roughRejectionWeight}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.makeableRejectionsWeight))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Makeable rejections weight",
        description: `'${data.makeableRejectionsWeight}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.weightAfterSawing))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Weight after sawing",
        description: `'${data.weightAfterSawing}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.weightAfterBrutingOrConing))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Weight after bruting/ coning",
        description: `'${data.weightAfterBrutingOrConing}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.weightAfterBlocking))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Weight after blocking",
        description: `'${data.weightAfterBlocking}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.finalPolishedWeight))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Final polished weight",
        description: `'${data.finalPolishedWeight}' is not a valid decimal.`,
      });
    }

    if (!Number.isInteger(Number(data.numberOfPolishedStones))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Number of polished stones",
        description: `'${data.numberOfPolishedStones}' is not a valid integer.`,
      });
    }

    if (isNaN(Number(data.polishedCtsSingle))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts Single cut, eight cut, swiss cut,…",
        description: `'${data.polishedCtsSingle}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsRound))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts Round (full cut- brilliant)",
        description: `'${data.polishedCtsRound}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsPear))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts Pear",
        description: `'${data.polishedCtsPear}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsCushion))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts Cushion",
        description: `'${data.polishedCtsCushion}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsPrincess))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts Princess",
        description: `'${data.polishedCtsPrincess}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsHeart))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts Heart",
        description: `'${data.polishedCtsHeart}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsOval))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts Oval",
        description: `'${data.polishedCtsOval}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsMarquise))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts Marquise",
        description: `'${data.polishedCtsMarquise}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsEmerald))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts Emerald",
        description: `'${data.polishedCtsEmerald}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsRadiant))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts Radiant",
        description: `'${data.polishedCtsRadiant}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsProprietaryOrBranded))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts Proprietary or branded makes",
        description: `'${data.polishedCtsProprietaryOrBranded}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsOther))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts Other",
        description: `'${data.polishedCtsOther}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsBelow0_01))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts below 0.01",
        description: `'${data.polishedCtsBelow0_01}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCts0_01to0_03))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts 0.01-0.03",
        description: `'${data.polishedCts0_01to0_03}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCts0_04to0_07))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts 0.04-0.07",
        description: `'${data.polishedCts0_04to0_07}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCts0_08to0_14))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts 0.08-0.14",
        description: `'${data.polishedCts0_08to0_14}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCts0_15to0_17))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts 0.15-0.17",
        description: `'${data.polishedCts0_15to0_17}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCts0_18to0_22))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts 0.18-0.22",
        description: `'${data.polishedCts0_18to0_22}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCts0_23to0_29))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts 0.23-0.29",
        description: `'${data.polishedCts0_23to0_29}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCts0_3to0_39))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts 0.3-0.39",
        description: `'${data.polishedCts0_3to0_39}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCts0_4to0_49))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts 0.4-0.49",
        description: `'${data.polishedCts0_4to0_49}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCts0_5to0_69))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts 0.5-0.69",
        description: `'${data.polishedCts0_5to0_69}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCts0_7to0_89))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts 0.7-0.89",
        description: `'${data.polishedCts0_7to0_89}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCts0_9to0_99))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts 0.9-0.99",
        description: `'${data.polishedCts0_9to0_99}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCts1to1_49))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts 1-1.49",
        description: `'${data.polishedCts1to1_49}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCts1_5to1_99))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts 1.5-1.99",
        description: `'${data.polishedCts1_5to1_99}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCts2to2_99))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts 2-2.99",
        description: `'${data.polishedCts2to2_99}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCts3to3_99))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts 3-3.99",
        description: `'${data.polishedCts3to3_99}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCts4to4_99))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts 4-4.99",
        description: `'${data.polishedCts4to4_99}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCts5to9_99))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts 5-9.99",
        description: `'${data.polishedCts5to9_99}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsAbove10))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts 10+",
        description: `'${data.polishedCtsAbove10}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsEx))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts EX",
        description: `'${data.polishedCtsEx}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsVg))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts VG",
        description: `'${data.polishedCtsVg}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsGd))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts GD",
        description: `'${data.polishedCtsGd}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsFr))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts FR",
        description: `'${data.polishedCtsFr}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsPr))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts PR",
        description: `'${data.polishedCtsPr}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsFcy))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts FCY",
        description: `'${data.polishedCtsFcy}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsD))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts D",
        description: `'${data.polishedCtsD}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsE))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts E",
        description: `'${data.polishedCtsE}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsF))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts F",
        description: `'${data.polishedCtsF}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsG))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts G",
        description: `'${data.polishedCtsG}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsH))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts H",
        description: `'${data.polishedCtsH}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsI))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts I",
        description: `'${data.polishedCtsI}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsJ))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts J",
        description: `'${data.polishedCtsJ}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsK))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts K",
        description: `'${data.polishedCtsK}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsL))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts L",
        description: `'${data.polishedCtsL}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsM))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts M",
        description: `'${data.polishedCtsM}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsN))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts N",
        description: `'${data.polishedCtsN}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsO))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts O",
        description: `'${data.polishedCtsO}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsP))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts P",
        description: `'${data.polishedCtsP}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsBelowQ))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts Below Q",
        description: `'${data.polishedCtsBelowQ}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsFancy))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts Fancy",
        description: `'${data.polishedCtsFancy}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsTtlc))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts TTLC",
        description: `'${data.polishedCtsTtlc}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsTlc))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts TLC",
        description: `'${data.polishedCtsTlc}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsLc))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts LC",
        description: `'${data.polishedCtsLc}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsCl))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts CL",
        description: `'${data.polishedCtsCl}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsDc))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts DC",
        description: `'${data.polishedCtsDc}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsTtlb))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts TTLB",
        description: `'${data.polishedCtsTtlb}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsTlb))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts TLB",
        description: `'${data.polishedCtsTlb}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsLb))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts LB",
        description: `'${data.polishedCtsLb}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsBr))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts BR",
        description: `'${data.polishedCtsBr}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsDb))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts DB",
        description: `'${data.polishedCtsDb}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsFl))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts FL",
        description: `'${data.polishedCtsFl}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsIf))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts IF",
        description: `'${data.polishedCtsIf}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsVvs1))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts VVS1",
        description: `'${data.polishedCtsVvs1}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsVvs2))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts VVS2",
        description: `'${data.polishedCtsVvs2}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsVs1))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts VS1",
        description: `'${data.polishedCtsVs1}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsVs2))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts VS2",
        description: `'${data.polishedCtsVs2}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsSi1))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts SI1",
        description: `'${data.polishedCtsSi1}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsSi2))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts SI2",
        description: `'${data.polishedCtsSi2}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsSi3))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts SI3",
        description: `'${data.polishedCtsSi3}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsI1))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts I1",
        description: `'${data.polishedCtsI1}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsI2))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts I2",
        description: `'${data.polishedCtsI2}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.polishedCtsI3))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Polished cts I3",
        description: `'${data.polishedCtsI3}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.ags))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "AGS",
        description: `'${data.ags}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.hrd))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "HRD",
        description: `'${data.hrd}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.gia))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "GIA",
        description: `'${data.gia}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.igi))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "IGI",
        description: `'${data.igi}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.gsi))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "GSI",
        description: `'${data.gsi}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.dbgis))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "DBGIS",
        description: `'${data.dbgis}' is not a valid decimal.`,
      });
    }

    if (isNaN(Number(data.other))) {
      errors.push({
        lineNumber: index + 2,
        columnName: "Other",
        description: `'${data.other}' is not a valid decimal.`,
      });
    }
  });

  return errors;
};
