import React, { useState, useEffect, useContext } from "react";
import { SelectedCustomerGroupContext } from "../../../common/CustomerGroupSelector/CustomerGroupSelectorContext";
import { getCurrentSalesPeriod } from "../../../lib/apiSalesPeriod";
import { ManufacturingData } from "./ManufacturingData/ManufacturingData";
import { BoxInsights } from "./BoxInsights/BoxInsights";
import { Legal } from "./Legal/Legal";
import { CustomerGroupSelector } from "../../../common/CustomerGroupSelector/CustomerGroupSelector";
import Helmet from "react-helmet";
import { Grid, Tab, Message } from "semantic-ui-react";
import MobileNotAvailable from "../../../common-mobile/MobileNotAvailable/MobileNotAvailable";

const InsightsContainer = () => {
  const [currentSalesPeriod, setCurrentSalesPeriod] = useState(null);
  const { selectedCustomerGroup } = useContext(SelectedCustomerGroupContext);
  const allowInsight = process.env.REACT_APP_ALLOW_INSIGHTS === "True";

  useEffect(() => {
    async function fetchData() {
      const currentSalesPeriod = await getCurrentSalesPeriod();
      setCurrentSalesPeriod(currentSalesPeriod);
    }
    fetchData();
  }, []);

  return !allowInsight ? (
    <Message
      style={{ width: "500px", margin: "auto" }}
      icon="hourglass"
      header="Insights View Unavailable"
      content="The Insights screen is currently unavailable."
    />
  ) : (
    <>
      <Helmet>
        <title>De Beers Sightholder Portal - Insights</title>
      </Helmet>
      <Grid className="desktop">
        <Grid.Row className="customer-selection-header">
          <Grid.Column width={13}>
            <CustomerGroupSelector hideCustomerSelector={true} />
          </Grid.Column>
          <Grid.Column
            width={3}
            floated="right"
            textAlign="right"
            className="current-sight-heading"
          >
            Current Sight: {currentSalesPeriod?.name}
          </Grid.Column>
          <Grid.Column computer={16}>
            <div className="border"></div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row></Grid.Row>
      </Grid>
      <Tab
        className="desktop"
        menu={{ attached: true }}
        onTabChange={(e) => {
          e.persist();
        }}
        panes={[
          {
            menuItem: "Manufacturing Insights",
            render: () => {
              return (
                <Tab.Pane>
                  <ManufacturingData
                    currentSalesPeriod={currentSalesPeriod}
                    selectedCustomerGroup={selectedCustomerGroup}
                  />
                </Tab.Pane>
              );
            },
          },
          {
            menuItem: "Box Insights",
            render: () => {
              return (
                <Tab.Pane>
                  <BoxInsights selectedCustomerGroup={selectedCustomerGroup} />
                </Tab.Pane>
              );
            },
          },
          {
            menuItem: "Legal",
            render: () => {
              return (
                <Tab.Pane>
                  <Legal />
                </Tab.Pane>
              );
            },
          },
        ]}
      />
      <MobileNotAvailable />
    </>
  );
};

export default InsightsContainer;
