import { Grid, Segment } from "semantic-ui-react";
import React, { useEffect, useState } from "react";

import { BankList } from "./BankList";
import { CustomerGroupSelector } from "../../common/CustomerGroupSelector/CustomerGroupSelector";
import { FinancialCompliance } from "./FinancialCompliance";
import Helmet from "react-helmet";
import { getCurrentSalesPeriod } from "../../lib/apiSalesPeriod";
import { userNameCache } from "../../lib/authentication";
import MobileNotAvailable from "../../common-mobile/MobileNotAvailable/MobileNotAvailable";

export const Finance = () => {
  const [userName, setUserName] = useState("");
  const [currentSalesPeriod, setCurrentSalesPeriod] = useState(null);
  const [editingBankList, setEditingBankList] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const currentSP = await getCurrentSalesPeriod();
      setCurrentSalesPeriod(currentSP);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const current = userNameCache();
    if (current !== userName) {
      setUserName(current);
    }
  }, [setUserName, userName]);

  return (
    <>
      <Helmet>
        <title>De Beers Sightholder Portal - Financial Submission</title>
      </Helmet>
      <Grid className="desktop">
        <Grid.Row className="customer-selection-header">
          <Grid.Column width={13}>
            <CustomerGroupSelector hideCustomerSelector={true} />
          </Grid.Column>
          <Grid.Column
            width={3}
            floated="right"
            textAlign="right"
            className="current-sight-heading"
          >
            Current Sight: {currentSalesPeriod?.name}
          </Grid.Column>
          <Grid.Column computer={16} mobile={16}>
            <div className="border"></div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Segment className="desktop">
        <BankList
          editing={editingBankList}
          setEditingBankList={setEditingBankList}
        />
        <FinancialCompliance />
      </Segment>
      <MobileNotAvailable />
    </>
  );
};

export default Finance;
