import React, { useEffect, useState } from "react";
import { CommunicationsContext } from "./CommunicationsContext";
import {
  fetchCommunications,
  getSalesPeriods,
  getCommunicationTypesList,
  markCommunicationAsRead,
  markAllCommunicationsAsRead,
} from "./communications.functions";
import { isLoggedIn } from "../../lib/authentication";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { v4 as uuid } from "uuid";
import { findIndex, propEq, update } from "ramda";

export const CommunicationsContextProvider = ({ children }) => {
  const [communications, setCommunications] = useState([]);
  const [loadingCommunications, setLoadingCommunications] = useState(false);
  const [titleFilter, setTitleFilter] = useState(null);
  const [typeIdFilter, setTypeIdFilter] = useState(null);
  const [salesPeriodIdFilter, setSalesPeriodIdFilter] = useState(null);
  const [filterLoading, setFilterLoading] = useState(false);
  const [salesPeriods, setSalesPeriods] = useState([]);
  const [communicationTypes, setCommunicationTypes] = useState([]);
  const [releasedDateFromFilter, setReleasedDateFromFilter] = useState("");
  const [releasedDateToFilter, setReleasedDateToFilter] = useState("");

  const [notification, setNotification] = useState(uuid());
  const [connection, setConnection] = useState(null);

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(
        `${process.env.REACT_APP_CUSTOMER_INTEGRATION_URL}/api/communicationHub`
      )
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }, []);

  useEffect(() => {
    if (connection) {
      connection.start().then(() => {
        connection.on("CommunicationUpdate", () => {
          setNotification(uuid());
        });
      });
    }
  }, [connection]);

  const startupFn = async () => {
    setFilterLoading(true);
    try {
      if (isLoggedIn()) {
        await Promise.all([
          getCommunicationTypesList(setCommunicationTypes),
          getSalesPeriods(setSalesPeriods),
        ]);
      }
    } finally {
      setFilterLoading(false);
    }
  };

  useEffect(() => {
    startupFn();
  }, []);

  useEffect(() => {
    if (isLoggedIn()) {
      fetchCommunications({
        setLoading: setLoadingCommunications,
        pageSize: 0,
        pageNumber: 1,
        setCommunications,
        titleFilter,
        typeIdFilter,
        salesPeriodIdFilter,
        releasedDateFromFilter,
        releasedDateToFilter,
        sortColumn: "releasedDate",
        sortDirection: "descending",
      });
    }
  }, [
    titleFilter,
    typeIdFilter,
    releasedDateFromFilter,
    releasedDateToFilter,
    salesPeriodIdFilter,
    notification,
  ]);

  const updateFilter = (filterType, filterValue) => {
    if (filterType === "title") {
      if (
        filterValue.length > 2 ||
        (filterValue?.length < 3 && titleFilter?.length > 0)
      ) {
        setTitleFilter(filterValue);
      }
    } else if (filterType === "sight") {
      setSalesPeriodIdFilter(filterValue);
    } else if (filterType === "type") {
      setTypeIdFilter(filterValue);
    } else if (filterType === "releasedDateFrom") {
      setReleasedDateFromFilter(filterValue);
    } else if (filterType === "releasedDateTo") {
      setReleasedDateToFilter(filterValue);
    }
  };

  const updateCommunication = (communication) => {
    const communicationIndex = findIndex(
      propEq("communicationId", communication.communicationId),
      communications
    );
    setCommunications(
      update(communicationIndex, communication, communications)
    );
  };

  const markCommunicationAsReadClicked = (communication) => async () => {
    await markCommunicationAsRead({
      communicationId: communication.communicationId,
      setCommunication: updateCommunication,
    });
  };

  const markAllCommunicationAsReadClicked = async () => {
    await markAllCommunicationsAsRead();
    fetchCommunications({
      setLoading: setLoadingCommunications,
      pageSize: 0,
      pageNumber: 1,
      setCommunications,
      titleFilter,
      typeIdFilter,
      salesPeriodIdFilter,
      releasedDateFromFilter,
      releasedDateToFilter,
      sortColumn: "releasedDate",
      sortDirection: "descending",
    });
  };

  const communicationContext = {
    communications,
    loadingCommunications,
    typeIdFilter,
    communicationTypes,
    salesPeriods,
    releasedDateFromFilter,
    releasedDateToFilter,
    filterLoading,
    updateFilter,
    markCommunicationAsReadClicked,
    markAllCommunicationAsReadClicked,
  };

  return (
    <CommunicationsContext.Provider value={communicationContext}>
      {children}
    </CommunicationsContext.Provider>
  );
};
