import { notifyError, notifySuccess } from "./notifications";

import { createAxios } from "./axiosWrapper";
import { financialSubmissionTypes } from "./enums";

const orderManagementApiUrl = `${process.env.REACT_APP_CUSTOMER_INTEGRATION_URL}/api`;

const axios = () => createAxios(orderManagementApiUrl);

export const cleanWhiteSpace = (inputText) => {
  return inputText.replace(/ +$/, "");
};

export const getCustomerGroupsData = async (filters) => {
  try {
    const customerGroupsResults = await axios().get(
      `/CustomerGroups?filters=${encodeURIComponent(JSON.stringify(filters))}`
    );
    const customerGroups = customerGroupsResults.data;
    return customerGroups;
  } catch (err) {
    notifyError("Failed to load customer groups", err);
  }
};

export const getPdsPhasingRanges = async (
  salesLocationId,
  orderTypeId,
  startSalesPeriod,
  endSalesPeriod
) => {
  try {
    const request = await axios().get(
      `/PdsPhasing/all-phasings-by-range?salesLocationId=${salesLocationId}&orderTypeId=${orderTypeId}&startSalesPeriodId=${startSalesPeriod}&endSalesPeriodId=${endSalesPeriod}`
    );
    const pdsPhasingRanges = request.data;
    return pdsPhasingRanges;
  } catch (err) {
    notifyError("Failed to load PDS Phasing Ranges", err);
  }
};

export const addCustomerGroup = async (newCustomerGroup) => {
  try {
    const createdCustomerGroup = await axios().post(
      `/CustomerGroups`,
      newCustomerGroup
    );
    const customerGroup = createdCustomerGroup.data;
    return customerGroup;
  } catch (err) {
    notifyError("Failed to add a customer group", err);
  }
};

export const getCustomerGroup = async (customerGroupId) => {
  try {
    const customerGroupResults = await axios().get(
      `/CustomerGroups/${customerGroupId}`
    );
    const customerGroup = customerGroupResults.data;
    return customerGroup;
  } catch (err) {
    notifyError("Failed to load a customer group", err);
  }
};

export const getITOWindowOpen = async () => {
  try {
    const response = await axios().get(`/ProductSwapRequest/window-open`);

    return response.data;
  } catch (err) {
    notifyError("Failed to load a customer group", err);
  }
};

export const editCustomerGroup = async (customerGroup) => {
  try {
    const editedCustomerGroup = await axios().put(
      `/CustomerGroups/${customerGroup.customerGroupId}`,
      customerGroup
    );
    const editedCustomerGroupData = editedCustomerGroup.data;
    return editedCustomerGroupData;
  } catch (err) {
    notifyError("Failed to edit a customer group.", err);
  }
};

export const getCustomer = async (customerId) => {
  try {
    const customerResults = await axios().get(`/Customers/${customerId}`);
    const customer = customerResults.data;

    return customer;
  } catch (err) {
    notifyError("Failed to find a customer.", err);
  }
};

export const addCustomer = async (newCustomer) => {
  try {
    const customerResults = await axios().post(`/Customers`, newCustomer);
    const customers = customerResults.data;

    return customers;
  } catch (err) {
    notifyError("Failed to add customer.", err);
  }
};

export const editCustomer = async (customer) => {
  try {
    const customerResults = await axios().put(
      `/Customers/${customer.customerId}`,
      customer
    );
    const customers = customerResults.data;
    return customers;
  } catch (err) {
    notifyError("Failed to update customer details.", err);
  }
};

export const getRepresentatives = async () => {
  try {
    const representatives = await axios().get(`/Representatives`);
    return representatives;
  } catch (err) {
    notifyError("Failed to load representatives.", err);
  }
};

export const getCountries = async () => {
  try {
    const countries = await axios().get(`/Countries`);
    return countries.data;
  } catch (err) {
    notifyError("Failed to load countries.", err);
  }
};

export const getOrders = async (customerId) => {
  try {
    const customerOrderResults = await axios().get(
      `/Orders?customerId=${customerId}`
    );
    const orders = customerOrderResults.data;
    return orders;
  } catch (err) {
    notifyError("Failed to load ITO(s).", err);
  }
};

export const createOrder = async (order) => {
  try {
    const createOrderResult = await axios().post(`Orders`, order);
    notifySuccess("Order created");
    return createOrderResult.data;
  } catch (err) {
    notifyError("Failed to create an order", err);
  }
};

export const getSalesLocations = async () => {
  try {
    const salesLocationResult = await axios().get(`/sales-locations`);

    return salesLocationResult.data;
  } catch (err) {
    notifyError("Failed to get the sales locations", err);
  }
};

export const getOrderTypes = async () => {
  try {
    const orderTypeResult = await axios().get(`/OrderType`);

    return orderTypeResult.data;
  } catch (err) {
    notifyError("Failed to get ITO types", err);
  }
};

export const getBoxOccurrenceTypes = async () => {
  try {
    const boxOccurrenceTypeResult = await axios().get(`/BoxOccurrence`);

    return boxOccurrenceTypeResult.data;
  } catch (err) {
    notifyError("Failed to get box occurrence types", err);
  }
};

export const searchCustomers = async (filters, limit) => {
  try {
    const customersResults = await axios().get(
      `/Customers?filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}&limit=${limit}`
    );
    const customers = customersResults.data;
    return customers;
  } catch (err) {
    notifyError("Failed to search customers", err);
  }
};

export const getSwapRequests = async (customerId, customerGroupId, itoYear) => {
  try {
    let url = "/ProductSwapRequest?";
    if (customerId) {
      url += `customerId=${customerId}`;
    } else if (customerGroupId) {
      url += `customerGroupId=${customerGroupId}`;
    }
    if (itoYear) {
      url += `&itoYear=${itoYear}`;
    }
    const swaps = await axios().get(url);
    const response = swaps.data;
    return response;
  } catch (err) {
    notifyError("Failed to load swap requests.", err);
  }
};

export const getCustomerPortalMaintenanceStatus = async () => {
  try {
    const url = "/CustomerPortalMaintenanceConfiguration";
    const response = await axios().get(url);
    return response.data;
  } catch (err) {
    return true;
  }
};

export const submitSwapRequests = async (swaps) => {
  try {
    const url = "/ProductSwapRequest?";
    const response = await axios().post(url, swaps);
    notifySuccess("Submitted successfully");
    return response;
  } catch (err) {
    notifyError("Failed to save swap requests.", err);
  }
};

export const getAllCustomerGroups = async ({
  pageSize,
  pageNumber,
  filterName,
  filterValue,
}) => {
  try {
    const encodedNameFilter = encodeURIComponent(filterName);
    const encodedValueFilter = encodeURIComponent(filterValue);
    const requestUrl = `limit=${pageSize}&skip=${
      pageNumber - 1
    }&filterName=${encodedNameFilter}&filterValue=${encodedValueFilter}`;
    const customerGroupDetails = await axios().get(
      `/CustomerGroups?${requestUrl}`
    );
    const customerGroups = customerGroupDetails?.data;
    return customerGroups;
  } catch (error) {
    notifyError("Failed to get the customer groups.", error);
  }
};

export const getAllOrders = async ({
  pageSize,
  pageNumber,
  sortColumn,
  sortDirection,
  customerGroupId,
  customerId,
  productFilter,
  customerFilter,
  locationFilter,
  orderTypeFilter,
  orderValueFromFilter,
  orderValueToFilter,
  salesPeriodFilter,
  ordersSubscription,
  selectedITOYear,
}) => {
  try {
    const encodedCustomerFilter = encodeURIComponent(
      cleanWhiteSpace(customerFilter)
    );
    const encodedProductFilter = encodeURIComponent(
      cleanWhiteSpace(productFilter)
    );
    const encodedLocationFilterFilter = encodeURIComponent(
      cleanWhiteSpace(locationFilter)
    );
    const encodedOrderTypeFilter = encodeURIComponent(
      cleanWhiteSpace(orderTypeFilter)
    );
    const encodedOrderValueFromFilter = encodeURIComponent(
      cleanWhiteSpace(orderValueFromFilter)
    );
    const encodedOrderValueToFilter = encodeURIComponent(
      cleanWhiteSpace(orderValueToFilter)
    );
    const encodedSalesPeriodFilter = encodeURIComponent(
      cleanWhiteSpace(salesPeriodFilter)
    );

    const requestUrl = `get-all-orders?customerGroupId=${customerGroupId}&customerId=${customerId}&limit=${pageSize}&skip=${
      pageNumber - 1
    }&descending=${
      sortDirection === "descending"
    }&orderBy=${sortColumn}&customerName=${encodedCustomerFilter}&productName=${encodedProductFilter}&location=${encodedLocationFilterFilter}&orderType=${encodedOrderTypeFilter}&orderValueFrom=${encodedOrderValueFromFilter}&orderValueTo=${encodedOrderValueToFilter}&salesPeriod=${encodedSalesPeriodFilter}&itoYear=${selectedITOYear} 
    `;

    const orderDetails = await axios().get(`/order/${requestUrl}`, {
      cancelToken: ordersSubscription.httpRequestCancelToken.token,
    });
    const orders = orderDetails?.data;
    return orders;
  } catch (error) {
    notifyError("Failed to get the ITO(s).", error);
  }
};

export const getAllOrdersITOTotal = async ({
  customerGroupId,
  customerId,
  selectedITOYear,
  ordersSubscription,
}) => {
  try {
    const requestUrl = `get-all-orders-ito-total?customerGroupId=${customerGroupId}&customerId=${customerId}&itoYear=${selectedITOYear}`;

    const result = await axios().get(`/Order/${requestUrl}`, {
      cancelToken: ordersSubscription.httpRequestCancelToken.token,
    });
    const totalITO = result?.data;
    return totalITO ? totalITO : 0.0;
  } catch (error) {
    notifyError("Failed to get ITO(s) total.", error);
  }
};

export const getOrder = async (orderId) => {
  try {
    const orderDetails = await axios().get(`/order/get-by-id/${orderId}`);
    const order = orderDetails.data;

    return order;
  } catch (error) {
    notifyError("Failed to get the ITO.", error);
  }
};

export const updateOrder = async (order) => {
  try {
    const updateOrder = axios().put(`/order/${order.orderId}`, order);
    notifySuccess("Order has been updated");
    return updateOrder;
  } catch (err) {
    notifyError("Could not update the ITO");
  }
};

export const getCustomerGroupResults = async ({
  location,
  kam,
  customerGroupName,
}) => {
  try {
    let requestUrl = `/CustomerGroups?`;

    if (kam) {
      requestUrl += `&kam=${kam}`;
    }
    if (location) {
      requestUrl += `&location=${location}`;
    }
    if (customerGroupName) {
      requestUrl += `&customerGroupName=${customerGroupName}`;
    }

    const customerGroupDetails = await axios().get(`${requestUrl}`);
    return customerGroupDetails.data;
  } catch (error) {
    notifyError("Failed to load customer groups.", error);
  }
};

export const captureCustomerInformationConfirmation = async (
  customerConfirmation
) => {
  try {
    const result = await axios().post(
      `/CustomerInformationConfirmation`,
      customerConfirmation
    );
    const confirmation = result.data;

    return confirmation;
  } catch (err) {
    notifyError("Failed to capture customer confirmation.", err);
  }
};

export const hasCustomerInformationBeenConfirmed = async (
  customerGroupId,
  salesPeriodId
) => {
  try {
    const result = await axios().get(
      `/CustomerInformationConfirmation/confirmed?customerGroupId=${encodeURIComponent(
        customerGroupId
      )}&salesPeriodId=${encodeURIComponent(salesPeriodId)}`
    );
    const confirmed = result.data;

    return confirmed;
  } catch (err) {
    notifyError("Failed to check if customer has been confirmed", err);
  }
};

export const getFinancialSubmission = async (customerGroupId) => {
  try {
    const financialSubmissionsResults = await axios().get(
      `/FinancialSubmission?customerGroupId=${customerGroupId}`
    );
    const financialSubmissions = financialSubmissionsResults.data;

    return financialSubmissions;
  } catch (error) {
    notifyError("Failed to get financial submissions information", error);
  }
};

export const deleteFinancialSubmission = async (financialSubmissionId) => {
  try {
    await axios().delete(
      `/FinancialSubmission?financialSubmissionId=${financialSubmissionId}`
    );
  } catch (error) {
    notifyError("Failed to delete financial compliance document", error);
  }
};

export const downloadSubmissionFile = async (submissionId, fileName) => {
  try {
    const requestUrl = `/FinancialSubmission/download?financialSubmissionId=${submissionId}`;
    const response = await axios().get(requestUrl, {
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(response.data);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    return response.data;
  } catch (err) {
    notifyError("Could not get submission file.", err);
  }
};

export const downloadSubmissionTemplateFile = async (customerGroupId) => {
  try {
    const requestUrl = `/FinancialSubmission/download-template?customerGroupId=${customerGroupId}`;
    const response = await axios().get(requestUrl, {
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(response.data);
    const a = document.createElement("a");
    a.href = url;
    a.download = "GSS_Financial_Workbook.xlsx";
    a.click();
    return response.data;
  } catch (err) {
    if (err.response.status === 406) {
      notifyError(
        "You do not have permission to download the submission file.",
        err,
        true
      );
      return;
    }

    notifyError("Could not get submission file.", err);
  }
};

export const uploadSubmissionFile = async (
  file,
  financialSubmissionWindowId,
  customerGroupId,
  financialDocumentType,
  uploadComplete,
  setLoading
) => {
  setLoading(true);

  try {
    const dataJson = {
      file: [file],
      financialSubmissionWindowId: financialSubmissionWindowId,
      customerGroupId: customerGroupId,
      financialDocumentType:
        +financialSubmissionTypes[financialDocumentType] ?? 0,
    };

    const data = formData(dataJson, ["file"]);

    await axios().post("/FinancialSubmission", data);

    uploadComplete();
  } catch (err) {
    notifyError("Could not upload submission file.", err);
  } finally {
    setLoading(false);
  }
};

function formData(formData, filesIgnore = []) {
  const data = new FormData();

  const files = filesIgnore;

  Object.entries(formData).forEach(([key, value]) => {
    if (typeof value === "object" && !files.includes(key)) {
      data.append(key, JSON.stringify(value) || null);
    } else if (files.includes(key)) {
      data.append(key, value[0] || null);
    } else {
      data.append(key, value || null);
    }
  });

  return data;
}

export const getAxSalesDataPublishedList = async ({
  filter,
  pageNumber,
  pageSize,
}) => {
  try {
    const response = await axios().get(
      `AxSalesData/published-list?salesPeriodId=${
        filter?.selectedSalesPeriod
      }&limit=${pageSize}&skip=${pageNumber - 1}&customerIdFilter=${
        filter?.customerId
      }&itoYear=${filter?.itoYear}`
    );
    return response.data;
  } catch (err) {
    notifyError("Failed to get Pre-Invoice data.", err);
  }
};

export const getAxInvoiceList = async ({ filter }) => {
  try {
    const response = await axios().get(
      `AxInvoice?salesPeriodIdFilter=${filter?.selectedSalesPeriod}&limit=100&skip=0&customerIdFilter=${filter?.customerId}&itoYear=${filter?.itoYear}`
    );
    return response.data;
  } catch (err) {
    notifyError("Failed to get Invoice data.", err);
  }
};

export const getAxSalesDataPublishedDetails = async (
  customerId,
  salesPeriodId,
  itoYear,
) => {
  try {
    let requestUrl = `AxSalesData/get-published-ax-sales-data?`;

    if (salesPeriodId) {
      requestUrl += `&salesPeriodId=${salesPeriodId}`;
    }

    if (customerId) {
      requestUrl += `&customerIdFilter=${customerId}`;
    }

    if (itoYear) {
      requestUrl += `&itoYear=${itoYear}`;
    }

    const response = await axios().get(`${requestUrl}`);

    return response.data;
  } catch (err) {
    notifyError("Failed to get Pre-Invoice details.", err);
  }
};

export const convertExcelToPdf = async (file, filename) => {
  try {
    const requestUrl = `/pdf/excel-to-pdf`;

    const formData = new FormData();
    formData.append("file", file, "excelfile.xlsx");
    const response = await axios().post(requestUrl, formData, {
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(response.data);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    return response.data;
  } catch (err) {
    notifyError("Could not convert document to PDF.", err);
  }
};

export const downloadAxInvoiceFile = async (submissionId, fileName) => {
  try {
    const requestUrl = `/AxInvoice/download?axInvoiceId=${submissionId}`;
    const response = await axios().get(requestUrl, {
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(response.data);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName + ".pdf";
    a.click();
    return response.data;
  } catch (err) {
    notifyError("Could not get submission file.", err);
  }
};

export const getCustomerPermissions = async (
  customerId,
  setHasOrderManagementPermission
) => {
  setHasOrderManagementPermission(false);
  try {
    const request = `check-user-customer-order-management-access?customerGroupId=${customerId}`;
    const customerPermissions = await axios().get(request);
    setHasOrderManagementPermission(customerPermissions.data);
  } catch (err) {
    notifyError("Could not fetch customer permissions.", err);
  }
};

export const getCustomerOrderManagementPermission = async (customerId) => {
  if (customerId) {
    try {
      const request = `customerPortalOrder/check-user-customer-order-management-permission?customerId=${customerId}`;
      const customerPermission = await axios().get(request);
      return customerPermission.data;
    } catch (err) {
      notifyError("Could not fetch customer permissions.", err);
    }
  }
};

export const getHasLinkedOrderManagementPermission = async () => {
  try {
    const request = `customerPortalOrder/check-user-has-linked-customer-order-management-permission`;
    const customerPermission = await axios().get(request);
    return customerPermission.data;
  } catch (err) {
    notifyError("Could not fetch customer permissions.", err);
  }
};

export const getCustomerGroupOrderManagementPermission = async (
  customerGroupId
) => {
  if (customerGroupId) {
    try {
      const request = `/CustomerPortalOrder/check-user-customer-group-order-management-permission?customerGroupId=${customerGroupId}`;
      const customerPermission = await axios().get(request);
      return customerPermission.data;
    } catch (err) {
      notifyError("Could not fetch customer permissions.", err);
    }
  }
};
