export function GetOktaConfig() {
  const oktaConfig = JSON.parse(process.env.REACT_APP_OKTA_CONFIG);

  oktaConfig.tokenManager = {
    expireEarlySeconds: 300, // 5 minutes early
    autoRenew: true,
  };

  return oktaConfig;
}
