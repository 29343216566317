import "./DetailCard.scss";

import * as Unicons from "@iconscout/react-unicons";

import { Button, Card, Grid } from "semantic-ui-react";
import React, { useEffect, useState } from "react";

import { DATE_TIME_FORMAT } from "../../common/Constants";
import { IconAction } from "../../common/IndexPageTable/IconAction";
import moment from "moment";

export const DetailCard = ({ card, longContent, isEditing, expandCard }) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(expandCard);
  }, [expandCard]);

  const BuildContent = ({
    mainContent,
    extraContent,
    editContent,
    isEditing,
  }) => {
    return (
      <>
        {(mainContent || extraContent || editContent) && (
          <Grid columns="2">
            {mainContent &&
              mainContent?.map((item, index) => {
                return (
                  <>
                    <Grid.Column width={longContent ? "8" : "10"}>
                      {item.name}
                    </Grid.Column>
                    <Grid.Column
                      textAlign="right"
                      width={longContent ? "8" : "6"}
                    >
                      {item.icon ?? ""}
                      {item.isDate
                        ? moment(item.content).format(DATE_TIME_FORMAT)
                        : item.content}
                    </Grid.Column>
                  </>
                );
              })}
            {mainContent && editContent && <Grid.Column width="16" />}
            {isEditing &&
              editContent &&
              editContent?.map((item, index) => {
                return (
                  <>
                    <Grid.Column
                      width="16"
                      key={index}
                      className={item.bold ? "bold-item" : ""}
                    >
                      {item.name}:
                    </Grid.Column>
                    <Grid.Column width="16" className="edit-content">
                      {item.edit}
                    </Grid.Column>
                  </>
                );
              })}
            {!isEditing &&
              editContent &&
              editContent?.map((item, index) => {
                return (
                  <>
                    <Grid.Column width="16" key={index}>
                      <div className="detailed-card-name-read-flex-container">
                        <p>{item.name}:</p>
                        <p>
                          <strong>{item.read}</strong>
                        </p>
                      </div>
                    </Grid.Column>
                  </>
                );
              })}
            {extraContent && (
              <>
                <Grid.Column width="16">&nbsp;</Grid.Column>
                <Grid.Column className="bold-item" width="16">
                  {extraContent.header}
                </Grid.Column>
              </>
            )}
            {extraContent &&
              extraContent.items?.map((extra, index) => {
                return (
                  <>
                    <Grid.Column width="16" key={index}>
                      {extra}
                    </Grid.Column>
                  </>
                );
              })}
          </Grid>
        )}
      </>
    );
  };

  const cardClassName =
    "detail-card " + (card.shouldDisplay === false ? "hide-card" : "");

  return (
    <Card.Group className={cardClassName}>
      <Card>
        <Card.Content
          header={
            <>
              <h5>
                {card.highlighted.name}:{" "}
                <span className="mobile-card-highlighted">
                  {card.highlighted.content}
                </span>
              </h5>
              {card.header?.map((headerItem, index) => {
                return (
                  <h5 key={index} className="lighter">
                    {headerItem.name}: {headerItem.content}
                  </h5>
                );
              })}
              {!card.header && <p>&nbsp;</p>}
              {(card.content || card.extraContent || card.editContent || card.actions) && (
                <Button
                  className="circular-button-expand"
                  onClick={() => setExpanded(!expanded)}
                >
                  {!expanded && <Unicons.UilAngleDown size={25} />}
                  {expanded && <Unicons.UilAngleUp size={25} />}
                </Button>
              )}
            </>
          }
        />
        {expanded && (card.content || card.extraContent || card.editContent) && (
          <Card.Content>
            <BuildContent
              mainContent={card.content}
              extraContent={card.extraContent}
              editContent={card.editContent}
              isEditing={isEditing}
            />
          </Card.Content>
        )}

        {expanded &&
          card.threeColumnContent &&
          card.threeColumnContent.map((section) => {
            return (
              <Card.Content extra>
                <Grid columns="3" className="no-margins">
                  <Grid.Column width="16" className="mobile-card-highlighted">
                    <h5>{section.name}</h5>
                  </Grid.Column>
                  <Grid.Column width="16">&nbsp;</Grid.Column>
                  {section.content.map((sectionContent) => {
                    return (
                      <>
                        <Grid.Column width="9">
                          {sectionContent.col1}
                        </Grid.Column>
                        <Grid.Column width="3">
                          {sectionContent.col2}
                        </Grid.Column>
                        <Grid.Column width="4">
                          {sectionContent.col3}
                        </Grid.Column>
                      </>
                    );
                  })}
                  <Grid.Column width="16">&nbsp;</Grid.Column>
                  <Grid.Column width="9" className="bold-text">
                    {section.footer.col1}
                  </Grid.Column>
                  <Grid.Column width="3" className="bold-text">
                    {section.footer.col2}
                  </Grid.Column>
                  <Grid.Column width="4" className="bold-text">
                    {section.footer.col3}
                  </Grid.Column>
                </Grid>
              </Card.Content>
            );
          })}
        {card.actions && expanded && (
          <Card.Content extra className="icon-row-container">
            <div className="icon-row">
              {card.actions.length < 7 &&
                card.actions.map((action, index) => {
                  return (
                    <IconAction
                      key={index}
                      title={action.name}
                      data={card}
                      action={action}
                    />
                  );
                })}
            </div>
          </Card.Content>
        )}
      </Card>
    </Card.Group>
  );
};

export default DetailCard;
